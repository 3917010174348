import {ApiCaller} from '../../../../component/apiRedux/apiCaller'
import {FarmartResponse} from '../../../../redux/thunk/reduxApis/states.api'

export interface TransportPaymentDetails {
	transporter_details: TransporterDetails
	payment_details: PaymentDetails
	delivery_details: DeliveryDetails
}

interface TransporterDetails {
	so_number: string // to be added
	deliver_location: string // to be added
	pan_card_url: string // to be added
	trip_id: string
	vehicle_number: string
	driver_name: string
	transporter_name: string
	driver_mobie_number: number
	account_number: string
	ifsc_code: string
	bank_name: string
	account_holder_name: string
	name_plate_url: string
	driving_license_url: string
	rc_url: string
	aadhar_url: string
	cancelled_cheque_url: string
}

interface PaymentDetails {
	primary_bill_t_url: string
	revised_bill_t_url: string
	total_transport_cost: number // what is key for this
	halting_charges: number
	unloading_charges: number
	route_change_charges: number
	revised_transport_cost: number // what is key
	deduction_amount: number
	payable_amount: number
	paid_amount: number
	pending_amount: number
	initiated_amount: number
}

interface DeliveryDetails {
	so_id: number
	so_farmart_id: string
	buyer_id: number
	buyer_farmart_id: string
	buyer_name: string
	delivery_location: {
		state: string
		district: string
		pincode: string
		address: string
	}
}
export const transportPaymentDetailsApi = ApiCaller<
	FarmartResponse<TransportPaymentDetails>,
	any,
	any,
	{po_id: string}
>('transportPaymentDetailsApi', {
	url: 'v4/payment/transport/details',
	type: 'GET',
})
