import {ApiCaller} from '../../../component/apiRedux/apiCaller'
import {FarmartResponse} from './states.api'

export interface TerminationTypeList {
	id: number
	name: string
}

export const fetchTerminationReasonApiGet = ApiCaller<
	FarmartResponse<{po_termination_reason_list: TerminationTypeList[]}>
>('termination_reasons', {
	type: 'GET',
	url: '/v2/purchase_order/termination_reasons',
})

export const updateTerminationReasonApiPut = ApiCaller<FarmartResponse<any>>(
	'po_termination_update',
	{
		type: 'PUT',
		url: '/v2/purchase_order/terminate_po',
	},
)
