import * as XLSX from "xlsx";
import { IPayment } from "../redux/types/type.payment";

const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const fileExtension = ".csv";

type JsonToExcelType = {
    [key: string]: string | number
}[];

export type PaymentFormat = {
    payment: IPayment,
    paymentFor: "retailer" | "transport",
    paymentPart: "P1" | "P2"
};

const convertJsonToCSV = (jsonData: any, fileName: string) => {
    // @ts-ignore
    const ws = XLSX.utils.json_to_sheet(jsonData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    return { data, fileName: fileName + fileExtension };
}

export default convertJsonToCSV;