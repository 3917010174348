import React, {Suspense} from 'react'
import { Route} from 'react-router-dom'
import PrivateRoute from '../../../component/route'
import Loader from '../../../component/loader'
import {useUserRolesIn} from '../../../hooks/useUserRole'
const OrderDetailView = React.lazy(
	() => import('../pocreation/index'),
)

const OrderDetailPage = () => {
	return (
		<Suspense fallback={<Loader />}>
			<OrderDetailView />
		</Suspense>
	)
}


export const RenderPurchaseOrdersDetailsRoute = () => {
	const isActionEnabled = useUserRolesIn(['KYC_ANALYST'])
	return (
		<>
			<Route path='orderDetail/:orderId'>
				<Route
					path='details'
					element={<PrivateRoute component={OrderDetailPage} />}
				></Route>
			</Route>
		</>
	)
}
