import {Suspense} from 'react'
import Loader from '../../../component/loader'
import React from 'react'
import {Navigate, Route} from 'react-router-dom'
import PrivateRoute from '../../../component/route'
import PaymentDetailsDrawerContainer from './components/PaymentTable/paymentDetailsDrawer/PaymentDetailsDrawerContainer'
import PaymentTableContainer from './components/PaymentTable/PaymentTableContainer'

const PaymentV2 = React.lazy(() => import('./components/PaymentContainer'))

export const PaymentV2Page = () => (
	<Suspense fallback={<Loader />}>
		<PaymentV2 />
	</Suspense>
)

export const renderPaymentV2Route = () => {
	return (
		<Route
			path='payment'
			element={<PrivateRoute component={PaymentV2Page} />}
		/>
	)
}
