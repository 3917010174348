import { ApiCaller } from "../../../../component/apiRedux/apiCaller"
import { FarmartResponse } from "../../../../redux/thunk/reduxApis/states.api"
import { PaymentEntities } from "../enums/payment.constant"

export interface RejectionReasonQueryParams {
	rejection_for: PaymentEntities
}

export interface RejectionReasonReponse {
	payment_rejection_reasons: {
		id: number;
		name: string
	}[]
}

export const fetchRejectionReasonApi = ApiCaller<
	FarmartResponse<RejectionReasonReponse>,
	any,
	any,
	RejectionReasonQueryParams
>('fetchDocumentsPayment', {
	type: 'GET',
	url: 'v4/payment/rejection_reasons',
})
