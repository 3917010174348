import { useState } from 'react';
import { useAppSelector } from '../../../redux';
import styles from './LoginForm.module.scss';

export interface LoginFormProps {
	onChange: (val: string) => void,
	value: string,
}

export const LoginForm = (props: LoginFormProps) => {
	const {onChange, value} = props
	const [mobileNumber, setMobileNumber] = useState<string>(value || '');
	
	const authState = useAppSelector(state => state.auth)

	const onSubmit = () => onChange(mobileNumber);

	return (
		<div className={styles.loginFrom}>
			<div className={styles.headers}>
				<h1 className={styles.headers__header1}>Welcome to</h1>
				<h1 className={styles.headers__header2}>FarmartOS</h1>
				<p className={styles.headers__header3}>
					Enter your credentials to login into your account
				</p>
			</div>
			<div className={styles.loginInput}>
				<div className={styles.loginInput__label}>Enter mobile number</div>
				<input
					className={`${styles.loginInput__input} ${
						authState.hasError ? styles['loginInput__input--error'] : ''
					}`}
					placeholder='Enter 10 digit mobile no...'
					type='number'
					maxLength={10}
					minLength={10}
					value={mobileNumber}
					onChange={evt => {
						if (evt.target.value.length > 10) return
						setMobileNumber(evt.target.value)
					}}
					disabled={authState.isSendingOtp}
				/>
				{authState.hasError && (
					<p className={styles.loginInput__error}>{authState.errorMessage}</p>
				)}
			</div>
			<button
				className={styles.continueButton}
				type='submit'
				onClick={onSubmit}
				disabled={authState.isSendingOtp}
			>
				{authState.isSendingOtp ? 'Loading...' : 'Continue'}
			</button>
			<p className={styles.termsAndConditions}>
				By continuing you agree to our <a href='#'>Terms and Conditions.</a>
			</p>
		</div>
	)
}
