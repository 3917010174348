import { ThemeProvider, createTheme } from '@mui/material'
import { useContext, useEffect } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.min.css'
import 'reactjs-popup/dist/index.css'
import './app.css'
import ImagePreview from './component/image-preview-v2/ImagePreview'
import { ImageDetails, ImagePreviewContext } from './component/image-preview-v2/imagePreview.context'
import AppRoutes from './route'

const App = () => {
	const imagePreviewProps = useContext(ImagePreviewContext)


	const getDeviceType = () => {
		const ua = navigator.userAgent
		if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
			return 'tablet'
		}
		if (
			/Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
				ua,
			)
		) {
			return 'mobile'
		}
		return 'desktop'
	}
	useEffect(() => {
		const viewMode = getDeviceType()
		if (viewMode == 'desktop') {
			// @ts-ignore
			document
				.getElementById('viewport')
				.setAttribute(
					'content',
					'width=device-width,initial-scale=1.0,maximum-scale=1.0',
				)
		} else if (viewMode == 'mobile') {
			// @ts-ignore
			document.getElementById('viewport').setAttribute('content', 'width=1024')
		}
	}, [])
	
	const theme = createTheme({
		palette: {
			primary: {
				main: '#005A54',
			},
			secondary: {
				main: '#EFDA86',
			},
		},
		zIndex: {
			drawer: 0
		}
	})


	return (
		<div className='App'>
			<BrowserRouter>
				<ThemeProvider theme={theme}>
					<AppRoutes />
				</ThemeProvider>
			</BrowserRouter>
			<ToastContainer />
			{!!imagePreviewProps && (
				<ImagePreview<ImageDetails>
					hideDrawer={imagePreviewProps.hideDrawer}
					show={imagePreviewProps.show}
					onHide={imagePreviewProps.onHide}
					galleryImages={imagePreviewProps.galleryImages}
					onChange={imagePreviewProps.onChange}
					currentIndex={imagePreviewProps.currentIndex}
					imageKey={imagePreviewProps.imageKey}
					nameKey={imagePreviewProps.nameKey}
				>
					{imagePreviewProps.children}
				</ImagePreview>
			)}
		</div>
	)
}

export default App
