import {ApiCaller} from '../../../../component/apiRedux/apiCaller'
import {FarmartResponse} from '../states.api'

interface IPromoterDetails {
	id: number
	aadhar_number: string
	aadhar_front_image: string
	aadhar_back_image: string
}

interface IBuyerDetails {
	permissions: {
		1: boolean
	}
	id: number
	farmart_id: string
	credit_limit: number
	gst_status: number
	status: number
	trader_name: string
	legal_name: string
	address: string
	country: string
	state: string
	district: string
	pincode: string
	annual_turnover: number
	working_crops: string[]
	buyer_type: number
	product: string
	repayment_timeline: number
	created_by_name: string
	recommended_credit_limit: number | null
	promotor_details: IPromoterDetails | null
	buyer_group_id: number
	// added
	approved_credit_limit: string
	district_id?: number
	state_id?: number
	buyer_promotor_id?: string
}

export interface BuyerDetailsV2 {
	permissions: {
		[key: number]: boolean
	}
	_id: number
	farmartId: string
	creditLimit: number
	status: number
	recommended_credit_limit: number
	traderName: string
	address: string
	gst_no: string
	state: string
	district: string
	pincode: number
	country: string
	available_credit_limit: number
	overdue_amount: string
	outstanding_amount: string
}

export interface IBuyerDocuments {
	type: number
	value: string
	doc_url: {front_url: string; back_url: string}[]
}

export interface IBuyerMillDetails {
	mill_name: string
	gst: string
	mandi_licence: string
	address: string
	state: string
	commodity_produced: string
	mill_capacity: number
	district: string
	pin: number
	crops: string[]
	mill_img_urls: string[]
	buyer_facility_id: number
	buyer_gst_id: number
	poc: {
		name: string
		mobile: number
		email: string
	}
}

export interface IBuyerGstList {
	gst_no: string
	buyer_gst_fmt_id: string
	credit_limit: number
	state: string
	district: string
	poc: {
		name: string
		email: string
		mobile: number
	}[]
	capacity_in_ton: number
	buyer_gst_id: number
	buyer_group_id: number
}

export interface BuyerPeriodWideOverdueResponse {
	overdue_amt_in_period: {
		distribution: string
		amount: string
	}[]
	total_overdue: number
	updated_at: string
}

export const buyerDetails = ApiCaller<FarmartResponse<IBuyerDetails>>(
	'buyerdetails',
	{
		type: 'GET',
		url: 'v3/buyer/detail',
	},
)

export const buyerDocumentsGroup = ApiCaller<
	FarmartResponse<{buyer_documents: IBuyerDocuments[]}>
>('buyerdocumentsgroup', {
	type: 'GET',
	url: 'v3/buyer/doc',
})

export const updateBuyerDetails = ApiCaller<FarmartResponse<any>>(
	'updateBuyerDetails',
	{
		type: 'POST',
		url: '/v3/buyer/gst_details',
	},
)

export const updateBuyerDocument = ApiCaller<FarmartResponse<any>>(
	'updateBuyerDocuments',
	{
		type: 'POST',
		url: 'v3/buyer/doc/bulk',
	},
)

export const buyerDocuments = ApiCaller<
	FarmartResponse<{buyer_documents: IBuyerDocuments[]}>
>('buyerdocuments', {
	type: 'GET',
	url: 'v3/buyer/doc',
})

export const buyerMillDetails = ApiCaller<
	FarmartResponse<{buyer_mill_details: IBuyerMillDetails[]}>
>('buyermilldetails', {
	type: 'GET',
	url: '/v3/buyer/facilities',
})

export const affiliatedGstDetail = ApiCaller<
	FarmartResponse<{buyer_gst_list: IBuyerGstList[]}>
>('buyeraffiliatedgstlist', {
	type: 'GET',
	url: '/v3/buyer/gst_details',
})

export const promoterLinkedCompainesLists = ApiCaller<
	FarmartResponse<{
		buyer_gst_list: Omit<IBuyerGstList, 'buyer_group_id'>[]
	}>
>('promoterlinkedcompaineslists', {
	type: 'GET',
	url: '/v3/buyer/promotor_gst_details',
})

export const totalAllocatedToPan = ApiCaller<
	FarmartResponse<{total_credit_limit: number; total_capacity: number}>
>('totalallocatedtopan', {
	type: 'GET',
	url: 'v3/buyer/group_credit_limit',
})

export const totalAllocatedToPromoter = ApiCaller<
	FarmartResponse<{total_credit_limit: number; total_mill_capacity_ton: number}>
>('totalaffliatedtopromoter', {
	type: 'GET',
	url: 'v3/buyer/promotor_credit_limit',
})

export const postBuyerSuspendedStatus = ApiCaller<any>(
	'postbuyersuspendedstatus',
	{
		type: 'PUT',
		url: 'v1/buyer/permissions',
	},
)

export const postBuyerStatus = ApiCaller<any>('postbuyeractivestatus', {
	type: 'POST',
	url: '/v3/buyer/status',
})

export const fetchAllBuyerV2 = ApiCaller<BuyerDetailsV2[]>(
	'fetch_all_buyer_api',
	{
		type: 'POST',
		url: 'v2/buyer/',
	},
)

export const fetchBuyerList = ApiCaller<FamrmartResponse<{buyers: any}>>(
	'fetch_buyer_list',
	{type: 'GET', url: 'v4/buyer/'},
)

export const buyerPeriodWideOverdue = ApiCaller<
	FarmartResponse<BuyerPeriodWideOverdueResponse>
>('buyer_period_wise_overdue', {
	type: 'GET',
	url: 'v3/buyer/buyer_period_wise_overdue',
})

export const changeBuyerSuspendedStatus = ApiCaller<FarmartResponse<any>>(
	'change_buyer_permission',
	{
		type: 'PUT',
		url: 'v1/buyer/permissions',
	},
)
