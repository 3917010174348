import React from 'react'
import {type} from '../../atoms/icons/types'
import {Icons, IconAllProps} from './../../atoms/icons'
import {TypeSet} from './../../atoms/typography'
import {TooltipComponent} from './../../atoms/tooltip'
import styles from './iconButton.module.css'

type IconProps = Pick<IconAllProps, 'fontSize' | 'color' | 'type' | 'iconStyle'>
export interface IconButtonAllProps extends IconProps {
	iconBox?: object
	onClick?: () => void
	label?: string
	tooltipTitle?: string
	profile: any
}

export function IconButton(props: IconButtonAllProps) {
	const {
		type,
		iconBox,
		fontSize,
		color,
		iconStyle,
		onClick,
		label,
		tooltipTitle,
		profile,
	} = props

	return (
		<TooltipComponent title={tooltipTitle}>
			<div
				className={`${styles.icon_container}`}
				style={iconBox}
				onClick={onClick}
			>
				<Icons
					type={type}
					fontSize={fontSize}
					color={color}
					iconStyle={iconStyle}
				/>
				{label && (
					<div className={styles.titleContainer}>
						<TypeSet variant='body2'>{label}</TypeSet>
					</div>
				)}
				{profile && (
					<div className={styles.titleContainer}>
						<div className={styles.designation}>
							<TypeSet variant='button'>{profile?.name}</TypeSet>
						</div>
						<div className={styles.designation}>
							<TypeSet variant='caption'>{profile?.mobileNumber}</TypeSet>
							<TypeSet variant='caption'>{` - ${profile?.designation}`}</TypeSet>
						</div>
					</div>
				)}
			</div>
		</TooltipComponent>
	)
}
