import {ApiCaller} from '../../../../../component/apiRedux/apiCaller'
import {FarmartResponse} from '../../states.api'

export interface RetailerBankDetailsInterface {
	data: {
		bank_id: string
		bank_name: string
		account_number: string
		ifsc_code: string
		account_holder_name: string
		cancelled_cheque: string
		account_detail_id: number
		merchant_doc_id: number
		status_of_doc: number
	}
}

export interface RetailerBankinfoFromIfsc {
	bank_name: string
	bank_id: number
}

export const getRetailesBankDetails = ApiCaller<
	FarmartResponse<Partial<RetailerBankDetailsInterface>>
>('retailer_bank_details', {
	type: 'GET',
	url: '/v3/retailer/bank_details',
})

export const updateRetailerBankDetails = ApiCaller<FarmartResponse<any>>(
	'retailer_bank_details_update',
	{
		type: 'POST',
		url: '/v3/retailer/bank_details',
	},
)

export const getBankNameFromIfsc = ApiCaller<
	FarmartResponse<RetailerBankinfoFromIfsc>
>('bank_details_from_ifsc', {
	type: 'GET',
	url: '/v3/retailer/bank_from_ifsc',
})
