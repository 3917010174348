import styled from "styled-components";

interface Props {
    flexDirection?: "column" | "column-reverse" | "row" | "row-reverse"
    padding?: "s" | "m" | "l"
    className?: string
}

const Container = styled.div<Props>`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: ${props => props.flexDirection ?? "column"};
    padding: ${props => {
        if(props.padding === "s") return "8px";
        if(props.padding === "m") return "16px";
        if(props.padding === "l") return "32px";
        return "0";
    }};
`;

export default Container;