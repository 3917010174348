import {
	buyerPeriodWideOverdue,
	changeBuyerSuspendedStatus,
	fetchAllBuyerV2,
	postBuyerStatus,
	promoterLinkedCompainesLists,
	totalAllocatedToPan,
	totalAllocatedToPromoter,
	updateBuyerDocument,
	updateBuyerDetails,
	fetchBuyerList,
} from './buyer.api'
import {
	editFacilitiesDetailApi,
	getFacilitiesDetailApi,
	getFacilitiesListApi,
} from './facilities.api'

export const buyersApis = [
	updateBuyerDocument,
	postBuyerStatus,
	postBuyerStatus,
	getFacilitiesListApi,
	getFacilitiesDetailApi,
	editFacilitiesDetailApi,
	totalAllocatedToPan,
	promoterLinkedCompainesLists,
	totalAllocatedToPromoter,
	fetchAllBuyerV2,
	updateBuyerDetails,
	buyerPeriodWideOverdue,
	changeBuyerSuspendedStatus,
	fetchBuyerList,
]
