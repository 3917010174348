import React, {Suspense, useEffect} from 'react'
import {Navigate, Route} from 'react-router-dom'
import PrivateRoute from '../../../component/route'
import Loader from '../../../component/loader'
import {useUserRolesIn} from '../../../hooks/useUserRole'
import { toast } from 'react-toastify'
import { type } from 'os'
const RetailerDetailView = React.lazy(
	() => import('./retailerApproval/RetailerApproval'),
)

const RetalerEditView = React.lazy(
	() => import('./createRetailer/CreateRetailer'),
)

const RetailerDetailPage = () => {
	return (
		<Suspense fallback={<Loader />}>
			<RetailerDetailView />
		</Suspense>
	)
}

const RetailerEditlPage = () => {
	return (
		<Suspense fallback={<Loader />}>
			<RetalerEditView />
		</Suspense>
	)
}

export const RenderRetailerDetailsRoute = () => {
	const isActionEnabled = useUserRolesIn(['KYC_ANALYST'])
	return (
		<>
			<Route path='retailerdetails/:retailerID'>
				<Route
					path='details'
					element={<PrivateRoute component={RetailerDetailPage} />}
				></Route>
				<Route
					path='edit'
					element={
						isActionEnabled ? (
							<PrivateRoute component={RetailerEditlPage} />
						) : (
							<>
							<Navigate to={'/protected/procurement/retailer'}/>
							</>
						)
					}
				></Route>
			</Route>
		</>
	)
}
