import {updateDocData} from './../service/firebaseServices'

interface SetDocDataReturnType {
	status: boolean
	message: string
}

interface SupplierData {
	status: number
	id: number
	updated_at?: number
	approvedLimit: number
}

export default async function updateSupplierStatus(
	data: SupplierData,
): Promise<SetDocDataReturnType> {
	try {
		validateSupplierData(data)
		const updatedData = addTimestampAndActiveStatus(data)

		await updateDocData({
			collectionType: 'supplier',
			key: updatedData.id,
			data: updatedData,
		})
		return {status: true, message: 'Success'}
	} catch (error: any) {
		return {status: false, message: error.message}
	}
}

function addTimestampAndActiveStatus(data: SupplierData): SupplierData {
	const d = new Date()
	let time = d.getTime()
	return {
		...data,
		updated_at: time,
	}
}

function validateSupplierData(data: SupplierData): void {
	if (!data.id) throw new Error('Supplier id is required')
}
