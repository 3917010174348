import { useAppSelector } from "../redux";
import { RolesKeys, designations } from "../utils";

export const useUserRole = () => {
    const {profile} = useAppSelector(state => {
		return {
			isLoggedIn: state.auth.authenticated,
			profile: state.auth.userProfile,
		}
	})
    return profile?.user_role_id;
};

export function useUserRolesIn(roles: RolesKeys[]) {
	const { profile } = useAppSelector(state => {
		return {
			isLoggedIn: state.auth.authenticated,
			profile: state.auth.userProfile,
		}
	})
	const roleId = profile?.user_role_id;
	for (let key of roles) {
		if (roleId === designations[key]) return true;
	}
	return false;
}