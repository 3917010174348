import {retailerStatus} from '../../../../utils/index'

export const RetailerCategoryEnums = {
	Fnv: 1,
	Sales: 2,
	Procuremnt: 3,
}

const RetailercatText = {
	[RetailerCategoryEnums.Fnv]: 'Fnv',
	[RetailerCategoryEnums.Sales]: 'Sales',
	[RetailerCategoryEnums.Procuremnt]: 'Procuremnt',
}

export const RetailerDocumentsEnum = {
	Pancard: 1,
	Gst: 2,
	Aadhar: 3,
	Licence: 4,
	BankAccount: 5,
	tds: 6,
	msmse: 7,
}

export const RetailerDocNames = {
	[RetailerDocumentsEnum.Pancard]: 'Pancard',
	[RetailerDocumentsEnum.Gst]: 'GST',
	[RetailerDocumentsEnum.Aadhar]: 'Aadhar',
	[RetailerDocumentsEnum.Licence]: 'Licence',
	[RetailerDocumentsEnum.BankAccount]: 'Bank Account',
	[RetailerDocumentsEnum.tds]: 'TDS',
	[RetailerDocumentsEnum.msmse]: 'MSME',
}

const RetailerStatus = {
	[retailerStatus.DOC_REJECTED]: 'Document Rejected',
	[retailerStatus.APPROVED]: 'Retailer Approved',
	[retailerStatus.LEAD_ASSIGNED]: 'Lead Assigned',
	[retailerStatus.PENDING_FOR_DOCS]: 'Pending for Document',
	[retailerStatus.PENDING_FOR_APPROVAL]: 'Pending for Approval',
}

export const DocumentStatus = {
	PENDING: 1,
	APPROVED: 2,
	RE_UPLOAD: 3,
	REJECTED: 4,
}

export const AdditionalReDocField = [
	{
		1: {
			mandantory: true,
			isFrontImageReq: true,
			isBackImgReq: false,
			isBothImgReq: false,
			isSubmitted: false,
		},
	},
	{
		2: {
			mandantory: false,
			isFrontImageReq: true,
			isBackImgReq: true,
			isBothImgReq: true,
			isSubmitted: false,
		},
	},
	{
		3: {
			mandantory: true,
			isFrontImageReq: true,
			isBackImgReq: true,
			isBothImgReq: true,
			isSubmitted: false,
		},
	},
	{
		4: {
			mandantory: false,
			isFrontImageReq: true,
			isBackImgReq: false,
			isBothImgReq: false,
			isSubmitted: false,
		},
	},
	{
		5: {
			mandantory: true,
			isFrontImageReq: true,
			isBackImgReq: false,
			isBothImgReq: false,
			isSubmitted: false,
		},
	},
	{
		6: {
			mandantory: true,
			isFrontImageReq: true,
			isBackImgReq: false,
			isBothImgReq: false,
			isSubmitted: false,
		},
	},
	{
		7: {
			mandantory: true,
			isFrontImageReq: true,
			isBackImgReq: false,
			isBothImgReq: false,
			isSubmitted: false,
		},
	},
]

export const getRetailerStatus = (retailerStatusCode: number) => {
	return RetailerStatus[retailerStatusCode]
}

export const getDocumentType = (docCode: number) => {
	return RetailerDocNames[docCode]
}

export const RetailerCategoryArr = () => {
	const cat_array: any = []
	Object.keys(RetailerCategoryEnums).map(rtlType => {
		// @ts-ignore
		const obj = {id: RetailerCategoryEnums[rtlType], name: rtlType}
		cat_array.push(obj)
	})

	return cat_array
}

export const getRetailerType = (typeCode: number) => {
	return RetailercatText[typeCode]
}

export const getModifiedDocumentArr = (document: any) => {
	const newArr = []
	for (let i = 0; i < document.length; i++) {
		const orgnl_doc = document[i]
		for (let j = 0; j < AdditionalReDocField.length; j++) {
			let [key] = Object.keys(AdditionalReDocField[j])
			if (Number(key) === orgnl_doc.type) {
				// @ts-ignore
				const dataToAdd = AdditionalReDocField[j][orgnl_doc.type]
				const newObj = {...orgnl_doc, ...dataToAdd}
				newArr.push(newObj)
				break
			}
		}
	}

	return newArr
}
