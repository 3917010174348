import {getDocDataById} from './../service/firebaseServices'

interface FetchSupplierById {
	id: string
}

export default async function fetchSupplierById(
	body: FetchSupplierById,
): Promise<any> {
	const response = await getDocDataById({
		collectionType: 'supplier',
		key: body.id,
	})

	if (response?.exists()) {
		return response?.data()
	} else {
		return null
	}
}
