import {createSlice} from '@reduxjs/toolkit'
import {ISoTarget, ISoTargetState} from '../types/type.so_target'
import {
	fetchRPMs,
	updateSoTargetRpms,
	getAllBuyerOrder,
} from '../thunk/thunk.so_target'
import {toast} from 'react-toastify'

export type CreateSoTargetBody = {
	rpmId: string
	salesOrderId: string
	targetMargin: number
}

const initialState: ISoTargetState = {
	fetchingOrders: false,
	fetchingRPMs: false,
	creatingTargetFor: null,
	orders: [],
	rpms: [],
	selectedSalerOrderTarget: null,
	totalOrderNumber:0
}

export const sliceSoTarget = createSlice({
	name: 'so_target',
	initialState,
	reducers: {
		setSelectedOrderTarget: (state, payload) => {
			state.selectedSalerOrderTarget = payload.payload
		},
		setRpms: (state, payload) => {
			state.rpms = payload.payload
		},
	},
	extraReducers: builder => {
		builder.addCase(fetchRPMs.pending, state => {
			state.fetchingRPMs = true
		})
		builder.addCase(fetchRPMs.fulfilled, (state, action) => {
			state.fetchingRPMs = false
			state.rpms = action.payload as []
		})
		builder.addCase(fetchRPMs.rejected, (state, action) => {
			state.fetchingRPMs = false
			const message = action.payload as string
			toast(message, {type: 'error'})
		})

		builder.addCase(getAllBuyerOrder.pending, state => {
			state.fetchingOrders = true
		})
		builder.addCase(getAllBuyerOrder.fulfilled, (state, action) => {
			state.fetchingOrders = false
			state.orders = action.payload.buyer_orders as []
			state.totalOrderNumber=action.payload.total_records
		})
		builder.addCase(getAllBuyerOrder.rejected, (state, action) => {
			state.fetchingOrders = false
			const message = action.payload as string
			toast(message, {type: 'error'})
		})

		builder.addCase(updateSoTargetRpms.pending, state => {})

		builder.addCase(updateSoTargetRpms.fulfilled, state => {
			toast('So Rpm Mapping Successful', {type: 'success'})
		})

		builder.addCase(updateSoTargetRpms.rejected, state => {})
	},
})

export const soTargetAction = {
	...sliceSoTarget.actions,

	fetchRPMs,
	updateSoTargetRpms,
	getAllBuyerOrder,
}

export default sliceSoTarget.reducer
