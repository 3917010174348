import {createAsyncThunk} from '@reduxjs/toolkit'
import ServiceApi from '../../../service/service.api'

export const getAllQcCheckList = createAsyncThunk(
	'dropdown/get-all-qcChecklist',
	async (_, thunkAPI) => {
		try {
			const {status, data} = await ServiceApi.getInstance().GET(
				'/v1/crop/qc_check_list',
			)
			if (status !== 200) throw new Error(data.message ?? 'Something went wrong')
			return data
		} catch (error: any) {
			return thunkAPI.rejectWithValue(error.message)
		}
	},
)

export const getAllDocAtDispatch = createAsyncThunk(
	'dropdown/get-all-document',
	async (_, thunkAPI) => {
		try {
			const {status, data} = await ServiceApi.getInstance().GET(
				'/v1/crop/doc_at_dispatch',
			)
			if (status !== 200) throw new Error(data.message ?? 'Something went wrong')
			return data
		} catch (error: any) {
			return thunkAPI.rejectWithValue(error.message)
		}
	},
)
