import {ApiCaller} from '../../../../../component/apiRedux/apiCaller'
import {FarmartResponse} from '../../../../../redux/thunk/reduxApis/states.api'

export interface EditTransportDetailsPayload {
	po_id: number
	total_transport_cost: number
	halting_charges: number
	unloading_charges: number
	route_change_charges: number
	revised_transport_cost: number
	primary_bill_t_url: string
	revised_bill_t_url: string
}

export const editTransportChargesApi = ApiCaller<
	FarmartResponse<any>,
	any,
	EditTransportDetailsPayload,
	any
>('editTransportCharges', {
	url: 'v4/payment/transport_charges',
	type: 'POST',
})

export const editTransportBankDetails = ApiCaller<
	FarmartResponse<any>,
	any,
	any,
	any
>('editTransportBankDetails', {
	url: 'v4/payment/transporter_bank_account',
	type: 'POST',
})