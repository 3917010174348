import { useEffect, useRef } from "react";
import { useLocation, useNavigate, Location} from "react-router-dom";

const browserHistory = new Map<string, Location>();

export function setRecentBrowserHistory() {
	const location = useLocation()

	useEffect(() => {
		browserHistory.set(location.pathname, location)
	}, [location])
}


export function getRecentBrowserHistory(path: string): Location | string {
	const historyUrl = browserHistory.get(path)
	if (browserHistory.has(path) && historyUrl) return historyUrl
	return path
}