import {searchDoc} from './../service/firebaseServices'

interface fetchSupplierListT {
	search?: string
}
interface fetchSupplierListReturnType {
	data: any
	snapshots: any
	totalCount: any
}
export default async function fetchSupplierList(
	body: fetchSupplierListT,
): Promise<fetchSupplierListReturnType> {
	const response = await searchDoc({
		collectionType: 'supplier',
		search: body?.search ?? '',
	})
	return {
		data: response?.response && extractRequiredData(response?.response),
		snapshots: response?.response,
		totalCount: response?.totalRecords,
	}
}
function extractRequiredData(response: any) {
	return response.docs.map((doc: any) => {
		const data={...doc.data()};
		delete data?.actionTakenBy
		delete data?.isActive
		
		return data
	})
}
