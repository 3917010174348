import { ApiCaller } from "../../../../../component/apiRedux/apiCaller"
import { FarmartResponse } from "../../../../../redux/thunk/reduxApis/states.api"

export interface InitPaymentDetailsRequestPayload {
    po_id: number,
    payment_for: 1 | 2,
    is_gst_payment: boolean,
    amount: number
}

export const initPaymentDetailsApi = ApiCaller<
    FarmartResponse<any>,
    any,
    InitPaymentDetailsRequestPayload
>('initPaymentDetailsApi', {
    type: 'POST',
    url: '/v4/payment',
})

export const fetchRetailerOrTransportPaymentIntiatedByRPMApi = ApiCaller<
    FarmartResponse<any>,
    any,
    any
>('fetchRetailerOrTransportPaymentIntiatedByRPMApi', {
    type: 'GET',
    url: '/v4/payment/requested_payment',
})

