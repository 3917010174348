import * as React from 'react'
import Box from '@mui/material/Box'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import {IconButton, IconButtonAllProps} from './../../molecules/iconButton'
import {Icons} from './../../atoms/icons'

export type IconButtonProps = Pick<
	IconButtonAllProps,
	| 'type'
	| 'iconBox'
	| 'fontSize'
	| 'color'
	| 'iconStyle'
	| 'onClick'
	| 'label'
	| 'tooltipTitle'
	|'profile'
>

export interface AccountMenuProps extends IconButtonProps {
	menuData: any
}

export function AccountMenu(props: AccountMenuProps) {
	const {
		type,
		iconBox,
		fontSize,
		color,
		iconStyle,
		onClick,
		label,
		tooltipTitle,
		menuData,
		profile
	} = props
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
	const open = Boolean(anchorEl)
	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget)
	}
	const handleClose = () => {
		setAnchorEl(null)
	}
	return (
		<React.Fragment>
			<Box sx={{display: 'flex', alignItems: 'center', textAlign: 'center'}}>
				<span
					onClick={handleClick}
					aria-controls={open ? 'account-menu' : undefined}
					aria-haspopup='true'
					aria-expanded={open ? 'true' : undefined}
				>
					<IconButton type={type} label={label} tooltipTitle={tooltipTitle} profile={profile}/>
				</span>
			</Box>
			<Menu
				anchorEl={anchorEl}
				id='account-menu'
				open={open}
				onClose={handleClose}
				onClick={handleClose}
				PaperProps={{
					elevation: 0,
					sx: {
						overflow: 'visible',
						filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
						mt: 1.5,
						'& .MuiAvatar-root': {
							width: 32,
							height: 32,
							ml: -0.5,
							mr: 1,
						},
						'&:before': {
							content: '""',
							display: 'block',
							position: 'absolute',
							top: 0,
							right: 14,
							width: 10,
							height: 10,
							bgcolor: 'background.paper',
							transform: 'translateY(-50%) rotate(45deg)',
							zIndex: 0,
						},
					},
				}}
				transformOrigin={{horizontal: 'right', vertical: 'top'}}
				anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
			>
				{menuData.map((value: any, index: any) => {
					return (
						<MenuItem
							onClick={value?.onClick}
							disabled={!value?.onClick}
							key={value + index}
						>
							{value?.iconName && <Icons type={value?.iconName} />}
							<div style={{marginLeft: '5px'}}>{value.title}</div>
						</MenuItem>
					)
				})}
			</Menu>
		</React.Fragment>
	)
}
