import {ApiCaller} from '../../../../component/apiRedux/apiCaller'
import { FarmartResponse } from '../../../../redux/thunk/reduxApis/states.api'


export interface DocumentData {
    id: number
    name: string
    doc_url: string
    doc_file_type: 'img'
    status: number
}

export interface DocumentResponse {
	approved_docs: number
	rejected_docs: number
	pending_docs: number
	docs_data: DocumentData[]
}

export const fetchDocumentsApi = ApiCaller<FarmartResponse<DocumentResponse>>(
	'fetchDocumentsPayment',
	{
		type: 'GET',
		url: 'v1/purchase_order/doc_approval',
	},
)
