import {ApiCaller} from '../../../../../component/apiRedux/apiCaller'
import {FarmartResponse} from '../../../../../redux/thunk/reduxApis/states.api'

export interface DeleteDeductionRequestDate {
	po_id: number
	deduction_for: 1 | 2 //(retailer: 1, transport: 2)
	deduction_id: number
}

export const deletedDeductionApi = ApiCaller<
	FarmartResponse<any>,
	any,
	DeleteDeductionRequestDate
>('deleteDeduction', {
	type: 'POST',
	url: 'v4/payment/delete_deduction',
})
