import {createAsyncThunk} from '@reduxjs/toolkit'
import {AppRootState} from '..'
import ServiceApi from '../../service/service.api'
import {ISoTarget, ITargetOrder} from '../types/type.so_target'
import {IUser} from '../types/type.user'

export type CreateSoTarget = {
	rpmId?: string
	salesOrderId: string
	targetMargin?: number | string
}

export const fetchRPMs = createAsyncThunk(
	'/so_target/rpms',
	async (buyerOrderId: string, thunkAPI) => {
		try {
			const {status, data} = await ServiceApi.getInstance().GET(
				`/v3/buyer/order/rpm/${buyerOrderId}`,
			)
			if (status !== 200) throw new Error(data.message ?? 'error in fetching rpm')
			return data.data.rpms
		} catch (err: any) {
			return thunkAPI.rejectWithValue(err.message)
		}
	},
)

export const getAllBuyerOrder = createAsyncThunk(
	'get/buyer_order',
	async (paginationData: any, thunkAPI) => {
		try {
			const {status, data} = await ServiceApi.getInstance().GET(
				'/v3/buyer/order',
				{
					params: {
						...paginationData
					},
				},
			)

			if (status !== 200) {
				throw new Error(data.message ?? 'Error Fetching Sales Orders')
			}
			return data.data
		} catch (e: any) {
			return thunkAPI.rejectWithValue(e.message)
		}
	},
)

export const updateSoTargetRpms = createAsyncThunk(
	'/so_target/rpm_update',
	async (updatedRpmData: any, thunkAPI) => {
		try {
			const {status, data} = await ServiceApi.getInstance().POST(
				'/v3/buyer/order/so-target',
				updatedRpmData,
			)
			if (status !== 200) {
				throw new Error(data.message ?? 'Error While Updating Rpm Mapping')
			}
		} catch (e: any) {
			return thunkAPI.rejectWithValue(e.message)
		}
	},
)
