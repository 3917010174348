import {createAsyncThunk} from '@reduxjs/toolkit'
import ServiceApi from '../../../../service/service.api'

export const getRetailerDetails = createAsyncThunk(
	'retailer/retailer-details',
	async (retailerID: number, thunkAPI) => {
		try {
			const {status, data} = await ServiceApi.getInstance().GET(
				'/v2/retailer/detail',
				{
					params: {
						retailer_id: retailerID,
					},
				},
			)
			if (status !== 200) throw new Error(data.message ?? 'Something went wrong')
			return data
		} catch (error: any) {
			return thunkAPI.rejectWithValue(error.message)
		}
	},
)
export const getRetailerDocuments = createAsyncThunk(
	'retailer/retailer-documents',
	async (retailerID: number, thunkAPI) => {
		try {
			const {status, data} = await ServiceApi.getInstance().GET(
				'/v2/retailer/docs',
				{
					params: {
						retailer_id: retailerID,
					},
				},
			)
			if (status !== 200) throw new Error(data.message ?? 'Something went wrong')
			return data
		} catch (error: any) {
			return thunkAPI.rejectWithValue(error.message)
		}
	},
)
