import {ApiCaller} from '../../../../component/apiRedux/apiCaller'
export interface FarmartPaginatedResponse<T> {}

export interface FarmartResponse<T> {
	status: boolean
	message: string
	data: T
}

export interface WarehouselistInterface {
	id: number
	name: string
	state_name: string
	district_name: string
	pincode: string
}

export const warehouseRequestGet = ApiCaller<
	FarmartResponse<{warehouse_list: WarehouselistInterface[]}>
>('warehouse', {
	type: 'GET',
	url: '/v1/warehouse ',
})
