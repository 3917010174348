import React from 'react'
import RejectedPoTableView from './view'
//Hooks=================
import {useFetchRejectPo, useRejectedPoTable} from './hooks'
function RejectedPoTableScreen() {
	const {
		rejectPoRows,
		isDataLoading,
		onPageChange,
		getAllRejectPoRec,
		tablePageData,
		totalRows,
	} = useFetchRejectPo()

	const {onTableRowSelection} = useRejectedPoTable()

	return (
		<RejectedPoTableView
			getAllRejectPoRec={getAllRejectPoRec}
			rejectPoRows={rejectPoRows}
			isDataLoading={isDataLoading}
			tablePageData={tablePageData}
			totalRows={totalRows}
			onPageChange={onPageChange}
			onTableRowSelection={onTableRowSelection}
		/>
	)
}

export default RejectedPoTableScreen
