import {getDocData} from './../service/firebaseServices'
interface filtersT {
	status: number
	state?: string
	district?: string
}
interface fetchSupplierListT {
	paginationFor: 'next' | 'previous'
	snapshots?: any
	pageSize: number
	filters: filtersT
	search?: string
}
interface fetchSupplierListReturnType {
	data: any[] | undefined
	snapshots: any
	totalCount: any
}
export default async function fetchSupplierList(
	body: fetchSupplierListT,
): Promise<fetchSupplierListReturnType> {
	if (body.snapshots) {
		if (body.paginationFor === 'next') {
			const response = await getDocData({
				collectionType: 'supplier',
				pageSize: body.pageSize,
				start: body?.snapshots.docs[body?.snapshots.docs.length - 1],
				end: null,
				filters: {
					status: body.filters.status,
					state: body.filters?.state ?? '',
					district: body.filters?.district ?? '',
				},
				search: body?.search ?? '',
			})
			return {
				data: response?.response && extractRequiredData(response?.response),
				snapshots: response?.response,
				totalCount: response?.totalRecords,
			}
		} else {
			const response = await getDocData({
				collectionType: 'supplier',
				pageSize: body.pageSize,
				start: null,
				end: body?.snapshots.docs[0],
				filters: {
					status: body.filters.status,
					state: body.filters?.state ?? '',
					district: body.filters?.district ?? '',
				},
				search: body?.search ?? '',
			})
			return {
				data: response?.response && extractRequiredData(response?.response),
				snapshots: response?.response,
				totalCount: response?.totalRecords,
			}
		}
	} else {
		const response = await getDocData({
			collectionType: 'supplier',
			pageSize: body.pageSize,
			filters: {
				status: body.filters.status,
				state: body.filters?.state ?? '',
				district: body.filters?.district ?? '',
			},
			search: body?.search ?? '',
		})
		return {
			data: response?.response && extractRequiredData(response?.response),
			snapshots: response?.response,
			totalCount: response?.totalRecords,
		}
	}
}

function extractRequiredData(response: any) {
	return response.docs.map((doc: any) => {
		const data={...doc.data()};
		delete data?.actionTakenBy
		delete data?.isActive
		
		return data
	})
}
