import { ButtonProps } from '@mui/material'
import React, {CSSProperties} from 'react'
import styled from 'styled-components'

interface Props extends ButtonProps {
	onClick?: (event: any) => void
	text: string
	style?: CSSProperties
	loading?: boolean
	loadingText?: string
	disabled?: boolean
}

const StyledButton = styled.button<Props>`
	background-color: #1b98f5;
	color: #fff;
	padding: 12px 28px 12px 28px;
	border-radius: 5px;
	border-style: none;
	width: fit-content;
	transition: all 0.3s;
	&: hover
		${props =>
			!props.loading &&
			`{
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        cursor: pointer;
    }
`};
`

const Button = (props: Props) => (
	<StyledButton
		{...props}
		onClick={props.loading ? undefined : props.onClick}
		style={props.style}
	>
		{props.loading ? props.loadingText ?? 'loading...' : props.text}
	</StyledButton>
)

export default React.memo(Button)
