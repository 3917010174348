import { ApiCaller } from "../../../../component/apiRedux/apiCaller";
import { FarmartResponse } from "../../../../redux/thunk/reduxApis/states.api";
import { BuyerLinkingCriteriaResponse } from "../types/linkBuyer.types";

export const fetchBuyerLinksCriteria = ApiCaller<
	FarmartResponse<BuyerLinkingCriteriaResponse>
>('fetchBuyerLinks', {
	type: 'GET',
	url: '/v4/buyer/linking_criteria_list',
})

export const linkBuyerApi = ApiCaller<FarmartResponse<any>>("linkBuyerApi", {
    type: 'POST',
    url: 'v4/buyer/links'
})
