import {createAsyncThunk} from '@reduxjs/toolkit'
import {isUserFC, isUserMis, isUserVp} from '../../utils'
import ServiceApi from './../../service/service.api'

export const getallbuyer = createAsyncThunk(
	'sales/get_buyer',
	async (_, thunkAPI) => {
		try {
			const {status, data} = await ServiceApi.getInstance().GET('/v1/buyer')
			if (status !== 200) throw new Error(data.message ?? 'Something went wrong')
			return data
		} catch (err: any) {
			return thunkAPI.rejectWithValue(err.message)
		}
	},
)
export const buyerPushToZoho = createAsyncThunk(
	'zoho/post_buyer',
	async (body, thunkAPI) => {
		try {
			const {status, data} = await ServiceApi.getInstance().POST(
				'v1/zoho/post_buyer',
				body,
			)
			if (status !== 200) throw new Error(data.message ?? 'Something went wrong')
			return data
		} catch (err: any) {
			return thunkAPI.rejectWithValue(err.message)
		}
	},
)

interface IActionBody {
	buyer_id: number
	action_permission: boolean
	action_enum: number
}
export const takeActionOnBuyer = createAsyncThunk(
	'sales/permissions',
	async (body: IActionBody, thunkAPI) => {
		try {
			const {status, data} = await ServiceApi.getInstance().PUT(
				'v1/buyer/permissions',
				body,
			)
			if (status !== 200) throw new Error(data.message ?? 'Something went wrong')
			return data
		} catch (err: any) {
			return thunkAPI.rejectWithValue(err.message)
		}
	},
)
export const approveBuyerCreditLimit = createAsyncThunk(
	'sales/approveCreditLimit',
	async (buyerDetails: any, thunkAPI) => {
		
		try {
			const {status, data} = await ServiceApi.getInstance().PATCH(
				`/v1/sales/buyer`,
				buyerDetails,
			)
			if (status !== 200) throw new Error(data.message ?? 'Something went wrong')
			const [editedBuyer] = data.data
			return editedBuyer
		} catch (err: any) {
			return thunkAPI.rejectWithValue(err.message)
		}
	},
)



export const editbuyer = createAsyncThunk(
	'sales/edit-buyer',
	async (buyerDetails: any, thunkAPI) => {
		let editData = {...buyerDetails}
		if (isUserVp()) {
			delete editData.creditLimit
		} else if (isUserMis()) {
			delete editData.creditLimit
			delete editData.status
			delete editData.statusRemark
		} else if (isUserFC()) {
			editData = {
				buyerId: editData.buyerId,
				creditLimit: editData.creditLimit,
			}
		}
		try {
			const {status, data} = await ServiceApi.getInstance().PATCH(
				`/v1/sales/buyer`,
				editData,
			)
			if (status !== 200) throw new Error(data.message ?? 'Something went wrong')
			const [editedBuyer] = data.data
			return editedBuyer
		} catch (err: any) {
			return thunkAPI.rejectWithValue(err.message)
		}
	},
)

export const editBuyerInfoByFc = createAsyncThunk(
	'sales//buyer/edit_buyer_by_finance',
	async (buyerDetails: any, thunkAPI) => {
		let editData = {...buyerDetails}
		try {
			const {status, data} = await ServiceApi.getInstance().PATCH(
				`/v1/buyer/edit_buyer_by_finance`,
				editData,
			)
			if (status !== 200) throw new Error(data.message ?? 'Something went wrong')
			return data
		} catch (err: any) {
			return thunkAPI.rejectWithValue(err.message)
		}
	},
)

export const getallsalesOrder = createAsyncThunk(
	'sales/get_saorder',
	async (paginationData: any, thunkAPI) => {
		try {
			const {status, data} = await ServiceApi.getInstance().GET(
				'/v2/sales_order',
				{
					params: {
						...paginationData,
					},
				},
			)
			if (status !== 200) throw new Error(data.message ?? 'Something went wrong')
			return data.data
		} catch (err: any) {
			return thunkAPI.rejectWithValue(err.message)
		}
	},
)

export const editSalesorder = createAsyncThunk(
	'sales/edit-sales',
	async (salesOrder: any, thunkAPI) => {
		const sales_order_id = salesOrder.orderId
		delete salesOrder.orderId
		try {
			const {status, data} = await ServiceApi.getInstance().PATCH(
				`/v1/sales/buyer/order/${sales_order_id}`,
				salesOrder,
			)
			if (status !== 200) throw new Error(data.message ?? 'Something went wrong')
			return data
		} catch (err: any) {
			return thunkAPI.rejectWithValue(err.message)
		}
	},
)

export const fetchAllRepaymentList = createAsyncThunk(
	'sales/all-repayments',
	async (pageData: any, thunkAPI) => {
		try {
			const {status, data} = await ServiceApi.getInstance().GET(
				'/v1/zoho/buyer_details',
				{
					params: {
						...pageData,
					},
				},
			)
			if (status !== 200) throw new Error(data.message ?? 'Something went wrong')
			return data
		} catch (err: any) {
			return thunkAPI.rejectWithValue(err.message)
		}
	},
)
export const fetchBuyerFinanceDetails = createAsyncThunk(
	'sales/buyer-finance-details',
	async (buyer_id: number, thunkAPI) => {
		try {
			const {status, data} = await ServiceApi.getInstance().GET(
				'/v2/buyer/credit_details',
				{
					params: {
						buyer_id,
					},
				},
			)
			if (status !== 200) throw new Error(data.message ?? 'Something went wrong')
			return data
		} catch (err: any) {
			return thunkAPI.rejectWithValue(err.message)
		}
	},
)
export const fetchBuyerCreditRiskEvaluationDetail = createAsyncThunk(
	'sales/buyer-credit-details',
	async (buyer_id: string, thunkAPI) => {
		try {
			const {status, data} = await ServiceApi.getInstance('DS').GET(
				`/buyer_credit_risk_evaluation?customer_id=${buyer_id}`,
				
			)
			if (status !== 200) throw new Error(data.message ?? 'Something went wrong')
			return data
		} catch (err: any) {
			return thunkAPI.rejectWithValue(err.message)
		}
	},
)

export const fetchUniqueSalesOeder = createAsyncThunk(
	'sales/unique-order',
	async (id: string, thunkAPI) => {
		try {
			const {status, data} = await ServiceApi.getInstance().GET(
				`/v2/sales_order/so_detail?so_id=${id}`,
				
			)
			if (status !== 200) throw new Error(data.message ?? 'Something went wrong')
			return data
		} catch (err: any) {
			return thunkAPI.rejectWithValue(err.message)
		}
	},
)
