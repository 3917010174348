import React, {useEffect, useRef, useState} from 'react'
import style from './imageGallery.module.css'

export interface ImageGalleryProps<T> {
	galleryImages: T[]
	onChange?: (index: number) => void
	onClose: () => void;
	currentIndex?: number
	imageKey: keyof T
	nameKey: keyof T
}

export function ImageGallery<T>(
	props: ImageGalleryProps<T>,
) {
	const {galleryImages, onChange, currentIndex = 0, imageKey, nameKey, onClose} = props
	const [slideNumber, setSlideNumber] = useState<number>(currentIndex)
	const [rotaion, setRotation] = useState<number>(0)
	const [scale, setScale] = useState<number>(1)
	const [isInGrabState, setIsInGrabState] = useState<boolean>(false)
	const [isImageRotationEnabled, setIsImageRotationEnabled] =
		useState<boolean>(true)
	const [width, setWidth] = useState<number>(0)
	const [height, setHeight] = useState<number>(0)

	const [baseWidth, setBaseWidth] = useState<number>(0)
	const [baseHeight, setBaseHeight] = useState<number>(0)

	const [isImageOverflow, setIsImageOverflow] = useState<boolean>(false)

	const mainContainer = document.getElementById('main-container')

	const startXRef = useRef<number>(0);
	const scrollLeftRef = useRef<number>(0);
	const startYRef = useRef<number>(0);
	const scrollTopRef = useRef<number>(0);
	const mouseDownRef = useRef<boolean>(false);

	React.useEffect(() => {

		if (!mainContainer || !isInGrabState)
			return;

		const mouseMoveHandeler = (e: MouseEvent) => {
			e.preventDefault()
			if (!mainContainer) {
				return
			}
			if (!mouseDownRef.current) {
				return
			}
			const x = e.pageX - mainContainer.offsetLeft
			const scrollX = x - startXRef.current
			const y = e.pageY - mainContainer.offsetTop
			const scrollY = y - startYRef.current
			mainContainer.scrollLeft = scrollLeftRef.current - scrollX
			mainContainer.scrollTop = scrollTopRef.current - scrollY
		}

		const mouseDownHandeler = (e: MouseEvent) => {
			e.preventDefault()
			mouseDownRef.current = true
			startXRef.current = e.pageX - mainContainer.offsetLeft
			scrollLeftRef.current = mainContainer.scrollLeft

			startYRef.current = e.pageY - mainContainer.offsetTop
			scrollTopRef.current = mainContainer.scrollTop
		}

		const mouseUpHandeler = (e: MouseEvent) => {
			e.preventDefault()
			mouseDownRef.current = false
		}

		mainContainer.addEventListener('mousedown', mouseDownHandeler)
		mainContainer.addEventListener('mouseup', mouseUpHandeler)
		mainContainer.addEventListener('mousemove', mouseMoveHandeler)

		return () => {
			mainContainer.removeEventListener('mousedown', mouseDownHandeler)
			mainContainer.removeEventListener('mouseup', mouseUpHandeler)
			mainContainer.removeEventListener('mousemove', mouseMoveHandeler)
		}
	}, [isInGrabState, mainContainer])

	const resetBeforeScroll = () => {
		setRotation(0)
		setScale(1)
	}

	const getCurrentIndexImage = React.useCallback(() => {
		if (galleryImages.length <= 0) return ''
		if (!galleryImages[slideNumber]) return ''
		const image = String(galleryImages[slideNumber][imageKey])
		return image
	}, [galleryImages, slideNumber, imageKey])

	const getImageUrlName = React.useCallback(() => {
		if (galleryImages.length <= 0) return ''
		if (!galleryImages[slideNumber]) return ''
		const image = String(galleryImages[slideNumber][nameKey])
		return image
	}, [galleryImages, slideNumber, nameKey])

	const prevSlide = React.useCallback(() => {
		if (galleryImages.length <= 0) return;
		let currentIndex: number
		currentIndex = slideNumber === 0 ? galleryImages.length - 1 : slideNumber - 1
		resetBeforeScroll()
		setTimeout(() => {
			setSlideNumber(currentIndex)
			if (onChange) {
				onChange(currentIndex)
			}
		}, 100)
	}, [galleryImages.length, slideNumber, onChange])

	// Next Image
	const nextSlide = React.useCallback(() => {
		if (galleryImages.length <= 0) return;
		let currentIndex: number
		currentIndex = slideNumber + 1 === galleryImages.length ? 0 : slideNumber + 1
		resetBeforeScroll()
		setTimeout(() => {
			setSlideNumber(currentIndex)
			if (onChange) {
				onChange(currentIndex)
			}
		}, 100)
	}, [galleryImages.length, slideNumber, onChange])

	const updateScalePlus = () => {
		if (scale + 0.05 > 2) return
		setScale(scale + 0.05)
	}

	const updateScaleMinus = () => {
		if (scale - 0.05 < 0.5) return
		setScale(scale - 0.05)
	}

	useEffect(() => {
		if (currentIndex < 0 || currentIndex >= galleryImages.length) return
		resetBeforeScroll()
		setTimeout(() => {
			setSlideNumber(currentIndex)
			if (onChange) {
				onChange(currentIndex)
			}
		}, 100)
	}, [currentIndex, onChange, galleryImages.length])

	useEffect(() => {
		if (scale > 1) {
			setIsInGrabState(true)
		} else {
			setIsInGrabState(false)
		}
	}, [scale])

	useEffect(() => {
		const imageContainer = document.getElementById('main-container')
		if (!imageContainer) return
		const containerWidth = imageContainer.offsetWidth
		if (!width) return
		if (width > containerWidth) setIsImageOverflow(true)
		else setIsImageOverflow(false)
	}, [height, scale, width])

	useEffect(() => {
		const resizeRatio = 0.9
		const img = new Image()
		img.src = getCurrentIndexImage();
		const checkIfContainerIsLargerThenImage = (width: number, height: number) => {
			const imageContainer = document.getElementById('main-container')
			if (!imageContainer) return
			const containerHeight = imageContainer.offsetHeight
			if (height > containerHeight) {
				setBaseHeight(containerHeight * resizeRatio)
				const aspectRatio = +(height / width)
				setBaseWidth((1 / aspectRatio) * containerHeight * resizeRatio)
			} else {
				setBaseHeight(height * resizeRatio)
				setBaseWidth(width * resizeRatio)
			}
		}
		img.onload = function () {
			checkIfContainerIsLargerThenImage((this as any).width, (this as any).height)
		}
	}, [slideNumber, galleryImages])

	useEffect(() => {
		if (rotaion % 180 === 0) {
			setWidth(baseWidth * scale)
			setHeight(baseHeight * scale)
		} else {
			setHeight(baseWidth * scale)
			setWidth(baseHeight * scale)
		}
	}, [scale, rotaion, baseHeight, baseWidth])

	const onClickRotateClockWise = () => {
		let newRotation = rotaion + 90
		if (newRotation >= 360) {
			newRotation = 0
		}
		setRotation(newRotation)
	}

	const onClickRotateAntiClockWise = () => {
		let newRotation = rotaion - 90
		setRotation(newRotation)
	}

	const scaleAtParticularRatio = (value: number) => {
		setScale(value)
	}

	const centerContainerWhenImageOverflow = () => {
		if (!isImageOverflow) {
			return {
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
			}
		}
		return {}
	}

	const downloadImage = () => {
		const filename = getCurrentIndexImage()
			.split('/')
			.pop()
		const link = document.createElement('a')
		link.setAttribute('type', 'hidden')
		document.body.appendChild(link)
		link.setAttribute('href', getCurrentIndexImage())
		link.setAttribute('download', filename || '')
		link.click()
		document.body.removeChild(link)
	}

	useEffect(() => {
		const keyDownListerner = (event: KeyboardEvent) => {
			if (event.key === 'ArrowLeft') {
				nextSlide()
			} else if (event.key === 'ArrowRight') {
				prevSlide()
			} else if (event.key === 'Escape') {
				onClose();
			}
		}
		document.addEventListener('keydown', keyDownListerner)
		return () => {
			document.removeEventListener('keydown', keyDownListerner)
		}
	}, [nextSlide, prevSlide])

	return (
		<div className={style.imageContainer}>
			<div className={style.imgHeader}>
				<div className={style.imageHeaderFileName}>
					<div>
						<i className='fa-solid fa-image'></i>
						<span className={style.headerFontFamily}>{getImageUrlName()}</span>
					</div>
				</div>
				<div>
					<div onClick={updateScalePlus}>
						<i className='fa-solid fa-magnifying-glass-plus'></i>{' '}
					</div>
					<div>
						<select
							name='size'
							value={parseInt(scale + '') * 100}
							onChange={evt => scaleAtParticularRatio(+evt.target.value / 100)}
						>
							<option value={50}>50</option>
							<option value={100}>100</option>
							<option value={200}>200</option>
						</select>
					</div>
					<div onClick={updateScaleMinus}>
						<i className='fa-solid fa-magnifying-glass-minus'></i>
					</div>
				</div>
				<div>
					{isImageRotationEnabled && (
						<div onClick={onClickRotateAntiClockWise}>
							<i className='fa-solid fa-rotate-left'></i>
						</div>
					)}
					{isImageRotationEnabled && (
						<div onClick={onClickRotateClockWise}>
							<i className='fa-solid fa-rotate-right'></i>
						</div>
					)}
					<div onClick={downloadImage}>
						<i className='fa fa-download' aria-hidden='true'></i>
					</div>
					{scale > 1 && (
						<div onClick={() => setScale(1)}>
							<i className='fa-solid fa-minimize' aria-hidden='true'></i>
						</div>
					)}
					{scale < 1 && (
						<div onClick={() => setScale(1)}>
							<i className='fa-solid fa-maximize' aria-hidden='true'></i>
						</div>
					)}
					{
						<div onClick={() => onClose()}>
							<i className='fa fa-window-close' aria-hidden='true'></i>
						</div>
					}
				</div>
			</div>
			<div
				className={style.carouselContainer}
				id='main-container'
				style={{
					...centerContainerWhenImageOverflow(),
				}}
			>
				<div
					className={style.imageCarousel}
					id='image-container'
					style={{
						cursor: isInGrabState ? 'grab' : 'default',
						transform: `rotate(${rotaion}deg)`,
						height: `${height}px`,
						width: `${width}px`,
					}}
				>
					<img
						id='image'
						className={style.imageCarouselImage}
						src={getCurrentIndexImage()}
						alt=''
						width={width}
						style={{
							display: 'block',
						}}
						height={height}
					/>
				</div>
			</div>
			{galleryImages.length >= 2 && (
				<div className={style.left} onClick={prevSlide}>
					<i className='fa-solid fa-chevron-left fa-2xl'></i>
				</div>
			)}
			{galleryImages.length >= 2 && (
				<div className={style.right} onClick={nextSlide}>
					<i className='fa-solid fa-chevron-right fa-2xl'></i>
				</div>
			)}
		</div>
	)
}

export default ImageGallery;
