import React, {Dispatch, SetStateAction, useReducer, useState} from 'react'
import {ImagePreviewProps} from './ImagePreview'

export interface ImagePreviewProviderProps {
	children: JSX.Element | JSX.Element[]
}

export interface ImageDetails {
    name: string;
    url: string | undefined;
}

type ImagePreviewContextType = ImagePreviewProps<ImageDetails> & {
	setGalleryImagesIndexAndShow: (params: {images: ImageDetails[], index: number, show: boolean}) => void
	setDrawerState: (hideDrawer: boolean) => void
	setCurrentIndex: (currentIndex: number) => void
    setShow: (show: boolean) => void
	setGalleryImagesAndDrawer: (params: {
		images: ImageDetails[]
		index: number
		show: boolean
		children: JSX.Element | JSX.Element[]
	}) => void,
	setCurrentFocusImageIndex: (params: {
		index: number,
		imagesArray: (ImageDetails | undefined)[],
	}) => void; 
}

type ImagePreviewContextState = Omit<ImagePreviewProps<ImageDetails>, 'onHide'>

const initialState: ImagePreviewContextState = {
	show: false,
	imageKey: 'url',
	nameKey: 'name',
	galleryImages: [],
	hideDrawer: true,
	currentIndex: 0,
	children: undefined,
}

export const ImagePreviewContext =
	React.createContext<ImagePreviewContextType | undefined>(undefined);

export const ImagePreviewProvider: React.FC<ImagePreviewProviderProps> = (props) => {
	const [state, setState] = useReducer((state: ImagePreviewContextState, newState: Partial<ImagePreviewContextState>) => ({
			...state,
			...newState
	}), {
		...initialState
	});

	const setGalleryImagesIndexAndShow = (params: {
		images: ImageDetails[]
		index: number
		show: boolean
	}) => {
		const {images, index, show} = params
		setState({
			galleryImages: images,
			currentIndex: index,
			show,
		})
	}

	const setDrawerState = (hideDrawer: boolean) => {
		setState({
			hideDrawer,
		})
	}

	const setCurrentIndex = (currentIndex: number) => {
		setState({
			currentIndex,
		})
	}

	const setGalleryImagesAndDrawer = (params: {
		images: ImageDetails[]
		index: number
		show: boolean
		children: JSX.Element | JSX.Element[]
	}) => {
		const { images, index, show, children } = params;
		setState({
			children,
			hideDrawer: false,
			galleryImages: images,
            currentIndex: index,
            show,
		})
	}

	const onHide = () => {
		setState({
			...initialState,
		})
	}

    const setShow = (show: boolean) => {
        setState({
            show
        })
    }

	// in images array , use to focus at some image at index
	const setCurrentFocusImageIndex = (params: {
		index: number
		imagesArray: (ImageDetails | undefined)[]
	}) => {
		const {imagesArray, index} = params
		let count = 0,
			focusIndex = 0
		let outputImagesArray: any = []
		for (let i = 0; i < imagesArray.length; i++) {
			const currentImageObject = imagesArray[i]
			if (currentImageObject) {
				count++
				outputImagesArray.push(currentImageObject)
			}
			if (i === index) {
				focusIndex = count - 1
			}
		}
		setGalleryImagesIndexAndShow({
			images: outputImagesArray,
			index: focusIndex,
			show: true,
		})
	}

	return (
		<ImagePreviewContext.Provider
			value={{
				...state,
				setGalleryImagesIndexAndShow,
				setDrawerState,
				setCurrentIndex,
				setGalleryImagesAndDrawer,
				onHide,
                setShow,
				setCurrentFocusImageIndex
			}}
		>
			{props.children}
		</ImagePreviewContext.Provider>
	)
}
