import { Navigate, Route } from "react-router-dom"
import PrivateRoute from '../../../component/route';
import { Suspense } from "react";
import React from "react";
import Loader from "../../../component/loader";

const NewBuyer = React.lazy(() => import('./components/BuyerContainer'))
const NewBuyerPage = () => (
    <Suspense fallback={<Loader />}>
        <NewBuyer />
    </Suspense>
)

const BuyerDetailInfiniteScroll = React.lazy(
    () => import('./components/BuyerInfiniteScrollContainer'),
)

const BuyerDetailInfiniteScrollPage = () => (
    <Suspense fallback={<Loader />}>
        <BuyerDetailInfiniteScroll />
    </Suspense>
)

const EditBuyerContainer = React.lazy(
    () => import('./components/editBuyer/EditBuyerContainer'),
)

const EditBuyerContainerPage = () => (
    <Suspense fallback={<Loader />}>
        <EditBuyerContainer />
    </Suspense>
)

const SelectedBuyerDetailsPreview = React.lazy(
    () => import('./components/buyerDetails/BuyerDetailsContainer'),
)

const SelectedBuyerDetailsPreviewPage = () => (
    <Suspense fallback={<Loader />}>
        <SelectedBuyerDetailsPreview />
    </Suspense>
)

export const RenderV3BuyerRoute = () => {
    return (
        <>
            <Route
                path='buyer'
                element={<PrivateRoute component={NewBuyerPage} />}
            ></Route>
            <Route
                path='buyerdetails/:buyerID'
                element={<PrivateRoute component={BuyerDetailInfiniteScrollPage} />}
            >
                <Route path='details' element={<SelectedBuyerDetailsPreviewPage />} />
                <Route
                    path='editBuyers'
                    element={<EditBuyerContainerPage />}
                />
                <Route path='' element={<Navigate to='details' replace={true} />} />
            </Route>
        </>
    )
}