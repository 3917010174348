import React from 'react'
import {Typography, TypographyProps} from '@mui/material'

export type TypeSetProps = Pick<TypographyProps, 'variant'>

export interface TypeSetAllProps extends TypeSetProps {
	children: any
}
// Use all except disableRipple
// type ButtonBaseProps = Omit<MuiButtonProps, "disableRipple">;

export const TypeSet = ({children, ...rest}: TypeSetAllProps) => (
	<Typography {...rest}>{children}</Typography>
)
