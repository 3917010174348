import { ApiCaller } from '../../../../component/apiRedux/apiCaller'
import { FarmartResponse } from '../../../../redux/thunk/reduxApis/states.api'
import { BuyerDetails, BuyerStatusList } from '../types/buyerSummary.types'

export const fetchBuyerDetails = ApiCaller<FarmartResponse<BuyerDetails>>(
	'fetchBuyerDetails',
	{
		type: 'GET',
		url: '/v4/buyer',
	},
)

export const fetchBuyerStatusList = ApiCaller<
	FarmartResponse<BuyerStatusList>
>('fetchBuyerStatusList', {
	type: 'GET',
	url: '/v4/buyer/status_list',
})

export const updateBuyerStatus = ApiCaller<FarmartResponse<any>>("updatedBuyerStatus", {
	type: 'PUT',
	url: '/v1/buyer/permissions'
});