import {ApiCaller} from '../../../../component/apiRedux/apiCaller'
import {FamrmartResponse} from '../states.api'

export interface ITradeRejectedReason {
	name: string
	id: number
}

export const fetchRejectedReasonList = ApiCaller<
	FamrmartResponse<{rejection_reason_list: ITradeRejectedReason[]}>
>('fetch_rejected_reason_list', {
	type: 'GET',
	url: 'v1/trade/rejection_reasons',
})

export const expireTrade = ApiCaller<FamrmartResponse<{}>>('expire_trade', {
	type: 'PUT',
	url: 'v1/trade/expire',
})

export const fetchTradeBuyerList = ApiCaller<FamrmartResponse<{buyers: any}>>(
	'fetch_trade_buyer_list',
	{
		type: 'GET',
		url: 'v1/trade/buyer_list',
	},
)
export const fetchTradeCrate = ApiCaller<FamrmartResponse<{}>>('create_trade',{
	type:'POST',
	url:'v2/trade', // changed to new version
})
export const fetchLocationAutoComplete = ApiCaller<FamrmartResponse<{}>>('auto_complete',{
    type:'GET',
	url:'v1/location/auto_complete',
})

export const fetchUniqueTraderDetails = ApiCaller<FamrmartResponse<{}>>('unique_trader_detail',{
    type:'GET',
	url:'v2/trade',
})

export const modifyTradePrice = ApiCaller<FamrmartResponse<{}>>('modify_price',{
    type:'PATCH',
	url:'v2/trade/modify_price',
})

export const fetchCountOfAllStatus = ApiCaller<FamrmartResponse<{}>>('fetch_count',{
	type:'GET',
	url:'/v1/trade/status/count'
})

export const fetchBuyerAddressList = ApiCaller<FamrmartResponse<{}>>('fetch-buyer-AddressList',{
	type:'GET',
	url:'v4/buyer/address_list'
})

export const cancelSaudaDetails = ApiCaller<FamrmartResponse<{}>>('cancel_sauda_datail',{
    type:'GET',
	url:'v1/trade/sauda/cancellation_reasons',
})

export const rejectSaudaDetails = ApiCaller<FamrmartResponse<{}>>('cancel_sauda_datail',{
    type:'GET',
	url:'v1/trade/sauda/rejection_reasons',
})

export const tradeChangeStatus = ApiCaller<FamrmartResponse<{}>>('change_status',{
    type:'PATCH',
	url:'v2/trade/sauda/change_status',
})





