import React, {useEffect} from 'react'
import {toast} from 'react-toastify'
import {useAppDispatch} from '../../../../redux'
//Slice=================
import {retailerActions} from './../../../../redux/slice/procuremntslice/rejectedpo/slice.rejectedpo'
//Types=================
import {IRejectPoTableObj} from './../types'
//Utils=================
import {generateTableRows} from './../utils'

export default function useFetchRejectedPo() {
	const actionDispatch = useAppDispatch()

	const [tablePageData, setTablePageData] = React.useState({
		pageNo: 1,
		rowLimit: 50,
	})
	const [totalRows, setTotalRows] = React.useState<number>(0)
	const [rejectPoRows, setRejectedPoRows] = React.useState<IRejectPoTableObj[]>(
		[],
	)
	const [isDataLoading, setIsDataLoading] = React.useState<boolean>(false)

	const getAllRejectPoRec = async () => {
		try {
			setIsDataLoading(true)
			const api_res = await actionDispatch(
				retailerActions.fetchAllRejectedPoList({
					page_no: tablePageData.pageNo,
					record_limit: tablePageData.rowLimit,
				}),
			)
			if (api_res.payload.status) {
				const [reject_po_data, total_records] = [
					api_res.payload.data?.pending_po_details ?? [],
					api_res.payload.data?.total_records ?? 0,
				]

				const generated_Rows = generateTableRows(reject_po_data)
				setTotalRows(total_records)
				setRejectedPoRows(generated_Rows)
			} else {
				toast(api_res.payload.message ?? 'Api Error: Something went wrong', {
					type: 'error',
				})

				setRejectedPoRows([])
			}
			setIsDataLoading(false)
		} catch (error: any) {
			console.error(error)
		}
	}

	const onPageChange = (page: number) => {
		setTablePageData({...tablePageData, pageNo: ++page})
	}

	return {
		getAllRejectPoRec,
		rejectPoRows,
		isDataLoading,
		onPageChange,
		tablePageData,
		totalRows,
	}
}
