import {ApiCaller} from '../../component/apiRedux/apiCaller'
import {FarmartResponse} from '../thunk/reduxApis/states.api'

export interface EmpolyeeResponseInterface {
	id: number
	name: string
	role_id: number
	mobile_number: number
}

export const employeeRequestGet = ApiCaller<
	FarmartResponse<{users: EmpolyeeResponseInterface[]}>
>('users', {
	type: 'POST',
	requestData: {role_ids: [11]},
	url: '/v2/user',
})
