import {useEffect, useReducer, useRef, useState} from 'react'
import {Navigate} from 'react-router-dom'
import {toast} from 'react-toastify'
import {useAppDispatch, useAppSelector} from '../../redux'
import {authActions} from '../../redux/slice/slice.auth'
import styles from './NewLoginPage.module.scss'
import {LoginForm} from './views/LoginForm'
import {OtpVerification} from './views/OtpVerification'
import {LoginSplashScreen} from './views/splashscreens/LoginSplashScreen1'
import React from 'react'

interface LoginState {
	mobileNumber: string
	otp: string
	isValidMobileNumnber: boolean
}

export const NewLoginPage = () => {
	const [state, setState] = useReducer(
		(state: LoginState, newState: Partial<LoginState>) => {
			return {
				...state,
				...newState,
			}
		},
		{
			mobileNumber: '',
			otp: '',
			isValidMobileNumnber: false,
		},
	)
	const isLoggedIn = useAppSelector(state => state.auth.authenticated)
	const hasUserData = localStorage.getItem('user-data')
	const actionDispatch = useAppDispatch()
	const {mobileNumber, otp, isValidMobileNumnber} = state
	const [otpVerificationTimer, setOtpVerificationTimer] = useState<number>(0)
	const interval = useRef<any>()

	const onSubmitMobileNumber = async (mobileNumber: string) => {
		try {
			const trimmedMobileNumber = mobileNumber.trim()
			const hasDecimal =  trimmedMobileNumber.includes('.') // added decimal check as well
			if (hasDecimal) {
				toast('Enter a valid Mobile Number', {
					type: 'error',
				})
				return
			}
			const isValidMobileNumber = /^[0-9]{10}$/ // used regex to handle multiple issues
			if (!isValidMobileNumber.test(trimmedMobileNumber)) {
				toast('Enter a valid Mobile Number', {type: 'error'})
				return
			}
			await actionDispatch(authActions.sendOtp(mobileNumber))
			setState({mobileNumber: mobileNumber, isValidMobileNumnber: true})
			setOtpVerificationTimer(60000)
		} catch (err) {}
	}

	const onSubmitOtp = async (otp: string) => {
		try {
			await actionDispatch(
				authActions.verifyOtp({
					mobile: mobileNumber,
					otp,
				}),
			)
		} catch (err) {}
	}

	useEffect(() => {
		if (otpVerificationTimer <= 0 || interval.current) return
		interval.current = setInterval(() => {
			setOtpVerificationTimer(otpVerificationTimer => otpVerificationTimer - 1000)
			if (otpVerificationTimer <= 0) {
				clearInterval(interval.current)
				interval.current = undefined
			}
		}, 1000)
		return () => {
			clearInterval(interval.current)
			interval.current = undefined
		}
	}, [otpVerificationTimer])

	const resendOtp = async () => {
		if (otpVerificationTimer > 0) return
		try {
			await actionDispatch(authActions.sendOtp(mobileNumber))
			toast('Otp send successfully!', {
				type: 'success',
			})
			setOtpVerificationTimer(60000)
		} catch (err) {}
	}

	if (hasUserData && isLoggedIn) return <Navigate to='/protected' />

	return (
		<div className={styles.loginContainer}>
			<div className={styles.imageContainer}>
				<LoginSplashScreen />
			</div>
			<div className={styles.actionContainer}>
				{!isValidMobileNumnber ? (
					<LoginForm onChange={onSubmitMobileNumber} value={mobileNumber} />
				) : (
					<OtpVerification
						otpVerificationTimer={otpVerificationTimer}
						mobileNumber={mobileNumber}
						onChange={onSubmitOtp}
						value={otp}
						onEditMobileNumber={() => {
							setState({isValidMobileNumnber: false})
						}}
						resendOtp={resendOtp}
					/>
				)}
			</div>
		</div>
	)
}
