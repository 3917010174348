import {createSlice, current} from '@reduxjs/toolkit'
import {
	fetchSupplier,
	fetchStateDistrict,
	fetchAllKobNames,
	fetchAllProductGivenKobNames,
	fetchRmList,
	fetchUAList,
	assignLeadToVasRM,
	assignLeadToVasUA,
	fetchSupplierProductList,
	addProductListWithSupplier,
	fetchSupplierListFromFirebase,
	serachSupplierListFromFirebase,
	updateSupplierStatus,
	getRecordById,
} from '../thunk/thunk.supplier'
import {toast} from 'react-toastify'
import { fetchBuyerCreditRiskEvaluationDetail } from '../thunk/thunk.sales'

const initialState: any = {
	supplierList: [],
	fetchingSupplierList: true,
	totalRecords: 0,
	stateDistrictData: {},
	state: [],
	district: [],
	fetchingStateDistrict: false,
	konNames: [],
	fetchingKobNames: false,
	productKobNames: [],
	fetchingProductKobNames: false,
	rmList: [],
	rmListObejct: {},
	fetchingRmList: false,
	assigningLeadToRm: false,
	fetchingProductList: false,
	selectedSupplierList: [],
	selectedSupplierListTotalCount: 0,
	selectedSupplierSnapshot: null,
}

export const sliceSupplierData = createSlice({
	name: 'supplier',
	initialState,
	reducers: {
		onSelectDistrict: (state, action) => {
			let stateDistrictData = {...current(state).stateDistrictData}
			state.district = stateDistrictData[action.payload]
		},
	},
	extraReducers: builder => {
		/* fatch supplier */
		builder.addCase(fetchSupplier.pending, state => {
			state.fetchingSupplierList = true
		})
		builder.addCase(fetchSupplier.fulfilled, (state, action) => {
			state.fetchingSupplierList = false
			if (action.payload?.filtered_records?.message) {
				state.supplierList = []
				state.totalRecords = 0
				const message = action.payload?.message as string
				toast(message, {type: 'error'})
			} else {
				state.supplierList = action.payload?.filtered_records
				state.totalRecords = action.payload?.number_of_records
			}
		})
		builder.addCase(fetchSupplier.rejected, (state, action) => {
			state.fetchingSupplierList = false
			const message = action.payload as string
			toast(message, {type: 'error'})
		})
		/* fatch product list  */
		builder.addCase(fetchSupplierProductList.pending, state => {
			state.fetchingProductList = true
		})
		builder.addCase(fetchSupplierProductList.fulfilled, (state, action) => {
			state.fetchingProductList = false
		})
		builder.addCase(fetchSupplierProductList.rejected, (state, action) => {
			state.fetchingProductList = false
			const message = action.payload as string
			toast(message, {type: 'error'})
		})
		/* fatch state district  */
		builder.addCase(fetchStateDistrict.pending, state => {
			state.fetchingStateDistrict = true
		})
		builder.addCase(fetchStateDistrict.fulfilled, (state, action) => {
			state.fetchingStateDistrict = false
			state.stateDistrictData = action.payload
			state.state = Object.keys(action.payload)
		})
		builder.addCase(fetchStateDistrict.rejected, (state, action) => {
			state.fetchingStateDistrict = false
			const message = action.payload as string
			toast(message, {type: 'error'})
		})
		/* fatch kob names  */
		builder.addCase(fetchAllKobNames.pending, state => {
			state.fetchingKobNames = true
		})
		builder.addCase(fetchAllKobNames.fulfilled, (state, action) => {
			state.fetchingKobNames = false
			state.konNames = action.payload?.kob_names
		})
		builder.addCase(fetchAllKobNames.rejected, (state, action) => {
			state.fetchingKobNames = false
			const message = action.payload as string
			toast(message, {type: 'error'})
		})
		/* fatch product of kob names  */
		builder.addCase(fetchAllProductGivenKobNames.pending, state => {
			state.fetchingProductKobNames = true
		})
		builder.addCase(fetchAllProductGivenKobNames.fulfilled, (state, action) => {
			state.fetchingProductKobNames = false
			state.productKobNames = action.payload?.product_list
		})
		builder.addCase(fetchAllProductGivenKobNames.rejected, (state, action) => {
			state.fetchingProductKobNames = false
			const message = action.payload as string
			toast(message, {type: 'error'})
		})
		/* fatch rm list  */
		builder.addCase(fetchRmList.pending, state => {
			state.fetchingRmList = true
		})
		builder.addCase(fetchRmList.fulfilled, (state, action) => {
			state.fetchingRmList = false
			state.rmList = action.payload?.users
			state.rmListObejct = convertInobject(action.payload?.users)
		})
		builder.addCase(fetchRmList.rejected, (state, action) => {
			state.fetchingRmList = false
			const message = action.payload as string
			toast(message, {type: 'error'})
		})

		/* fatch UA list  */
		builder.addCase(fetchUAList.pending, state => {
			state.fetchingUAList = true
		})
		builder.addCase(fetchUAList.fulfilled, (state, action) => {
			state.fetchingUAList = false
			state.uaList = action.payload?.users
			state.uaListObejct = convertInobject(action.payload?.users)
		})
		builder.addCase(fetchUAList.rejected, (state, action) => {
			state.fetchingUAList = false
			const message = action.payload as string
			toast(message, {type: 'error'})
		})

		/* Assign Lead To Vas RM */
		builder.addCase(assignLeadToVasRM.pending, state => {
			state.assigningLeadToRm = true
		})
		builder.addCase(assignLeadToVasRM.fulfilled, (state, action) => {
			state.assigningLeadToRm = false
			const message = action.payload as string
			toast(message, {type: 'success'})
		})
		builder.addCase(assignLeadToVasRM.rejected, (state, action) => {
			state.assigningLeadToRm = false
			const message = action.payload as string
			toast(message, {type: 'error'})
		})

		/* update lead */

		builder.addCase(updateSupplierStatus.pending, state => {
			state.updatingLead = true
		})

		builder.addCase(updateSupplierStatus.fulfilled, (state, action) => {
			state.updatingLead = false
			const message = action.payload as string
			toast(message, {type: 'success'})
		})

		builder.addCase(updateSupplierStatus.rejected, (state, action) => {
			state.updatingLead = false
			const message = action.payload as string
			toast(message, {type: 'error'})
		})

		builder.addCase(assignLeadToVasUA.pending, state => {
			state.assigningLeadToUA = true
		})
		builder.addCase(assignLeadToVasUA.fulfilled, (state, action) => {
			state.assigningLeadToUA = false
			const message = action.payload as string
			toast('Successfully UA assigned to Lead', {type: 'success'})
		})
		builder.addCase(assignLeadToVasUA.rejected, (state, action) => {
			state.assigningLeadToUA = false
			const message = action.payload as string
			toast(message, {type: 'error'})
		})

		/* fetch supplier list from firebase */
		builder.addCase(fetchSupplierListFromFirebase.pending, state => {
			state.fetchingSupplierList = true
		})
		builder.addCase(fetchSupplierListFromFirebase.fulfilled, (state, action) => {
			state.fetchingSupplierList = false
			state.selectedSupplierList = action.payload.data
			state.selectedSupplierSnapshot = action.payload.snapshots
			state.selectedSupplierListTotalCount = action.payload.totalCount
		})
		builder.addCase(fetchSupplierListFromFirebase.rejected, (state, action) => {
			state.fetchingSupplierList = false
			const message = action.payload as string
			toast(message, {type: 'error'})
		})
		builder.addCase(serachSupplierListFromFirebase.pending, state => {
			state.fetchingSupplierList = true
		})
		builder.addCase(serachSupplierListFromFirebase.fulfilled, (state, action) => {
			state.fetchingSupplierList = false
			state.selectedSupplierList = action.payload.data
			state.selectedSupplierSnapshot = action.payload.snapshots
			state.selectedSupplierListTotalCount=action.payload.totalCount

		})
		builder.addCase(serachSupplierListFromFirebase.rejected, (state, action) => {
			state.fetchingSupplierList = false
			const message = action.payload as string
			toast(message, {type: 'error'})
		})
	},
})
function convertInobject(data: any) {
	let newData: any = {}
	data.forEach((value: any) => {
		newData[value.id] = value
	})
	return newData
}
export const soTargetAction = {
	...sliceSupplierData.actions,
	fetchSupplier,
	fetchStateDistrict,
	fetchAllKobNames,
	fetchAllProductGivenKobNames,
	fetchRmList,
	fetchUAList,
	assignLeadToVasRM,
	assignLeadToVasUA,
	fetchSupplierProductList,
	addProductListWithSupplier,
	fetchSupplierListFromFirebase,
	serachSupplierListFromFirebase,
	updateSupplierStatus,
	getRecordById,
	fetchBuyerCreditRiskEvaluationDetail,
}

export default sliceSupplierData.reducer
