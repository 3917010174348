import React, {useEffect} from 'react'
import {GridRowParams} from '@mui/x-data-grid/models/params/gridRowParams'
import {toast} from 'react-toastify'
import {useAppDispatch, useAppSelector} from '../../../../redux'
import {useNavigate} from 'react-router-dom'
import {procurementAction} from '../../../../redux/slice/slice.procurement'

export default function useRejectedPoTable() {
	const dispatch = useAppDispatch()
	const navigate = useNavigate()
	const onTableRowSelection = (params: GridRowParams) => {
		if (params.row.po_pending_in_hand_id) {
			navigate(
				`/protected/inventory/rejectedpodetails/${params.row.po_pending_in_hand_id}`,
			)
			dispatch(procurementAction.setRejectedPoId(params.row.po_pending_in_hand_id))
		} else {
			toast('Po Pending In Hand Id is Missing', {type: 'error'})
		}
	}
	return {onTableRowSelection}
}
