import {createSlice} from '@reduxjs/toolkit'
import {ISalesState} from '../types/type.sales'
import {
	getallbuyer,
	getallsalesOrder,
	editbuyer,
	editSalesorder,
	fetchAllRepaymentList,
	fetchBuyerFinanceDetails,
	takeActionOnBuyer,
	editBuyerInfoByFc,
	buyerPushToZoho,
	approveBuyerCreditLimit,
	fetchUniqueSalesOeder
} from '../thunk/thunk.sales'
import {toast} from 'react-toastify'

const initialState: ISalesState = {
	isFetching: false,
	isSalesOrderFetching: false,
	buyers: [],
	saorder: [],
	sampleApprovalList: [],
	buyerFinanceDetails: null,
	sampleDetail: {
		buyer: '',
		buyerAddress: '',
		cropName: '',
		images: [],
		videos: [],
		weight: '',
	},
	selectedbuyer: null,
	selectedsaleorder: null,
	totalOrder: 0,
	buyerFinanceDetailsFetching: false,
	isSubmitLoading: false,
}

export const sliceSales = createSlice({
	name: 'sales',
	initialState,
	reducers: {
		setselectedbuyer: (state, action) => {
			state.selectedbuyer = action.payload
		},
		setSelectedSaleOrder: (state, action) => {
			state.selectedsaleorder = action.payload
		},
		setBuyerFinanceDetails: (state, action) => {
			state.buyerFinanceDetails = action.payload
		},
		setBuyerUpdate: (state, action) => {
			const allbuyers = state.buyers
			const indexOfBuyer = allbuyers.findIndex(item => {
				return item._id == state.selectedbuyer?._id
			})
			state.buyers[indexOfBuyer] = action.payload
			if (action.payload.permissions['1']) {
				toast('Buyer Unsuspended', {type: 'success'})
			} else {
				toast('Buyer Suspended', {type: 'success'})
			}
		}
	},
	extraReducers: builder => {
		builder.addCase(getallbuyer.rejected, state => {
			state.isFetching = false
		})
		builder.addCase(getallbuyer.pending, state => {
			state.isFetching = true
		})
		builder.addCase(getallbuyer.fulfilled, (state, action) => {
			state.isFetching = false
			state.buyers = action.payload
		})
		builder.addCase(editbuyer.pending, state => {
			state.isSubmitLoading = true
		})
		builder.addCase(editbuyer.rejected, (state, payload) => {
			state.isSubmitLoading = false
			toast(payload.payload as string, {type: 'error'})
		})
		builder.addCase(editbuyer.fulfilled, (state, payload) => {
			state.isSubmitLoading = false
			const allbuyers = state.buyers
			const indexOfBuyer = allbuyers.findIndex(item => {
				return item._id == state.selectedbuyer?._id
			})
			state.buyers[indexOfBuyer] = payload.payload
			toast('Buyer Updated Successfully', {type: 'success'})
		})
		builder.addCase(editBuyerInfoByFc.pending, state => {
			state.isSubmitLoading = true
		})
		builder.addCase(editBuyerInfoByFc.rejected, (state, payload) => {
			state.isSubmitLoading = false
			toast(payload.payload as string, {type: 'error'})
		})
		builder.addCase(editBuyerInfoByFc.fulfilled, (state, payload) => {
			state.isSubmitLoading = false
			toast('Buyer Updated Successfully', {type: 'success'})
		})
		builder.addCase(buyerPushToZoho.pending, (state, payload) => {
			state.isSubmitLoading = true
		})
		builder.addCase(buyerPushToZoho.rejected, (state, payload) => {
			state.isSubmitLoading = false
			toast(payload.payload as string, {type: 'error'})
		})
		builder.addCase(buyerPushToZoho.fulfilled, (state, payload) => {
			state.isSubmitLoading = false
			toast('Buyer Successfully Push To Zoho', {type: 'success'})
		})
		builder.addCase(approveBuyerCreditLimit.pending, (state, payload) => {
			state.isSubmitLoading = true
		})
		builder.addCase(approveBuyerCreditLimit.rejected, (state, payload) => {
			state.isSubmitLoading = false
			toast(payload.payload as string, {type: 'error'})
		})
		builder.addCase(approveBuyerCreditLimit.fulfilled, (state, payload) => {
			state.isSubmitLoading = false
			state.selectedbuyer = payload.payload
			toast('Buyer Approved', {type: 'success'})
		})

		builder.addCase(getallsalesOrder.rejected, state => {
			state.isSalesOrderFetching = false
		})
		builder.addCase(getallsalesOrder.pending, state => {
			state.isSalesOrderFetching = true
		})
		builder.addCase(getallsalesOrder.fulfilled, (state, action) => {
			state.isSalesOrderFetching = false
			state.saorder = action.payload.buyer_orders
			state.totalOrder = action.payload.total_records
		})

		builder.addCase(editSalesorder.rejected, (state, action) => {
			toast(action.payload, {type: 'error'})
		})
		builder.addCase(editSalesorder.fulfilled, state => {
			toast('Sales Order Updated Successfully', {type: 'success'})
		})
		builder.addCase(fetchBuyerFinanceDetails.rejected, (state, action) => {
			state.buyerFinanceDetailsFetching = false
			toast(action.payload, {type: 'error'})
		})
		builder.addCase(fetchBuyerFinanceDetails.pending, state => {
			state.buyerFinanceDetailsFetching = true
		})
		builder.addCase(fetchBuyerFinanceDetails.fulfilled, (state, action) => {
			state.buyerFinanceDetailsFetching = false
			state.buyerFinanceDetails = action.payload.data ?? null
		})
	},
})

export const salesActions = {
	getallbuyer,
	getallsalesOrder,
	editbuyer,
	editSalesorder,
	fetchAllRepaymentList,
	fetchBuyerFinanceDetails,
	takeActionOnBuyer,
	editBuyerInfoByFc,
	buyerPushToZoho,
	approveBuyerCreditLimit,
	fetchUniqueSalesOeder,
	...sliceSales.actions,
}

export default sliceSales.reducer
