import {
	useRef,
	useState
} from 'react'
import { useAppDispatch, useAppSelector } from '../../../redux'
import styles from './OtpVerification.module.scss'
import {authActions} from '../../../redux/slice/slice.auth'
export interface OtpFormProps {
	onChange: (val: string) => void
	onEditMobileNumber: () => void
	value: string
	mobileNumber: string
	resendOtp: () => void,
	otpVerificationTimer: number
}

export const OtpVerification = (props: OtpFormProps) => {
	const {
		onChange,
		resendOtp,
		mobileNumber,
		onEditMobileNumber,
		otpVerificationTimer,
	} = props
	const authState = useAppSelector(state => state.auth)
	const actionDispatch = useAppDispatch()
	const ref1 = useRef<any>()
	const [state, setState] = useState({
		id1: '',
		id2: '',
		id3: '',
		id4: '',
	})
	const [isInValidOtp, setIsInValidOtp] = useState<boolean>(false)

	const onKeyDown = (id: number) => {
		return (evt: any) => {
			const inputs = ref1.current.elements
			if (evt.key === 'Backspace' || evt.key === 'Delete') {
				if (id == 1) return
				inputs[id - 2].focus()
			} else {
				if (id == 4) return
				inputs[id].focus()
			}
		}
	}

	const onChangeHandel = (id: number) => {
		return (evt: any) => {
			const str = evt.target.value + ''
			if (str.length >= 2) return
			setState({
				...state,
				[`id${id}`]: str,
			})
		}
	}

	const onSubmit = () => {
		let str = ''
		const inputs = ref1.current.elements
		for (let input of inputs) {
			str += input.value
		}
		if (str.trim().length < 4) {
			setIsInValidOtp(true)
			return
		} else {
			setIsInValidOtp(false)
		}
		onChange(str);
	}

	return (
		<div className={styles.otpForm}>
			<div className={styles.header}>
				<h1 className={styles.header__header1}>OTP Verification</h1>
				<p className={styles.header__editText}>
					Enter OTP send to <br/>
					+91-{mobileNumber}{' '}
				</p>
				<a
					className={styles.header__editIcon}
					href='#'
					onClick={() =>{
						actionDispatch(authActions.resetErrorMessage())
						onEditMobileNumber()
					}}
				>
					<i
						className='fa fa-pencil'
						style={{
							marginRight: '5px',
							color: '#0072C3',
						}}
					></i>
					<span
						style={{
							color: '#0072C3',
						}}
					>
						Edit
					</span>
				</a>
				<form ref={ref1} className={styles.loginInput}>
					<input
						value={state.id1}
						className={`${styles.inputField} ${
							authState.hasError || isInValidOtp ? styles['inputField--error'] : ''
						}`}
						onChange={onChangeHandel(1)}
						onKeyUp={onKeyDown(1)}
						maxLength={1}
						type='number'
						disabled={authState.isVerifyingOtp}
					/>
					<input
						value={state.id2}
						className={`${styles.inputField} ${
							authState.hasError || isInValidOtp ? styles['inputField--error'] : ''
						}`}
						onChange={onChangeHandel(2)}
						onKeyUp={onKeyDown(2)}
						maxLength={1}
						type='number'
						disabled={authState.isVerifyingOtp}
					/>
					<input
						value={state.id3}
						className={`${styles.inputField} ${
							authState.hasError || isInValidOtp ? styles['inputField--error'] : ''
						}`}
						onChange={onChangeHandel(3)}
						onKeyUp={onKeyDown(3)}
						maxLength={1}
						disabled={authState.isVerifyingOtp}
						type='number'
					/>
					<input
						value={state.id4}
						className={`${styles.inputField} ${
							authState.hasError || isInValidOtp ? styles['inputField--error'] : ''
						}`}
						onChange={onChangeHandel(4)}
						onKeyUp={onKeyDown(4)}
						disabled={authState.isVerifyingOtp}
						maxLength={1}
						type='number'
					/>
				</form>
				{authState.hasError ? (
					<p className={styles.error}>{authState.errorMessage}</p>
				) : (
					''
				)}
				<div
					className={`${styles.resendText} ${
						otpVerificationTimer > 0 ? styles['resendText--disabled'] : ''
					}`}
					onClick={()=>{
						setState({
							id1: '',
							id2: '',
							id3: '',
							id4: '',
						})
						resendOtp()
					}}
				>
					{otpVerificationTimer > 0 ? (
						<span
							style={{
								color: '#AFAFB1',
							}}
						>
							  00:{String(otpVerificationTimer / 1000).padStart(2, '0')}
						</span>
					) : authState.isSendingOtp ? (
						'Plese wait...'
					) : (
						'Resend OTP'
					)}
				</div>
				<button
					className={styles.submitButton}
					onClick={onSubmit}
					disabled={authState.isVerifyingOtp}
				>
					{authState.isVerifyingOtp ? 'Loading...' : 'Submit'}
				</button>
			</div>
		</div>
	)
}
