import { createAsyncThunk } from "@reduxjs/toolkit";
import ServiceApi from "../../service/service.api";
import { ICreateNewWarehouse, IInventory, IWarehouse } from "../types/type.inventory";

export type FetchWarehouseOption = {
    page?: number,
    limit?: number,
    search_by?: string | {},
    state_id?: string | number | {} | null,
    district_id?: string | {}
}
export type RemapOption = {
    warehouse_id: number,
    pending_in_hand_id: number
}

const verifyNewWarehouse = (warehouse: Partial<ICreateNewWarehouse>) => {
    if (!warehouse.name)
        throw new Error("Warehouse name is required.");
    if (!warehouse.address)
        throw new Error("Warehouse address is required.");
    if (!warehouse.pincode)
        throw new Error("Warehouse pincode is required.");
    if (`${warehouse.pincode ?? ""}`.length !== 6)
        throw new Error("Warehouse pincode should be of 6 digit.");
    if (!warehouse.stateId)
        throw new Error("Warehouse state is required.");
    if (!warehouse.districtId)
        throw new Error("Warehouse district is required.");
    if (!warehouse.inchargeName1)
        throw new Error("Warehouse incharge name 1 is required.");
    if (!warehouse.inchargeMobile1)
        throw new Error("Warehouse incharge 1 mobile number is required.");
    if (!warehouse.storageAreaSqFt)
        throw new Error("Warehouse storage area in Sq / Ft is required.");
    if (!warehouse.storageCapacityBags)
        throw new Error("Warehouse storage capacity (bags) is required.");
    if (`${warehouse.inchargeMobile1}`.length !== 10)
        throw new Error("Mobile number should be of 10 digit.");
    if ((warehouse.inchargeName2 && !warehouse.inchargeMobile2)
        || (warehouse.inchargeMobile2 && !warehouse.inchargeName2))
        throw new Error("Name and Mobile number both feilds are required.");
    if (warehouse.inchargeMobile2 && `${warehouse.inchargeMobile2}`.length !== 10)
        throw new Error("Mobile number should be of 10 digit.");
}

export const fetchWarehouseList = createAsyncThunk("inventory/warehouse-fetch", async (option: FetchWarehouseOption, thunkAPI) => {
    try {
        const { status, data } = await ServiceApi.getInstance()
            .GET("v1/warehouse/details", {
                params: {
                    page: option.page,
                    limit: option.limit,
                    search_by: option.search_by,
                    state_id:option.state_id,
                    district_id:option.district_id
                }
            });
        if (status !== 200 || !data.status)
            throw new Error(data.message ?? "Unable to fetch inventory list.");
        return {
            warehouseList: data.data.inventories as IWarehouse[],
            totalWarehouse: data.data.total_records as number
        };
    } catch (err: any) {
        return thunkAPI.rejectWithValue(err.message);
    }
});

export const onboardWarehouse = createAsyncThunk("inventory/warehouse-onboard", async (warehouse: Partial<ICreateNewWarehouse>, thunkAPI) => {
    try {
        verifyNewWarehouse(warehouse);
        // onboard new warehouse.
        const newWarehouse = {
            warehouse_name: warehouse.name,
            address: warehouse.address,
            pincode: `${warehouse.pincode}`,
            state_id: 1,
            district_id: 1,
            storage_area_sqft: warehouse.storageAreaSqFt,
            storage_capacity_no_of_bag: warehouse.storageCapacityBags,
        };
        const warehouseRes = await ServiceApi.getInstance().POST("/v1/warehouse", newWarehouse);
        if (warehouseRes.status !== 200 || !warehouseRes.data.status)
            throw new Error(warehouseRes.data.message ?? "Unable to onboard new warehouse.");
        // make another api call to add warehouse incharge.
        const warehouseIncharge = {
            warehouse_id: warehouseRes.data.data.warehouse_id,
            incharge_list: [
                {
                    name: warehouse.inchargeName1,
                    mobile_number: `${warehouse.inchargeMobile1}`
                }
            ]
        };
        if (warehouse.inchargeName2 && warehouse.inchargeMobile2) {
            warehouseIncharge.incharge_list.push({
                name: warehouse.inchargeName2,
                mobile_number: `${warehouse.inchargeMobile2}`
            });
        }
        const inchargeRes = await ServiceApi.getInstance().POST("/v1/warehouse/incharge", warehouseIncharge);
        if (inchargeRes.status !== 200 || !inchargeRes.data.status)
            throw new Error(inchargeRes.data.message ?? "Unable to add warehouse incharge.");
        
        // refresh all inventory list.
        thunkAPI.dispatch(fetchAllInventory());
        return thunkAPI.fulfillWithValue(warehouse.name ?? "");
    } catch (err: any) {
        return thunkAPI.rejectWithValue(err.message);
    }
});

export const fetchAllInventory = createAsyncThunk("inventory/all", async (_, thunkAPI) => {
    try {
        const { status, data } = await ServiceApi.getInstance().GET("/v1/warehouse");
        if (status !== 200 || !data.status)
            throw new Error(data.message ?? "Unable to fetch warehouse list.");
        return data.data.warehouse_list as IInventory[];
    } catch(err: any) {
        return thunkAPI.rejectWithValue(err.message);
    }
});

export const remapWithNewWarehouse = createAsyncThunk("inventory/remap", async (option: RemapOption, thunkAPI) => {
    try {
        if (!option.warehouse_id)
            throw new Error("wrong warehouse selected.");
        if (!option.pending_in_hand_id)
            throw new Error("wrong pending inhand selected.");
        const { status, data } = await ServiceApi
            .getInstance()
            .POST("/v1/warehouse/remap_warehouse", option);
        if (status !== 200 || !data.status)
            throw new Error(data.message ?? "unable to remap, try again.");
        return {
            success: data.status as boolean,
            ...option
        };
    } catch (err: any) {
        return thunkAPI.rejectWithValue(err.message);
    }
});