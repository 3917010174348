import {buyersApis} from './buyers'
import {
	affiliatedGstDetail,
	buyerDetails,
	buyerDocuments,
	buyerMillDetails,
	updateBuyerDetails,
} from './buyers/buyer.api'
import {
	createCropApi,
	createCropVariety,
	createVarietyQcChecklist,
	deleteVarietyQcChecklist,
	fetchCropApiGet,
	fetchCropApiV2,
	fetchCropVarieties,
	fetchDispatchDocList,
	fetchQcChecklist,
	fetchVarietyDispatchDoc,
	fetchVarietyQcChecklist,
	fetchVarietySampling,
	updateVarietyQcChecklist,
} from './crops.api'

import {buyerApis} from '../../../page/sales/buyerV3/apis/buyerapis'
import {empoloyeeApi} from '../../employee'
import {
	fetchUserActivityApiGet,
	fetchUserActivityEnumsApiGet,
} from './accessCreation.api'
import {configApis} from './config'
import {deliveriesApis} from './deliveries'
import {getUrlForImage} from './imageUpload.api'
import {
	fetchTerminationReasonApiGet,
	updateTerminationReasonApiPut,
} from './procurement.api'
import {procurmentApis} from './procurment'
import {getBankNameFromIfsc} from './procurment/retailers/retailers.api'
import {getBankDetailsthunkApis, retailerKycDetailsDocs} from './retailer.api'
import {regionalManagerRequestGet} from './rmList.api'
import {
	districtRequestGet,
	getStateDistrictFromPinCode,
	statesRequestGet,
} from './states.api'
import {warehouseApis} from './warehouse'
import {paymentApis} from '../../../page/payments/paymentv2/apis'
import { paymentTrenchesApi } from '../../../page/procurement/pocreation/paymentTrenches/apis'
import {supplierApis} from '../../../page/valueAddedServices/apis/supplierAllApis'
import {tradeApis} from './trade'

export const thunkApis = [
	...empoloyeeApi,
	buyerDetails,
	updateBuyerDetails,
	buyerDocuments,
	buyerMillDetails,
	affiliatedGstDetail,
	statesRequestGet,
	getStateDistrictFromPinCode,
	districtRequestGet,
	regionalManagerRequestGet,
	fetchCropApiGet,
	getBankDetailsthunkApis,
	retailerKycDetailsDocs,
	fetchTerminationReasonApiGet,
	updateTerminationReasonApiPut,
	fetchCropVarieties,
	fetchCropApiV2,
	fetchVarietyQcChecklist,
	fetchVarietySampling,
	fetchVarietyDispatchDoc,
	fetchDispatchDocList,
	fetchQcChecklist,
	updateVarietyQcChecklist,
	deleteVarietyQcChecklist,
	createVarietyQcChecklist,
	createCropApi,
	createCropVariety,
	getBankNameFromIfsc,
	fetchUserActivityApiGet,
	fetchUserActivityEnumsApiGet,
	getUrlForImage,
	...buyersApis,
	...deliveriesApis,
	...procurmentApis,
	...configApis,
	...warehouseApis,
	...buyerApis,
	...paymentApis,
	...paymentTrenchesApi,
	...supplierApis,
...tradeApis,
]
