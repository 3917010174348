import {ApiCaller} from '../../../../component/apiRedux/apiCaller'
import {FarmartResponse} from '../../../../redux/thunk/reduxApis/states.api'
import {PaymentStatus} from '../enums/payment.constant'

export const addEditUtrApi = ApiCaller<
	FarmartResponse<any>,
	any,
	{
		id: string
		status: string
		utrNumber: string
	}
>('addEditUtrNumber', {
	type: 'POST',
	url: 'v1/payment/utr_number',
})
