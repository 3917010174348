import {
	expireTrade,
	fetchRejectedReasonList,
	fetchTradeBuyerList,
} from './trade.api'

export const tradeApis = [
	fetchRejectedReasonList,
	expireTrade,
	fetchTradeBuyerList,
]
