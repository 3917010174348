import ListItem from '@mui/material/ListItem'
import { useTheme } from '@mui/material/styles'
import React, { Suspense, createRef, useEffect } from 'react'
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom'
import { Popup } from 'reactjs-popup'
import Container from '../component/container'
import Loader from '../component/loader'
import PrivateRoute from '../component/route'
import Row from '../component/row'
import Title from '../component/title'
import {Header} from '../componentV2/organism/header'
import {NewLoginPage} from '../page/newLoginPage/NewLoginPage'
import RejectedPoTableScreen from '../page/procurement/rejectedPoTable/RejectedPoTableScreen'
import SecondaryUnloadingMain from '../page/procurement/sencondaryUnloading/SecondaryUnloadingDetail'
import {RenderV3BuyerRoute} from '../page/sales/buyerV3/buyerV3.route'
import {authActions} from '../redux/slice/slice.auth'
import NavSidebar from '../sidebar'
import {setRecentBrowserHistory} from '../utils/getRecentBrowserHistory'
import Button from './../component/button'
import {useAppDispatch, useAppSelector} from './../redux'
import {toast} from 'react-toastify'
import { renderPaymentV2Route } from '../page/payments/paymentv2/payment.route'
import { userActions } from '../redux/slice/slice.user'
import { RenderRetailerDetailsRoute } from '../page/procurement/retailer/retailerDetail.route'
import { RenderPurchaseOrdersDetailsRoute } from '../page/procurement/orders/orderDetail.route'
import { RenderSalesOrderDetailsRoute } from '../page/sales/soApprovaltab/sodetail.route'
import { RenderSupplyDetailsRoute } from '../page/procurement/SupplyVisibility/supplyVisiblity.route'
import ENV from './../config/environment'

const VisitsTracking = React.lazy(
	() => import('../page/access-creation/VisitsTracking/VisitsTracking'),
)

// lazy load all the routes
const AccessCreation = React.lazy(
	() => import('../page/access-creation/AccessCreation'),
)

const MapVisits = React.lazy(
	() => import('../page/access-creation/VisitsTracking/MapView/MapView'),
)

const AccessCreationPage = () => (
	<Suspense fallback={<Loader />}>
		<AccessCreation />
	</Suspense>
)

const EmployeeVisits = () => (
	<Suspense fallback={<Loader />}>
		<VisitsTracking />
	</Suspense>
)
const MapViewWithDetails = () => (
	<Suspense fallback={<Loader />}>
		<MapVisits />
	</Suspense>
)

const LoginScreen = React.lazy(() => import('../page/auth'))
const LoginPage = () => (
	<Suspense fallback={<Loader />}>
		<NewLoginPage />
	</Suspense>
)

/* ==========dashboard========== */
// added new dashboards routes and removed the previous routes
const CompanyMetricsDashboard = React.lazy(
	() => import('../page/dashboard/companyMetrics'),
)

const SellerMetricsDashboard = React.lazy(
	() => import('../page/dashboard/sellerMetrics'),
)
const OperationFunnelsDashboard = React.lazy(
	() => import('../page/dashboard/operationFunnels'),
)
const ProcurementDashboard = React.lazy(
	() => import('../page/dashboard/procurement'),
)
const BuyerOverView = React.lazy(
	() => import('../page/dashboard/buyerOverView'),
)
const FinanceDashboard = React.lazy(
	() => import('../page/dashboard/finance'),
)
const ProductFarmartApp = React.lazy(
	() => import('../page/dashboard/productFarmart'),
)
const SaudaBookApp = React.lazy(
	() => import('../page/dashboard/productSaudaBook'),
)
const CompanyMetricsDashboardPage = () => (
	<Suspense fallback={<Loader />}>
		< CompanyMetricsDashboard/>
	</Suspense>
)
const SellerMetricsDashboardPage = () => (
	<Suspense fallback={<Loader />}>
		<SellerMetricsDashboard />
	</Suspense>
)
const OperationFunnelsPage = () => (
	<Suspense fallback={<Loader />}>
		<OperationFunnelsDashboard />
	</Suspense>
)

const ProcurementDashboardPage = () => (
	<Suspense fallback={<Loader />}>
		<ProcurementDashboard />
	</Suspense>
)


const BuyerOverViewPage = () => (
	<Suspense fallback={<Loader />}>
		<BuyerOverView />
	</Suspense>
)

const FinanceDashboardPage = () => (
	<Suspense fallback={<Loader />}>
		<FinanceDashboard />
	</Suspense>
)

const ProductFarmartAppPage = () => (
	<Suspense fallback={<Loader />}>
		<ProductFarmartApp />
	</Suspense>
)

const SaudaBookAppPage = () => (
	<Suspense fallback={<Loader />}>
		<SaudaBookApp />
	</Suspense>
)

/* ==========sales========== */
const Buyer = React.lazy(() => import('../page/sales/newBuyerTab'))
const BuyerPage = () => (
	<Suspense fallback={<Loader />}>
		<Buyer />
	</Suspense>
)

const SalesOrder = React.lazy(() => import('../page/sales/soApprovaltab'))
const SalesOrderPage = () => (
	<Suspense fallback={<Loader />}>
		<SalesOrder />
	</Suspense>
)
const SalesOrderFullInfo = React.lazy(
	() => import('../page/sales/soApprovaltab/view'),
)
const SalesOrderFullInfoPage = () => (
	<Suspense fallback={<Loader />}>
		<SalesOrderFullInfo />
	</Suspense>
)
const RePayments = React.lazy(
	() => import('../page/sales/newRepaymentTab/NewRepaymentContainer'),
)
const RePaymentsPage = () => (
	<Suspense fallback={<Loader />}>
		<RePayments />
	</Suspense>
)

/* ==========Payment========== */

const Payment = React.lazy(() => import('../page/payments/payment'))
const PaymentPage = () => (
	<Suspense fallback={<Loader />}>
		<Payment />
	</Suspense>
)
const MandiTax = React.lazy(() => import('../page/payments/manditax'))
const MandiTaxPage = () => (
	<Suspense fallback={<Loader />}>
		<MandiTax />
	</Suspense>
)
const InvoiceList = React.lazy(() => import('../page/payments/invoice'))
const InvoiceListPage = () => (
	<Suspense fallback={<Loader />}>
		<InvoiceList />
	</Suspense>
)
//======= Trade Routes=====//
const BuyerPrices = React.lazy(
	() => import('../page/procurement/buyerPrices/main'),
)
const BuyerPricesPage = () => (
	<Suspense fallback={<Loader />}>
		<BuyerPrices />
	</Suspense>
)
const SupplierSauda = React.lazy(
	() => import('../page/trade/supplierSauda/main'),
)
const SupplierSaudaPage = () => (
	<Suspense fallback={<Loader />}>
		<SupplierSauda />
	</Suspense>
)
//======Procurement Routes======//
const Retailer = React.lazy(() => import('../page/procurement/retailer'))
const RetailerPage = () => (
	<Suspense fallback={<Loader />}>
		<Retailer />
	</Suspense>
)
const ProcurementOrder = React.lazy(() => import('../page/procurement/orders'))
const ProcurementOrderPage = () => (
	<Suspense fallback={<Loader />}>
		<ProcurementOrder />
	</Suspense>
)
const SupplyVisibility = React.lazy(
	() => import('../page/procurement/SupplyVisibility'),
)
const SupplyVisibilityPage = () => (
	<Suspense fallback={<Loader />}>
		<SupplyVisibility />
	</Suspense>
)
const SalesOrderTarget = React.lazy(
	() => import('../page/procurement/so-target'),
)
const SalesOrderTargetPage = () => (
	<Suspense fallback={<Loader />}>
		<SalesOrderTarget />
	</Suspense>
)
const DropDown = React.lazy(
	() => import('../page/procurement/Procurementdropdown/Procurementdropdown'),
)
const DropDownPage = () => (
	<Suspense fallback={<Loader />}>
		<DropDown />
	</Suspense>
)
const Campaign = React.lazy(() => import('../page/procurement/createCampaigns'))
const CampaignPage = () => (
	<Suspense fallback={<Loader />}>
		<Campaign />
	</Suspense>
)
const Leads = React.lazy(() => import('../page/procurement/leads'))
const LeadsPage = () => (
	<Suspense fallback={<Loader />}>
		<Leads />
	</Suspense>
)
const RejectedPoTable = React.lazy(
	() => import('../page/procurement/incompleteDelivery'),
)
const RejectedPoTablePage = () => (
	<Suspense fallback={<Loader />}>
		<RejectedPoTable />
	</Suspense>
)

const RejectedPoDetailScreen = React.lazy(
	() => import('../page/procurement/rejectedPoMapping/RejectedPoMappingScreen'),
)

const SecondaryOrderUnLoadingDetailsScreen = React.lazy(
	() =>
		import(
			'../page/procurement/sencondaryUnloading/secondaryOrderUnloadingDetails'
		),
)

const SecondaryOrderUnLoadingDetails = () => (
	<Suspense fallback={<Loader />}>
		<SecondaryOrderUnLoadingDetailsScreen />
	</Suspense>
)

const RejectedPoDetail = () => (
	<Suspense fallback={<Loader />}>
		<RejectedPoDetailScreen />
	</Suspense>
)
/* inventory */
const Warehouse = React.lazy(() => import('../page/inventory/warehouse'))
const WarehousePage = () => (
	<Suspense fallback={<Loader />}>
		<Warehouse />
	</Suspense>
)
/* Logistic */
const LogisticDelivery = React.lazy(
	() => import('../page/logistic/pages/delivery/DeliveryIndex'),
)
const LogisticDeliveryPage = () => (
	<Suspense fallback={<Loader />}>
		<LogisticDelivery />
	</Suspense>
)
/* Controls And Config */
const Commodity = React.lazy(() => import('../page/controls&config/commodity'))
const CommodityPage = () => (
	<Suspense fallback={<Loader />}>
		<Commodity />
	</Suspense>
)

/* Controls And Config */
const Pincode = React.lazy(
	() => import('../page/controls&config/pincode/Pincode'),
)
const PincodePage = () => (
	<Suspense fallback={<Loader />}>
		<Pincode />
	</Suspense>
)

/* Supplier */
const Supplierlist = React.lazy(
	() => import('../page/valueAddedServices/supplierlist'),
)
const SupplierlistPage = () => (
	<Suspense fallback={<Loader />}>
		<Supplierlist />
	</Suspense>
)
const VasCustomersList = React.lazy(
	() => import('../page/valueAddedServices/vasCustomers'),
)
const VasCustomersPage = () => (
	<Suspense fallback={<Loader />}>
		<VasCustomersList />
	</Suspense>
)
const ProtectedRoutes = React.memo(() => {
	const theme = useTheme()
	const navigate = useNavigate();
	const userData = localStorage.getItem('user-data');

	React.useEffect(() => {
		if (!userData) {
			toast("Session expired, please login again!", {type: 'error'});
			setTimeout(() => {
				navigate('/login')
			}, 500)
		}
	}, [userData]);

	if (!userData) {
		return <></>;
	}

	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				width: '100%',
				marginLeft: `calc(${theme.spacing(8)} + 1px)`,
				marginTop: '60px',
				height: `calc(100% - 60px)`,
			}}
		>
			<NavSidebar />

			<Routes>
				<Route path='/users'>
					<Route
						path='access_creation'
						element={<PrivateRoute component={AccessCreationPage} />}
					></Route>
					<Route
						path='visits'
						element={<PrivateRoute component={EmployeeVisits} />}
					></Route>
					<Route
						path='visits/maps'
						element={<PrivateRoute component={MapViewWithDetails} />}
					></Route>
				</Route>
				<Route
					path='/'
					element={<Navigate to='/protected/dashboard/company_metrics' />} // for home redirect to the company metrics page
				/>
				<Route path='/sales'>
					{/* <Route
						path='buyer'
						element={<PrivateRoute component={BuyerPage} />}
					></Route> */}
					{RenderV3BuyerRoute()}
					{RenderSalesOrderDetailsRoute()}
					<Route
						path='salesOrder'
						element={<PrivateRoute component={SalesOrderPage} />}
					></Route>

					<Route
						path='repayments'
						element={<PrivateRoute component={RePaymentsPage} />}
					></Route>
				</Route>
				<Route path='/procurement'>
					<Route
						path='procurement'
						element={<PrivateRoute component={ProcurementOrderPage} />}
					></Route>
					{/* <Route
						path='buyerPrices'
						element={<PrivateRoute component={BuyerPricesPage} />}
					></Route> */}
					<Route
						path='retailer'
						element={<PrivateRoute component={RetailerPage} />}
					></Route>
					{RenderRetailerDetailsRoute()}
					{RenderPurchaseOrdersDetailsRoute()}
					{RenderSupplyDetailsRoute()}
					<Route
						path='supply'
						element={<PrivateRoute component={SupplyVisibilityPage} />}
					></Route>
					<Route
						path='salesOrderTarget'
						element={<PrivateRoute component={SalesOrderTargetPage} />}
					></Route>
					<Route
						path='dropdown'
						element={<PrivateRoute component={DropDownPage} />}
					></Route>
					<Route
						path='leads'
						element={<PrivateRoute component={LeadsPage} />}
					></Route>
					<Route
						path='campaign'
						element={<PrivateRoute component={CampaignPage} />}
					></Route>
				</Route>
				<Route path='/trade'>
					<Route
						path='buyerPrices'
						element={<PrivateRoute component={BuyerPricesPage} />}
					></Route>
					<Route
						path='supplierSauda'
						element={<PrivateRoute component={SupplierSaudaPage} />}
					></Route>
				</Route>
				<Route path='/payments'>
					{/* <Route
						path='payment'
						element={<PrivateRoute component={PaymentPage} />}
					></Route> */}
					{renderPaymentV2Route()}
					<Route
						path='mandiTax'
						element={<PrivateRoute component={MandiTaxPage} />}
					></Route>
					<Route
						path='invoicing'
						element={<PrivateRoute component={InvoiceListPage} />}
					></Route>
				</Route>
				{/* {
						new paths added accourding to the new routes in dashboard
					} */}
				<Route path='/dashboard'>
					<Route
						path='company_metrics'
						element={<PrivateRoute component={CompanyMetricsDashboardPage} />}
					></Route>
					<Route
						path='buyer_overview'
						element={<PrivateRoute component={BuyerOverViewPage} />}
					></Route>
					<Route
						path='seller_metrics'
						element={<PrivateRoute component={SellerMetricsDashboardPage} />}
					></Route>
					<Route
						path='operational_funnels'
						element={<PrivateRoute component={OperationFunnelsPage} />}
					></Route>
					<Route
						path='procurement'
						element={<PrivateRoute component={ProcurementDashboardPage} />}
					></Route>
					<Route
						path='finance'
						element={<PrivateRoute component={FinanceDashboardPage} />}
					></Route>
					<Route
						path='product_farmart'
						element={<PrivateRoute component={ProductFarmartAppPage} />}
					></Route>
					<Route
						path='product_saudaBook'
						element={<PrivateRoute component={SaudaBookAppPage} />}
					></Route>
				</Route>
				<Route path='/inventory'>
					<Route
						path='warehouse'
						element={<PrivateRoute component={WarehousePage} />}
					></Route>
					<Route
						path={`rejectedPo`}
						element={<PrivateRoute component={RejectedPoTablePage} />}
					>
						<Route path='incompleDelivery' element={<RejectedPoTableScreen />} />
						<Route path='secondaryUnloading' element={<SecondaryUnloadingMain />} />
						<Route
							path=''
							element={<Navigate to='incompleDelivery' replace={true} />}
						/>
					</Route>
					<Route
						path='rejectedpodetails/:rejectedPoID'
						element={<PrivateRoute component={RejectedPoDetail} />}
					></Route>
					<Route
						path='pending-secondary-uploading/:secondaryOrderUnLoadingId'
						element={<PrivateRoute component={SecondaryOrderUnLoadingDetails} />}
					></Route>
				</Route>
				<Route path='/logistic'>
					<Route
						path='delivery'
						element={<PrivateRoute component={LogisticDeliveryPage} />}
					></Route>
				</Route>
				<Route path='/control&config'>
					<Route
						path='commodity'
						element={<PrivateRoute component={CommodityPage} />}
					></Route>
					<Route
						path='pincode'
						element={<PrivateRoute component={PincodePage} />}
					></Route>
				</Route>
				<Route path='/vas'>
					<Route
						path='supplierList'
						element={<PrivateRoute component={SupplierlistPage} />}
					></Route>
					<Route
						path='vasCustomers'
						element={<PrivateRoute component={VasCustomersPage} />}
					></Route>
				</Route>
			</Routes>
		</div>
	)
})
const designations: any = {
	11: 'PA',
	12: 'RPM',
	13: 'SHP',
	14: 'PVP',
	15: 'SVP',
	16: 'SHS',
	17: 'RSM',
	18: 'SA',
	19: 'FC',
	20: 'AP',
	21: 'MISP',
	22: 'CPO',
	23: 'CFO',
	24: 'MISS',
	25: 'ES',
	26: 'AMS',
	27: 'AR',
	37: 'HR',
	35: 'LM',
	36: 'LA',
	39: 'SCA',
	70: 'VKM', // vas key manager
}

const AppRoutes = () => {
  const {isLoggedIn, profile} = useAppSelector(state => {
    //todo need 11, 12,
    // 18 to be replaced to constant currently to restrict temporary PA/SA to login added this condition
    /* these conditions added after discussing Sukhpreet and Mahesh */
	// giving view access to RPM as per JIRA ticket number 1178
    if ([11, 18].includes(state?.auth?.userProfile?.user_role_id)) {
      localStorage.removeItem('user-data')
    }
    return {
      isLoggedIn: [11, 18].includes(state?.auth?.userProfile?.user_role_id) ? false : state.auth.authenticated,
      profile: [11, 18].includes(state?.auth?.userProfile?.user_role_id) ? null : state.auth.userProfile,
    }
  })
	useEffect(()=>{
		if(isLoggedIn){
			actionDispatch(userActions.getUserTicketToken())
			.then((data)=>{
				localStorage.setItem('user-ticket',JSON.stringify(data.payload.data))
			})
			.catch((err)=>{
				toast(err.message,{type:'error'})
			})
		}
	},[isLoggedIn])
	const {screenId,recordId,platformId} = useAppSelector((state) => state.screenRecord)
	setRecentBrowserHistory();
	const _popup = createRef<any>()
	const actionDispatch = useAppDispatch()
	const navigate = useNavigate()

	function onLogoutClick() {
		_popup.current?.close()
		actionDispatch(authActions.logout())
		sessionStorage.clear()
		localStorage.clear();
		navigate('/login')
	}

	function printUserSpecificName(userName: any) {
		const nameArray = userName.split(' ')
		let newName = nameArray[0]
		if (newName.length + nameArray[1] <= 12) {
			newName = newName + ' ' + nameArray[1]
		}
		return newName
	}
	function onClickHelp() {
		let newName = profile?.name.split(' ').join('_')
		const url = `https://fmt-issue-tracker.web.app/home/raise/farmartos?username=${newName}&mobile=${profile?.mobile_number}`
		window.open(url)
	}
	function onAddTicket() {
		const userTicketString = localStorage.getItem('user-ticket')
		let myToken
		if (userTicketString) {
			myToken = JSON.parse(userTicketString)
			const url = `${ENV.ticketingUrl}?params=${JSON.stringify({
				id:encodeURIComponent(platformId),
				key:encodeURIComponent(myToken.encrypted_token),
				screenId: screenId,
				recordId: recordId,
				addTicket: true,
				productId:encodeURIComponent(myToken.product_id)
			})}`
			window.open(url)
			// useAppDispatch(setScreenAndRecord({screenId: null, recordId: null}))
		} else {
			toast('Ticket Token Missing', {type: 'error'})
		}
	}
	function onViewTicket(){
		const userTicketString = localStorage.getItem('user-ticket')
		let myToken
		if (userTicketString) {
			myToken = JSON.parse(userTicketString)
			const url = `${ENV.ticketingUrl}?params=${JSON.stringify({
				id:encodeURIComponent(platformId),
				key:encodeURIComponent(myToken.encrypted_token),
				productId:encodeURIComponent(myToken.product_id)
			})}`
			window.open(url)
			// useAppDispatch(setScreenAndRecord({screenId: null, recordId: null}))
		} else {
			toast('Ticket Token Missing', {type: 'error'})
		}
	}
	let userAction: any = []
	// Removed with respect to Jira ticket https://farmart.atlassian.net/browse/FMVE-1134
	// removed after the last commit 20a6fc4
	if (isLoggedIn) {
		userAction = [
			{
				title: 'Help',
				iconName: 'help',
				label: 'Help',
				menuData: [
				// Removed with respect to Jira ticket https://farmart.atlassian.net/browse/FMVE-1134
				// removed after the last commit 20a6fc4
					{
						title: 'Raise Ticket',
						onClick: onAddTicket,
						iconName: 'FlagOutlinedIcon'
					},
					{
						title: 'View Ticket',
						onClick: onViewTicket,
						iconName: 'RemoveRedEyeRounded'
					},
				],
			},
			// Removed with respect to Jira ticket https://farmart.atlassian.net/browse/FMVE-1134
			// removed after the last commit 20a6fc4
			{
				title: 'Profile',
				iconName: 'user',
				menuData: [
					{
						title: 'Log out',
						onClick: () => {
							_popup.current?.open()
						},
						iconName: 'logout',
					},
				],
				profile: {
					name: printUserSpecificName(profile?.name),
					mobileNumber: profile?.mobile_number,
					designation: designations[profile?.user_role_id] ?? 'N/A',
				},
			},
		]
	}

	return (
		<div
			style={{
				display: 'flex',
				flexGrow: 1,
				width: '100%',
				height: '100%',
				overflowX: 'hidden',
			}}
		>
			<Header actions={userAction} />
			<Routes>
				<Route path='*' element={<Navigate to='/protected' />} />
				<Route path='/login' element={<LoginPage />} />
				<Route path='/' element={<Navigate to='/login' />} />
				<Route path='/home' element={<Navigate to='/login' />} />
				<Route path='/index' element={<Navigate to='/login' />} />
				<Route path='/protected/*' element={<ProtectedRoutes />} />
			</Routes>
			<div>
				<ListItem sx={{display: 'block', paddingBottom: '15px'}} disablePadding>
					<Popup ref={_popup} modal>
						{(close: () => void) => (
							<Container padding='l'>
								<Title style={{marginBottom: '32px'}}>Are you Sure ?</Title>
								<Row>
									<Button text='Logout Now' onClick={onLogoutClick} />
									<Button
										style={{marginLeft: '18px', backgroundColor: 'gray'}}
										text='Cancel'
										onClick={close}
									/>
								</Row>
							</Container>
						)}
					</Popup>
				</ListItem>
			</div>
		</div>
	)
}

export default AppRoutes
