import {createAsyncThunk} from '@reduxjs/toolkit'
import ServiceApi from './../../../service/service.api'

export const getAllStates = createAsyncThunk(
	'global/states',
	async (_, thunkAPI) => {
		try {
			const {status, data} = await ServiceApi.getInstance().GET(
				'/v1/location/states',
			)
			if (status !== 200) throw new Error(data.message ?? 'Something went wrong')
			return data
		} catch (err: any) {
			return thunkAPI.rejectWithValue(err.message)
		}
	},
)
