import React, {Dispatch, SetStateAction, useEffect, useRef, useState} from 'react'
// Reduxx=================
import {procurementAction} from './../../../../redux/slice/slice.procurement'
// Types==================
import {IDataFetch, ISecondaryOrderList} from './../types/tabletypes'
import {useAppDispatch, useAppSelector} from '../../../../redux'
import {Api} from '@mui/icons-material'
import {generateTableRows, getTotalPages, uuid} from './../utils'
import {toast} from 'react-toastify'
import {GridRowParams} from '@mui/x-data-grid/models/params'
import {useNavigate} from 'react-router-dom'
import { setSecondarySalesOrderCurrentIndex, setSecondarySalesOrderCurrentPage, setSecondarySalesOrderTotalPages } from '../../../../redux/slice/slice.secondaryOrderUnloading'

export interface UseSecondarySoTableProps {
	limit: number;
	page: number;
	searchBy: string;
	setPageNumber: Dispatch<SetStateAction<number>>
}

export interface UseSecondarySoTableInterface {
	isDataLoading: boolean;
	totalRecords: number;
	secondaryOrdersList: number;	
}

export default function useSecondarySoTable({ limit, page, searchBy, setPageNumber}: UseSecondarySoTableProps) {
	const dispatch = useAppDispatch()
	const navigate = useNavigate()
	const reduxCurrentPage = useAppSelector(state => state.secondaryOrderUnloading.currentPage);

	const [isDataLoading, setIsDataLoading] = useState<IDataFetch>({
		isOrdersFechinhg: false,
	})
	const [totalRecords, setTotalRecords] = useState<number>(0);
	const [secondaryOrdersList, setSecondaryOrderList] = useState<
		ISecondaryOrderList[]
	>([])

	const recentRequestId = useRef<string>(uuid());


	const secondaryOrderTableIds = useAppSelector(state => state.secondaryOrderUnloading.ids);

	useEffect(() => {
		if (reduxCurrentPage > 0 && page != reduxCurrentPage) {
			setPageNumber(reduxCurrentPage)
		}
	}, [reduxCurrentPage, page])

	async function getAllPendingSecondaryOrders(uuid: string) {
		setSecondaryOrderList([]);
		try {
			setIsDataLoading({isOrdersFechinhg: true})
			const API_RES = await dispatch(
				procurementAction.fetchAllSecondaryOrders({limit, page_number: page + 1, searchBy}),
			)

			if (uuid != recentRequestId.current) {
				setIsDataLoading({isOrdersFechinhg: false})
				return
			}
			if (API_RES.payload.status) {
				const list = API_RES.payload.data.orders ?? []
				const total_records = API_RES.payload.data.total_records ?? 1000;
				const arrFortable = generateTableRows(list)
				setTotalRecords(total_records)
				setSecondaryOrderList(arrFortable)
				dispatch(
					setSecondarySalesOrderTotalPages(getTotalPages(limit, total_records)),
				)
				setIsDataLoading({isOrdersFechinhg: false})
			} else {
				toast(API_RES.payload, {type: 'error'})
				setSecondaryOrderList([])
				setIsDataLoading({isOrdersFechinhg: false})
			}
		} catch (err: unknown) {
			console.log(err)
			setIsDataLoading({isOrdersFechinhg: false})
		}
	}

	const onTableRowSelection = (params: GridRowParams) => {
		if (params.row.po_pending_inhand_id) {
			navigate(
				`/protected/inventory/pending-secondary-uploading/${params.row.po_pending_inhand_id}`,
			)
			const idx = secondaryOrderTableIds.findIndex(obj => +obj === +params.row.po_pending_inhand_id);
			if (idx >= 0) {
				dispatch(setSecondarySalesOrderCurrentPage(page))
				dispatch(setSecondarySalesOrderCurrentIndex(idx))
			}
		} else {
			toast('Po Pending In Hand Id is Missing', {type: 'error'})
		}
	}

	React.useEffect(() => {
		let id = uuid();
		recentRequestId.current = id;
		getAllPendingSecondaryOrders(id);
	}, [limit, page, searchBy])

	return {isDataLoading, secondaryOrdersList, onTableRowSelection, totalRecords}
}