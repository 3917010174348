import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {toast} from 'react-toastify'
import {assignLeadsStatus} from '../../utils'
import {
	getRetailerDetails,
	getRetailerDocuments,
} from '../thunk/procurementthunk/retailer/thunk.retailerDetails'
import {
	addGstDetailsCropCreation,
	addQcCheck,
	addQcSample,
	createCrop,
	createCrop2,
	createDocAtDispatch,
	createVariety,
	deleteDocAtDispatch,
	deleteQcCheck,
	deleteQcSample,
	deleteVariety,
	detachSotoPo,
	downloadRetailers,
	editDocumentAtDis,
	editQcCheck,
	editQcSample,
	editVariety,
	fecthGstDetailsCropCreation,
	fetchAllSecondaryOrders,
	fetchDataScienceQcChecks,
	fetchRejectedPoDetails,
	fetchRejectedPoDispatchD,
	fetchRejectedPoTransportD,
	fetchSalesOrderForRejectedPo,
	fetchUnloadingDetails,
	getAllDocumentStatus,
	getPoFullDetails,
	getRetailers,
	getSalesOrderMapping,
	getSamples,
	loadAllCrops,
	loadCrops,
	mapRejectedSalesOrder,
	mapSotoPo,
	submitRetailerFinalPayment,
	submitTransportFinalPayment,
	updateDispatchDetails,
	updateDocumentStatus,
	updatePoPaymentInfo,
	updateRetailerDocument,
	updateSample,
	updateTransporterDetails,
	uploadImage,
	uploadRetailers,
} from '../thunk/thunk.procurement'
import {getModifiedDocumentArr} from './../../page/procurement/retailer/utils'

import {
	IProcurementCrop,
	IProcurementState,
	ISample,
	ISelectedRetailer,
} from '../types/type.procurement'

const initialState: IProcurementState = {
	fetchingRetailers: false,
	hasError: false,
	fetchingCrops: false,
	fetchingCropsNew: false,
	fetchingSamples: false,
	updatingDocStatus: false,
	creatingCrop: false,
	creatingCropNew: false,
	creatingVariety: false,
	isAddingGstCropVarityCreation: false,
	creatingDoc: false,
	updatingSample: false,
	sampleFilterStatus: -1,
	sampleFilterState: 'ALL',
	errorMessage: '',
	retailers: [],
	crops: [],
	allcrops: [],
	samples: [],
	total_sample_records: 0,
	states: [],
	retailerState: [],
	retailerFilterState: 'All',
	retailerFilterStatus: -1,
	selectedRetailer: null,
	retailerEditData: null,
	selectedRetailerDoc: [],
	cropToEdit: null,
	selectedSample: null,
	selectedCrop: null,
	selectedVariety: null,
	deleteData: null,
	editData: null,
	soOrder: [],
	poStatus: null,
	poDetails: null,
	poUpdating: false,
	isPodetailsFetching: false,
	isSoOrderFetching: false,
	isPart2PaymentSubmitting: false,
	isPart2TransporterSubmitting: false,
	isRetailerDetailsLoading: false,
	isDocumentUpdating: false,
	totalRetailers: 0,
	rejectedPoId: null,
	isDownloadingRetailer: false,
	gstDetails: {
		isFetching: false,
		isError: false,
		response: null,
	},
	isPoMappingtoSo: false,
	isQcChecksByDataSfetching: false,
	qcChecksByDataS: {},
}

export const sliceProcurement = createSlice({
	name: 'procurement',
	initialState,
	reducers: {
		setRetailerEditData: (
			state,
			action: {type: string; payload: ISelectedRetailer | null},
		) => {
			if (action.payload) {
				const {
					retailer_id,
					farmart_id,
					name,
					mobile_number,
					business_name,
					type,
					category,
					email,
					referred_by,
					full_address,
					pincode,
					state_id,
					district_id,
					lat,
					long,
					status_of_retailer,
				} = action.payload
				const new_data = {
					retailerId: retailer_id,
					retailerFarId: farmart_id,
					retailerName: name,
					mobileNumber: mobile_number.toString(),
					businessName: business_name,
					businessType: type,
					businessCategory: category,
					retailerEmail: email,
					referredBy: referred_by,
					retailerAddress: full_address,
					retailerPincode: pincode,
					retailerState: state_id,
					retailerDistrict: district_id,
					retailerLatitude: lat,
					retailerLongitude: long,
					status_of_retailer: status_of_retailer,
				}
				state.retailerEditData = new_data
			} else {
				state.retailerEditData = null
			}
		},
		setSelectedRetailer: (state, action) => {
			state.selectedRetailer = action.payload
		},
		setSelectedRetailerDocument: (state, action) => {
			state.selectedRetailer = action.payload
		},
		setRejectedPoId: (state, action) => {
			state.rejectedPoId = action.payload
		},
		selectSample: (state, payload: {type: string; payload: ISample | null}) => {
			state.selectedSample = payload.payload
		},
		setSamples: (state, action: any) => {
			state.samples = action.payload
		},
		selectCropToEdit: (
			state,
			payload: {type: string; payload: IProcurementCrop | null},
		) => {
			state.cropToEdit = payload.payload
		},
		setSampleFilterStatus: (state, payload: {type: string; payload: number}) => {
			state.sampleFilterStatus = payload.payload
		},
		setSelectedCrop: (state, payload) => {
			state.selectedCrop = payload.payload
		},
		setSelectedVariety: (state, payload) => {
			state.selectedVariety = payload.payload
		},
		setdeleteData: (state, payload) => {
			state.deleteData = payload.payload
		},
		seteditData: (state, payload) => {
			state.editData = payload.payload
		},
		setSampleFilterState: (state, payload: {type: string; payload: string}) => {
			state.sampleFilterState = payload.payload
			state.selectedSample = null
		},
		setRetailerFilterState: (state, payload: {type: string; payload: string}) => {
			state.retailerFilterState = payload.payload
			state.selectedRetailer = null
		},
		setRetailerFilterStatus: (state, payload) => {
			state.retailerFilterStatus = payload.payload
			state.selectedRetailer = null
		},
		setPoDetails: (state, payload) => {
			state.poDetails = payload.payload
		},
		setSalesOrderList: (state, action) => {
			state.soOrder = action.payload
		},
	},
	extraReducers: builder => {
		builder.addCase(submitRetailerFinalPayment.rejected, (state, action) => {
			state.isPart2PaymentSubmitting = false
			toast(action.payload, {type: 'error'})
		})
		builder.addCase(submitRetailerFinalPayment.pending, state => {
			state.isPart2PaymentSubmitting = true
		})
		builder.addCase(submitRetailerFinalPayment.fulfilled, (state, action) => {
			state.isPart2PaymentSubmitting = false
			toast('Retailer Final Payment Successful', {type: 'success'})
			let po_details = state.poDetails
			let updated_po_details = {
				...po_details,
				isRetailerPart2ApprovedByStateHead: true,
			}
			state.poDetails = updated_po_details
		})
		builder.addCase(submitTransportFinalPayment.rejected, (state, action) => {
			toast(action.payload, {type: 'error'})
			state.isPart2TransporterSubmitting = false
		})
		builder.addCase(submitTransportFinalPayment.pending, (state, action) => {
			state.isPart2TransporterSubmitting = true
		})
		builder.addCase(submitTransportFinalPayment.fulfilled, (state, action) => {
			state.isPart2TransporterSubmitting = false
			toast('Transporter Final Payment Successful', {type: 'success'})
			let po_details = state.poDetails
			let updated_po_details = {
				...po_details,
				isTransportPart2ApprovedByStateHead: true,
			}
			state.poDetails = updated_po_details
		})
		builder.addCase(getRetailers.rejected, (state, action) => {
			state.fetchingRetailers = false
			state.hasError = true
			state.errorMessage = action.payload as string
		})
		builder.addCase(getRetailers.pending, (state, action) => {
			state.fetchingRetailers = true
		})
		builder.addCase(getRetailers.fulfilled, (state, action) => {
			state.retailers = action.payload.retailers
			state.totalRetailers = action.payload.total_records
			state.fetchingRetailers = false
			state.hasError = false
			state.errorMessage = ''
		})

		builder.addCase(downloadRetailers.fulfilled, (state, action) => {
			state.isDownloadingRetailer = false
		})

		builder.addCase(downloadRetailers.rejected, (state, action) => {
			state.isDownloadingRetailer = false
			const message = action.payload as string
			toast(message, {type: 'error'})
		})
		builder.addCase(downloadRetailers.pending, (state, action) => {
			state.isDownloadingRetailer = true
		})

		builder.addCase(uploadRetailers.pending, (state, action) => {
			state.fetchingRetailers = true
		})

		builder.addCase(uploadRetailers.fulfilled, (state, action) => {
			state.fetchingRetailers = false
			if (action.payload.uploadStatus === assignLeadsStatus.Partial) {
				toast('Retailers Partially Uploaded', {type: 'warning'})
			} else if (action.payload.uploadStatus === assignLeadsStatus.Success) {
				toast('Retailers Successfully Uploaded', {type: 'success'})
			} else {
				toast('No Retailer Uploaded', {type: 'error'})
			}
		})

		builder.addCase(uploadRetailers.rejected, (state, action) => {
			const message = action.payload as string
			toast(message, {type: 'error'})
		})

		builder.addCase(loadAllCrops.pending, state => {
			state.fetchingCropsNew = true
		})

		builder.addCase(loadAllCrops.rejected, state => {
			state.fetchingCropsNew = false
		})

		builder.addCase(loadAllCrops.fulfilled, (state, action) => {
			state.fetchingCropsNew = false
			state.allcrops = action.payload
		})

		builder.addCase(loadCrops.pending, state => {
			state.fetchingCrops = true
		})
		builder.addCase(loadCrops.rejected, (state, action) => {
			state.fetchingCrops = false
			state.hasError = true
			state.errorMessage = action.payload as string
		})
		builder.addCase(loadCrops.fulfilled, (state, action) => {
			state.fetchingCrops = false
			state.crops = action.payload
			state.hasError = false
			state.errorMessage = ''
		})

		builder.addCase(createCrop2.pending, state => {
			state.creatingCropNew = true
		})

		builder.addCase(createCrop2.rejected, (state, action) => {
			state.creatingCropNew = false
			const message = action.payload as string
			toast(message, {type: 'error'})
		})

		builder.addCase(createCrop2.fulfilled, (state, action) => {
			state.creatingCropNew = false
			const newCropData = action.payload[0]
			state.allcrops.push(newCropData)
			toast('Crop created successfully', {type: 'success'})
		})

		builder.addCase(createVariety.pending, state => {
			state.creatingVariety = true
		})

		builder.addCase(createVariety.rejected, (state, action) => {
			state.creatingVariety = false
		})
		builder.addCase(addGstDetailsCropCreation.pending, state => {
			state.isAddingGstCropVarityCreation = true
		})
		builder.addCase(addGstDetailsCropCreation.rejected, state => {
			state.isAddingGstCropVarityCreation = false
		})
		builder.addCase(addGstDetailsCropCreation.fulfilled, state => {
			state.isAddingGstCropVarityCreation = false
			toast('GST Details added successfully', {
				type: 'success',
			})
		})
		builder.addCase(
			fecthGstDetailsCropCreation.pending,
			(state, action: PayloadAction<{crop_variety_id: number} | undefined>) => {
				state.gstDetails = {
					isFetching: true,
					isError: false,
					response: null,
				}
			},
		)
		builder.addCase(fecthGstDetailsCropCreation.rejected, state => {
			state.gstDetails = {
				isFetching: false,
				isError: true,
				response: null,
			}
		})
		builder.addCase(fecthGstDetailsCropCreation.fulfilled, (state, action) => {
			state.gstDetails = {
				isFetching: false,
				isError: false,
				response: action?.payload.data,
			}
		})
		builder.addCase(createVariety.fulfilled, (state, action) => {
			state.creatingVariety = false
			state.selectedCrop?.varieties.push(action.payload[0])
			toast('Variety Created Successfully', {type: 'success'})
		})
		builder.addCase(editVariety.rejected, () => {
			toast('Something Bad Happened', {type: 'error'})
		})
		builder.addCase(editVariety.fulfilled, state => {
			const varietyId = state.selectedVariety?._id
			const varietyIndex = state.selectedCrop?.varieties.findIndex(item => {
				return item._id == varietyId
			})
			// @ts-ignore
			state.selectedCrop.varieties[varietyIndex].name =
				state.editData?.varietyData.name
			// @ts-ignore
			state.selectedCrop.varieties[varietyIndex].qualityCheckGuideURL =
				state.editData?.varietyData.qualityCheckGuideURL
			state.editData = null
			toast('Variety Updated Successfully', {
				type: 'success',
			})
		})
		builder.addCase(deleteVariety.rejected, () => {
			toast('Something Bad Happened', {type: 'error'})
		})

		builder.addCase(deleteVariety.fulfilled, state => {
			const varietyToDelete = state.selectedVariety?._id
			const allVarieties = state.selectedCrop?.varieties
			const newVarieties = allVarieties?.filter(item => {
				return item._id !== varietyToDelete
			})
			// @ts-ignore
			state.selectedCrop.varieties = newVarieties
			state.selectedVariety = null
			toast('Variety Deleted Successfully', {type: 'success'})
		})

		builder.addCase(addQcCheck.rejected, (state, action) => {
			toast('Something Went wrong', {type: 'error'})
		})

		builder.addCase(addQcCheck.fulfilled, (state, action) => {
			state.selectedVariety = action.payload
			toast('Qc Checks Created Successfully', {type: 'success'})
		})

		builder.addCase(editQcCheck.rejected, () => {
			toast('Something Went Wrong', {type: 'error'})
		})

		builder.addCase(editQcCheck.fulfilled, state => {
			const qcCheckId = state.editData?._id
			const indexOfQcCheck = state.selectedVariety?.qcCheckListParameter.findIndex(
				item => {
					return item._id == qcCheckId
				},
			)
			// @ts-ignore
			state.selectedVariety.qcCheckListParameter[indexOfQcCheck] = state.editData
			state.editData = null
			toast('Qc Check Updated', {type: 'success'})
		})

		builder.addCase(deleteQcCheck.rejected, () => {
			toast('Something went wrong', {type: 'error'})
		})
		builder.addCase(deleteQcCheck.fulfilled, (state, action) => {
			const deleteQcId = state.deleteData?._id
			const allQcChecks = state.selectedVariety?.qcCheckListParameter.filter(
				item => {
					return item._id !== deleteQcId
				},
			)
			// @ts-ignore
			state.selectedVariety.qcCheckListParameter = allQcChecks
			toast('Qc Check Sample Deleted Successfully', {type: 'success'})
		})

		builder.addCase(addQcSample.rejected, state => {
			toast('Something Went Wrong', {type: 'error'})
		})

		builder.addCase(addQcSample.fulfilled, (state, action) => {
			state.selectedVariety = action.payload
			toast('Qc Sample Created Successfully', {type: 'success'})
		})

		builder.addCase(editQcSample.rejected, () => {
			toast('Something Went Wrong', {type: 'error'})
		})
		builder.addCase(editQcSample.fulfilled, state => {
			const qcSampleId = state.editData?._id
			const indexOfSample = state.selectedVariety?.samplingCondition.findIndex(
				item => {
					return item._id == qcSampleId
				},
			)
			// @ts-ignore
			state.selectedVariety.samplingCondition[indexOfSample] = state.editData
			state.editData = null
			toast('Qc Sample Updated', {type: 'success'})
		})
		builder.addCase(deleteQcSample.rejected, () => {
			toast('Something Went Wrong', {type: 'error'})
		})

		builder.addCase(deleteQcSample.fulfilled, state => {
			const deleteQcSampleId = state.deleteData?._id
			const allQcSample = state.selectedVariety?.samplingCondition.filter(item => {
				return item._id !== deleteQcSampleId
			})
			// @ts-ignore
			state.selectedVariety.samplingCondition = allQcSample
			toast('Qc Sample Deleted Successfully', {type: 'success'})
		})

		builder.addCase(createDocAtDispatch.pending, state => {
			state.creatingDoc = false
		})
		builder.addCase(createDocAtDispatch.rejected, (state, action) => {
			state.creatingDoc = false
			const message = action.payload as string
			toast(message, {type: 'error'})
		})
		builder.addCase(createDocAtDispatch.fulfilled, (state, action) => {
			state.creatingDoc = false
			state.selectedVariety = action.payload
			toast('Document Added Successfully', {type: 'success'})
		})

		builder.addCase(editDocumentAtDis.rejected, () => {
			toast('Something went wrong', {type: 'error'})
		})
		builder.addCase(editDocumentAtDis.fulfilled, state => {
			const editDocumentId = state.editData?._id
			const indexOfDocument = state.selectedVariety?.documentAtDispatch.findIndex(
				item => {
					return item._id == editDocumentId
				},
			)
			// @ts-ignore
			state.selectedVariety.documentAtDispatch[indexOfDocument] = state.editData
			state.editData = null
			toast('Document Updated', {type: 'success'})
		})
		builder.addCase(deleteDocAtDispatch.rejected, () => {
			toast('Something went wrong', {type: 'error'})
		})

		builder.addCase(deleteDocAtDispatch.fulfilled, state => {
			const documentId = state.deleteData?._id
			const allDocuments = state.selectedVariety?.documentAtDispatch.filter(
				item => {
					return item._id !== documentId
				},
			)

			// @ts-ignore
			state.selectedVariety.documentAtDispatch = allDocuments
			toast('Document Deleted Successfully', {type: 'success'})
		})

		builder.addCase(createCrop.pending, state => {
			state.creatingCrop = true
		})
		builder.addCase(createCrop.rejected, (state, action) => {
			state.creatingCrop = false
			const msg = action.payload as string
			toast(msg, {type: 'error'})
		})
		builder.addCase(createCrop.fulfilled, (state, action) => {
			state.creatingCrop = false
			state.crops.push(action.payload)
			toast('Crop created successfully', {type: 'success'})
		})

		builder.addCase(getSamples.pending, state => {
			state.crops = []
			state.fetchingSamples = true
		})
		builder.addCase(getSamples.rejected, (state, action) => {
			state.fetchingSamples = false
			const msg = action.payload as string
			toast(msg, {type: 'error'})
		})
		builder.addCase(getSamples.fulfilled, (state, action) => {
			state.fetchingSamples = false
			state.samples = action.payload.data

			state.total_sample_records = action.payload.total_record ?? 0
		})

		builder.addCase(updateSample.pending, state => {
			state.updatingSample = true
		})
		builder.addCase(updateSample.rejected, (state, action) => {
			state.updatingSample = false
			const msg = action.payload as string
			toast(msg, {type: 'error'})
		})
		builder.addCase(updateSample.fulfilled, (state, action) => {
			state.poStatus = action.payload.status
			/* const lotId = state.selectedSample?.lotId
			const newPodetailsIndex = state.samples.findIndex(item => {
				return item.lotId == lotId
			})
			state.samples[newPodetailsIndex] = action.payload
			state.selectedSample = action.payload*/
			state.updatingSample = false
			toast('Sample updated successfully.', {type: 'success'})
		})
		builder.addCase(getSalesOrderMapping.rejected, state => {
			state.soOrder = []
			state.isSoOrderFetching = false
		})
		builder.addCase(getSalesOrderMapping.pending, state => {
			state.soOrder = []
			state.isSoOrderFetching = true
		})
		builder.addCase(getSalesOrderMapping.fulfilled, (state, action) => {
			state.isSoOrderFetching = false
			state.soOrder = action.payload
		})
		builder.addCase(getPoFullDetails.rejected, state => {
			state.poDetails = null
			state.isPodetailsFetching = false
		})
		builder.addCase(getPoFullDetails.pending, state => {
			state.poDetails = null
			state.isPodetailsFetching = true
		})
		builder.addCase(getPoFullDetails.fulfilled, (state, action) => {
			let sample_id = state.selectedSample?._id
			let poDetails_id = action.payload.sampleId

			state.poDetails = action.payload
			state.isPodetailsFetching = false
		})
		builder.addCase(mapSotoPo.rejected, (state, action) => {
			state.isPoMappingtoSo = false
			toast(action.payload, {type: 'error'})
		})
		builder.addCase(mapSotoPo.pending, state => {
			state.poDetails = null
			state.isPoMappingtoSo = true
		})
		builder.addCase(mapSotoPo.fulfilled, (state, action) => {
			state.poDetails = action.payload
			state.isPoMappingtoSo = false
			toast('Po Successfully Mapped', {type: 'success'})
		})
		builder.addCase(detachSotoPo.rejected, (state, action) => {
			state.isPoMappingtoSo = false
			toast(action.payload, {type: 'error'})
		})
		builder.addCase(detachSotoPo.pending, state => {
			state.isPoMappingtoSo = true
		})
		builder.addCase(detachSotoPo.fulfilled, (state, action) => {
			state.isPoMappingtoSo = false
			toast('PO SO Detach successfully', {type: 'success'})
		})
		builder.addCase(updatePoPaymentInfo.rejected, (state, action) => {
			state.poUpdating = false
			toast(action.payload as string, {type: 'error'})
		})
		builder.addCase(updatePoPaymentInfo.pending, state => {
			state.poUpdating = true
		})
		builder.addCase(updatePoPaymentInfo.fulfilled, (state, action) => {
			const poDetails_copy = state.poDetails
			const payload = action.payload
			poDetails_copy.isPartOneApprovedByStateHead =
				payload.isPartOneApprovedByStateHead
			poDetails_copy.paymentInfoStatus.retailer.partOne.amount =
				payload.partOnePaymentRetailer
			poDetails_copy.paymentInfoStatus.retailer.partOne.percentage =
				payload.partOnePercRetailer
			poDetails_copy.paymentInfoStatus.transport.partOne.amount =
				payload.partOnePaymentTransporter
			state.poUpdating = false
			state.poDetails = poDetails_copy
			toast('Po Details Updated Successfully', {type: 'success'})
		})
		builder.addCase(getRetailerDetails.rejected, state => {
			state.isRetailerDetailsLoading = false
		})
		builder.addCase(getRetailerDetails.pending, state => {
			state.isRetailerDetailsLoading = true
		})
		builder.addCase(getRetailerDetails.fulfilled, (state, action) => {
			state.isRetailerDetailsLoading = false
			try {
				if (action.payload.status) {
					state.selectedRetailer = action.payload.data
				} else {
					state.selectedRetailer = null
					throw new Error(action.payload.message ?? 'Something went wrong')
				}
			} catch (err: unknown) {
				console.log(err)
			}
		})
		builder.addCase(getRetailerDocuments.rejected, (state, action) => {
			toast(action.payload, {type: 'error'})
		})
		builder.addCase(getRetailerDocuments.pending, state => {})
		builder.addCase(getRetailerDocuments.fulfilled, (state, action) => {
			try {
				if (action.payload.status) {
					const selectedDoc = action.payload.data?.retailer_docs ?? []
					const additionalDataArr = getModifiedDocumentArr(selectedDoc)
					state.selectedRetailerDoc = additionalDataArr
				} else {
					state.selectedRetailerDoc = []
				}
			} catch (err: unknown) {
				console.log(err)
			}
		})
		builder.addCase(fetchDataScienceQcChecks.rejected, (state, action) => {
			state.isQcChecksByDataSfetching = false
			// toast('DS:'+ action.payload,{type:'error'})
		})
		builder.addCase(fetchDataScienceQcChecks.pending, state => {
			state.isQcChecksByDataSfetching = true
			state.qcChecksByDataS = {}
		})

		builder.addCase(fetchDataScienceQcChecks.fulfilled, (state, action) => {
			state.isQcChecksByDataSfetching = false
			if(!action.payload.status){
				toast(`DS: ${action.payload.message} `,{type:'info'})
			}
			state.qcChecksByDataS = action.payload.data?.params?.data?.quality_params || {}
		})
		builder.addCase(updateRetailerDocument.rejected, state => {
			state.isDocumentUpdating = false
		})

		builder.addCase(updateRetailerDocument.pending, state => {
			state.isDocumentUpdating = true
		})
		builder.addCase(updateRetailerDocument.fulfilled, (state, action) => {
			state.isDocumentUpdating = false
			try {
				if (action.payload.data.status) {
					const doc_index = action.payload.index
					if (state.selectedRetailerDoc) {
						const newObj = {
							...state.selectedRetailerDoc[doc_index],
							...action.payload.data.data,
						}
						state.selectedRetailerDoc[doc_index] = newObj
					}
				} else {
					toast(action.payload?.data.message ?? 'Something went wrong', {
						type: 'error',
					})
				}
			} catch (err: unknown) {
				console.log(err)
			}
		})
	},
})

export const procurementAction = {
	getRetailers,
	downloadRetailers,
	uploadRetailers,
	loadCrops,
	loadAllCrops,
	createCrop,
	createCrop2,
	createVariety,
	editVariety,
	deleteVariety,
	addQcCheck,
	addQcSample,
	editQcCheck,
	deleteQcCheck,
	editQcSample,
	deleteQcSample,
	createDocAtDispatch,
	editDocumentAtDis,
	deleteDocAtDispatch,
	getSamples,
	updateSample,
	getSalesOrderMapping,
	getPoFullDetails,
	mapSotoPo,
	updatePoPaymentInfo,
	getAllDocumentStatus,
	updateDocumentStatus,
	uploadImage,
	submitRetailerFinalPayment,
	submitTransportFinalPayment,
	getRetailerDetails,
	getRetailerDocuments,
	updateRetailerDocument,
	updateTransporterDetails,
	fetchRejectedPoDetails,
	fetchSalesOrderForRejectedPo,
	fetchRejectedPoDispatchD,
	fetchRejectedPoTransportD,
	updateDispatchDetails,
	mapRejectedSalesOrder,
	fetchAllSecondaryOrders,
	fetchUnloadingDetails,
	addGstDetailsCropCreation,
	fecthGstDetailsCropCreation,
	detachSotoPo,
	fetchDataScienceQcChecks,
	...sliceProcurement.actions,
}

export default sliceProcurement.reducer