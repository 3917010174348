import {
	approveOrRejectSupplier,
	assignRmToSupplier,
	assignSupplierToUA,
	getAllState,
	getAllSupplier,
	getStateDistrict,
} from './supplierApis'

export const supplierApis = [
	assignSupplierToUA,
	getAllSupplier,
	assignRmToSupplier,
	getAllState,
	getStateDistrict,
	approveOrRejectSupplier,
]
