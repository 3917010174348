import {ApiCaller} from '../../../../component/apiRedux/apiCaller'
import { FarmartResponse } from '../../../../redux/thunk/reduxApis/warehouse/warehouse.api'
interface SupplierAndDeliveryDetailsResponse {
	supplier_details: {
		po_id: number
		po_farmart_id: string
		po_status: number
		retailer_id: number
		retailer_farmart_id: string
		retailer_name: string
		retailer_business_name: string
		bank_name: string
		account_number: string
		ifsc_code: string
		account_holder_name: string
		cancelled_cheque: string
	}
	delivery_details: {
		so_id: number
		so_farmart_id: string
		buyer_id: number
		buyer_farmart_id: string
		buyer_name: string
		delivery_location: {
			state: string
			district: string
			pincode: string
			address: string
		}
	}
}

export const getSupplierAndDeliveryDeailsApi = ApiCaller<
	FarmartResponse<SupplierAndDeliveryDetailsResponse>,
	any,
	any,
	{po_id: string}
>('getSupplierAndDeliveryDeailsApi', {
	url: 'v4/payment/supplier_and_delivery_detail',
	type: 'GET',
})
