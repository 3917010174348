import {PayloadAction, createSlice} from '@reduxjs/toolkit'
import {PaymentListApiRow} from '../types/payment.types'
const paymentTable = createSlice({
	name: 'paymentTable',
	initialState: {
		selectedRow: null,
		isDrawerOpen: false,
	} as {selectedRow: null | PaymentListApiRow; isDrawerOpen: boolean},
	reducers: {
		setSelectedRowAndOpenDrawer: (
			state,
			action: PayloadAction<PaymentListApiRow | null>,
		) => {
			state.selectedRow = action.payload
			state.isDrawerOpen = true
		},
		closeDrawer: state => {
			state.isDrawerOpen = false
			state.selectedRow = null
		},
	},
})

export default paymentTable.reducer
export const {setSelectedRowAndOpenDrawer, closeDrawer} = paymentTable.actions
