import {ApiCaller} from '../../../../component/apiRedux/apiCaller'
import {FarmartResponse} from '../../../../redux/thunk/reduxApis/states.api'

type WeightDetailsRequestType = {
	poId: number
	grossWeight: number
	netWeight: number
	bagDeduction: number
	numberOfBags: number
	mandiTax: number
	weightSlipUrl: string
	primary9rUrl: string
	first9rGatePassUrl: string
}

export const updateWeightDetailsApi = ApiCaller<FarmartResponse<any>, any, WeightDetailsRequestType>(
	'updateWeightDetailsApi',
	{
		type: 'POST',
		url: 'v4/payment/weight_details',
	},
)
