import {useDispatch, TypedUseSelectorHook, useSelector} from 'react-redux'
import {configureStore, combineReducers, AnyAction} from '@reduxjs/toolkit'
import userReducer from './slice/slice.user'
import paymentReducer from './slice/slice.payment'
import procurementDropdown from './slice/procuremntslice/dropdownslice'
import procurementReducer from './slice/slice.procurement'
import salesReducer from './slice/slice.sales'
import authReducer from './slice/slice.auth'
import campaignReducer from './slice/slice.campaign'
import soTargetReducer from './slice/slice.so_target'
import inventoryReducer from './slice/slice.inventory'
import globalReducer from './slice/globalslice/slice.geolocation'
import sampleReducer from './slice/slice.sample'
import secondaryOrderUnloadingReducer from './slice/slice.secondaryOrderUnloading';
import supplierReducer from './slice/slice.supplier';
import { thunkApis } from './thunk/reduxApis'
import paymentv2 from './../page/payments/paymentv2/redux/index'

import screenRecordReducer from './slice/slice.ticketing'
export const apiReducers = combineReducers(thunkApis.reduce((pre, cur) => ({
    [cur.reduxKey]: cur.reducer,
    ...pre
}), {}));

const combinedReducrer = combineReducers({
	user: userReducer,
	payment: paymentReducer,
	procurementdropdown: procurementDropdown,
	procurement: procurementReducer,
	sales: salesReducer,
	auth: authReducer,
	campaign: campaignReducer,
	soTarget: soTargetReducer,
	inventory: inventoryReducer,
	globalReducer: globalReducer,
	sample: sampleReducer,
	secondaryOrderUnloading: secondaryOrderUnloadingReducer,
	apis: apiReducers,
	supplier:supplierReducer,
	paymentv2: paymentv2,
	screenRecord:screenRecordReducer
})
const rootReducer = (state: any, action: AnyAction) => {
	if (action.type === 'auth/logout') {
		// reset all state here
		localStorage.removeItem('user-data')
		state = undefined
	}
	return combinedReducrer(state, action)
}

const reduxStore = configureStore({
	reducer: rootReducer,
})

export type TypeAppDispatch = typeof reduxStore.dispatch
export type AppRootState = ReturnType<typeof reduxStore.getState>

export const useAppDispatch = () => useDispatch<TypeAppDispatch>()
export const useAppSelector: TypedUseSelectorHook<AppRootState> = useSelector

export default reduxStore
