import { thunkApis } from "."
import { ApiCaller } from "../../../component/apiRedux/apiCaller"
import { FarmartResponse } from "./states.api"

export interface BankDetailsInterface{
    data: BankDetails;
 }
 interface BankDetails{
   bank_id?:string
   bank_name?:string,
   account_number?:string,
   ifsc_code?:string,
   account_holder_name?:string,
   cancelled_cheque?:string,
   account_detail_id?:string,
   merchant_doc_id?: number,
   status_of_doc?: number
}
export interface retailerDocsInterface{
    retailer_docs: RetailerDoc[];
 }
 interface RetailerDoc {
    doc_id?: number;
    type?: number;
    number?: string;
    front_url?: string;
    back_url?: string;
    status_of_doc?: number;
    additional_data?: string;
  }
  
  
export const getBankDetailsthunkApis=ApiCaller<FarmartResponse<BankDetailsInterface>>(
    'retailer_bank_details',
    {
        type:'GET',
        url:'/v3/retailer/bank_details'
    }
)
export const retailerKycDetailsDocs=ApiCaller<FarmartResponse<retailerDocsInterface>>(
    'retailer_kyc_bank_details',
    {
        type:'GET',
        url:'/v3/retailer/docs'
    }
)
