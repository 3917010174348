import React, { Dispatch, SetStateAction, useEffect } from 'react'

export interface TableContextProps {
    pageSize: number;
    setPageSize: Dispatch<SetStateAction<number>>,
    pageNumber: number;
    setPageNumber: Dispatch<SetStateAction<number>>,
    searchValue:string;
    setSearchValue: Dispatch<SetStateAction<string>>,
}

export const TableContext = React.createContext<TableContextProps | null>(null);

export interface TableContextProviderProps {
    children: JSX.Element | JSX.Element[]
}

export const TableContextProvider = (props: TableContextProviderProps) => {
	const [pageSize, setPageSize] = React.useState<number>(30)
	const [pageNumber, setPageNumber] = React.useState<number>(0)
	const [searchValue, setSearchValue] = React.useState<string>('')

	return (
		<TableContext.Provider
			value={{
				pageNumber,
				pageSize,
				searchValue,
				setPageNumber,
				setSearchValue,
				setPageSize,
			}}
		>
			<>{props.children}</>
		</TableContext.Provider>
	)
}

export function withTableContext<P extends object>(
	Component: React.FunctionComponent<P>,
): React.FC<P> {
	return props => (
		<TableContextProvider>
			<Component {...props} />
		</TableContextProvider>
	)
}
  