/*
	This function was created by Sagar Rajak to reduce the boilerplate code for api calling

	how this function works?
		Defination
			This function is primarily used in components directly to call api and get response
			like filters and dropdowns where we provide a thunk fuction to call api and get response
			and that component will deal with the data and will display accordingly
			but this function can be used in any component where we need to call api and store the response in redux state

			refrernce:- have a look at useage of quickFilterWithApi.tsx in src/component/quickFilterWithVariants/quickFilterWithApi.tsx
			what this fuction does is it creats an api slice and returns the reducer and thunk action along with the redux key
			and clear function to clear the redux state

			it needs a redux key to create a unique redux state for every api call
			so basically when ever we will call this function it will create a unique redux state for that api call
			and caller can subscribe to that redux state and can get the response and error and loading state of that api call

		Note: this function is imported in redux => thunk => reduxApis
					so we are creating a slice but we are not adding it to redux store in this function / file
					we are adding it to redux store in redux => thunk => reduxApis => index.tsx
					when ever you are creating a new api slice you have to add it to redux store in redux => thunk => reduxApis =>
					respective index.tsx file in folder like buyer.api.tsx in redux => thunk => reduxApis => buyers => index.tsx
					import this and export in the index.tsx file
					so that it can be used in the application
					else api will be called , even logs will be there in redux dev tools but you will not get the response in redux state
*/

import {Action, PayloadAction, createSlice} from '@reduxjs/toolkit'
import _ from 'lodash'
import {ThunkAction, ThunkDispatch} from 'redux-thunk'
import ServiceApi from '../../service/service.api'
import {
	ApiCallerResponseInterface,
	ApiInterface,
	RequestInterface,
	RequestOverrideOptionInterface,
} from './types'

const formatRequest = (request: RequestInterface): void => {
	if (_.isNil(request.headers)) {
		request.headers = {}
	}
	if (_.isNil(request.params)) {
		request.params = []
	}
	if (_.isNil(request.queryParams)) {
		request.queryParams = {}
	}
	if (_.isUndefined(request.requestData)) {
		request.requestData = null
	}
}

const setParams = (request: RequestInterface): void => {
	if (_.isNil(request.params) || request.params.length <= 0) return
	request.url =
		request.url +
		'/' +
		request.params.map((str: string) => encodeURIComponent(str)).join('/')
}

export class ApiError extends Error {
	public error: any
	constructor(message: string, err: any) {
		super(message)
		this.name = 'ApiError'
		this.error = err
	}
}

/**
 * @param request self explained request params
 * @param name must be unique for every api
 * TSuccessResponse = success response type
 * TErrorResponse = error response type default is null
 * TRequestData = 'request data type'
 * TQueryParams = 'queryParams'
 */
export const ApiCaller = <
	TSuccessResponse,
	TErrorResponse = any,
	TRequestData = any,
	TQueryParams extends {[key: string]: any} = any,
>(
	reduxKey: string,
	request: RequestInterface<TRequestData, TQueryParams>,
): ApiCallerResponseInterface<
	ApiInterface<TSuccessResponse, TErrorResponse>,
	TRequestData,
	TQueryParams
> => {
	/* refer top comment to know about working */
	formatRequest(request)
	
	const initialState: ApiInterface<TSuccessResponse, TErrorResponse> = {
		...request,
		requestData: null,
		responseData: null,
		error: null,
		isLoading: false,
	}

	const apiSlice = createSlice({
		name: reduxKey,
		initialState,
		reducers: {
			requested(state, action: PayloadAction<RequestInterface | null>): void {
				if (action.payload) {
					_.assign(state, action.payload)
				}
				state.isLoading = true
				state.responseData = null
				state.error = null
			},
			success(state, action): void {
				state.isLoading = false
				state.responseData = action.payload
				state.error = null
			},
			error(state, action): void {
				state.isLoading = false
				state.responseData = null
				state.error = action.payload
			},
			clear(state): void {
				state.isLoading = false
				state.responseData = null
				state.error = null
			},
		},
	})

	const {error, requested, success, clear} = apiSlice.actions
	const upperScopeRequest = request

	function thunkAction(
		request?: RequestOverrideOptionInterface,
	): ThunkAction<Promise<any>, any, unknown, Action<string>> {
		return async (
			dispatch: ThunkDispatch<any, unknown, Action<string>>,
		): Promise<any> => {
			if (_.isNil(request)) {
				request = {}
			}
			const currentRequest = _.assign({}, upperScopeRequest, request)
			formatRequest(currentRequest)
			dispatch(requested(currentRequest))
			setParams(currentRequest)
			if (currentRequest.type) {
				return ServiceApi.getInstance(currentRequest.instanceType)
					.Axios(currentRequest.url, {
						method: currentRequest.type,
						url: currentRequest.url,
						data: currentRequest.type !== 'GET' ? currentRequest.requestData : {},
						params: currentRequest.queryParams,
						headers: currentRequest.headers,
					})
					.then(axiosResponse => {
						if (axiosResponse.status !== 200) {
							throw new ApiError(
								axiosResponse?.data?.message
									? axiosResponse.data.message
									: 'Something went wrong!',
								axiosResponse.data,
							)
						} else if (
							!_.isNil(axiosResponse?.data.status) &&
							!axiosResponse?.data.status
						) {
							throw new ApiError(
								axiosResponse?.data?.message
									? axiosResponse.data.message
									: 'Something went wrong!',
								axiosResponse.data,
							)
						} else return dispatch(success(axiosResponse.data))
					})
					.catch(err => {
						dispatch(
							error(JSON.parse(JSON.stringify(err, Object.getOwnPropertyNames(err)))),
						)
						throw err
					})
			}
			return Promise.reject('Invalid Request')
		}
	}

	return {
		reducer: apiSlice.reducer,
		thunkAction,
		clear,
		reduxKey,
	}
}
