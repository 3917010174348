import {
	collection,
	doc,
	getDocs,
	getDoc,
	limit,
	startAfter,
	query,
	orderBy,
	where,
	endBefore,
	getCountFromServer,
	updateDoc,
	setDoc,
} from 'firebase/firestore'
import {db} from './firebaseConfig'

interface setDocDataT {
	collectionType: 'supplier' | 'products'
	key: any
	data: any
}
interface getDocDataT {
	collectionType: 'supplier' | 'products'
	pageSize: number
	start?: any
	end?: any
	filters?: getDocDataFilterType
	search?: string
}
interface getSerachDocT{
	collectionType: 'supplier' | 'products'
	search?: string
}

interface getDocDataFilterType {
	// keeping this optional as we may not need all the filters at all times
	status?: number
	state?: string
	district?: string
}

export async function setDocData(body: setDocDataT) {
	try {
		const docRef = doc(db, `${body.collectionType}/${body.key}`)
		await setDoc(docRef, body.data)
	} catch (error) {
		if (error instanceof Error) {
			console.log(error.message)
		} else {
			console.log('An unknown error occurred')
		}
	}
}
/* q = query(
				docRef,
				...queryFilters,
				orderBy('id'),
				where('searchField', '>=', search),
				where('searchField', '<=', search + '\uf8ff'), // '\uf8ff' is a high code point in Unicode to represent the end of a string
			) */

export async function searchDoc({
	collectionType,
	search,
}: getSerachDocT) {
	
	try {
		const docRef = collection(db, `${collectionType}`)
		let q = null
		q = query(
			docRef,
			where('businessName', '>=', search),
			where('businessName', '<=', search + '\uf8ff'), // '\uf8ff' is a high code point in Unicode to represent the end of a string
		)
		let data = await getDocs(q)
		return {
			response: data,
			totalRecords: 10,
		}
	} catch (error) {
		if (error instanceof Error) {
			console.log(error.message)
		} else {
			console.log('An unknown error occurred')
		}
	}
}

export async function updateDocData(body: setDocDataT) {
	try {
		const docRef = doc(db, `${body.collectionType}/${body.key}`)
		await updateDoc(docRef, body.data)
	} catch (error) {
		if (error instanceof Error) {
			console.log(error.message)
		} else {
			console.log('An unknown error occurred')
		}
	}
}

export async function getDocDataById({
	collectionType,
	key,
}: {
	collectionType: 'supplier' | 'products'
	key: any
}) {
	try {
		const docRef = doc(db, `${collectionType}/${key}`)
		const docSnap = await getDoc(docRef)
		return docSnap
	} catch (error) {
		if (error instanceof Error) {
			console.log(error.message)
		} else {
			console.log('An unknown error occurred')
		}
	}
}

export async function getDocData({
	collectionType,
	pageSize,
	start,
	end,
	filters,
	search = '',
}: getDocDataT) {
	try {
		const queryFilters = genrateQueryFilters(filters)
		const docRef = collection(db, `${collectionType}`)
		let q = null
		const totalRecords = await getCountFromServer(
			query(docRef, ...queryFilters, orderBy('id')),
		)

		if (start) {
			q = query(
				docRef,
				...queryFilters,
				orderBy('id'),
				startAfter(start),
				limit(pageSize),
			)
		} else if (end) {
			q = query(
				docRef,
				orderBy('id'),
				...queryFilters,
				endBefore(end),
				limit(pageSize),
			)
		} else {
			q = query(docRef, orderBy('id'), ...queryFilters, limit(pageSize))
		}
		let data = await getDocs(q)
		console.log(data, totalRecords.data().count, '==============', filters)
		return {
			response: data,
			totalRecords: totalRecords.data().count,
		}
	} catch (error) {
		if (error instanceof Error) {
			console.log(error.message)
		} else {
			console.log('An unknown error occurred')
		}
	}
}

function genrateQueryFilters(filters?: getDocDataFilterType) {
	const filterQuery = []

	if (filters) {
		if (filters.status) {
			filterQuery.push(where('status', '==', filters.status))
		}

		if (filters.state) {
			filterQuery.push(where('stateName', '==', filters.state))
		}

		if (filters.district) {
			filterQuery.push(where('districtName', '==', filters.district))
		}
	}

	return filterQuery
}
