import React, {createRef, useEffect} from 'react'
import {useAppDispatch, useAppSelector} from '../redux'
import styles from './sidebar.module.css'
import {
	forEmployees,
	forHr,
	forLogistic,
	forManagers,
	forVas,
} from './utils/getsidebarItems'
import Button from './../component/button'
import {authActions} from '../redux/slice/slice.auth'
import Container from '../component/container'
import Row from '../component/row'
import Title from '../component/title'
// Assests
import FarmartText from './../assets/icons/FarmartText.svg'
import Icon from './../assets/icons/Icon.svg'
// React Dom
import {Link, useLocation, useNavigate} from 'react-router-dom'
// React Popup
// Mui
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Box from '@mui/material/Box'
import Collapse from '@mui/material/Collapse'
import MuiDrawer from '@mui/material/Drawer'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Typography from '@mui/material/Typography'
import {CSSObject, Theme, styled} from '@mui/material/styles'
import {designations} from '../utils'
import metaData from './../../package.json'
import { forFinanceRoleNavbar } from './utils/forFinanceRoleNavbar'

const drawerWidth = 270

const openedMixin = (theme: Theme): CSSObject => ({
	width: drawerWidth,
	transition: theme.transitions.create('width', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.enteringScreen,
	}),
	overflowX: 'hidden',
	boxShadow: '0 0 10px',
})

const closedMixin = (theme: Theme): CSSObject => ({
	transition: theme.transitions.create('width', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	overflowX: 'hidden',
	width: `calc(${theme.spacing(7)} + 1px)`,
	[theme.breakpoints.up('sm')]: {
		width: `calc(${theme.spacing(8)} + 1px)`,
	},
})

const DrawerHeader = styled('div')(({theme}) => ({
	padding: theme.spacing(0, 0.5),
	// necessary for content to be below app bar
	...theme.mixins.toolbar,
}))

const Drawer = styled(MuiDrawer, {shouldForwardProp: prop => prop !== 'open'})(
	({theme, open}) => ({
		width: drawerWidth,
		flexShrink: 0,
		whiteSpace: 'nowrap',
		boxSizing: 'border-box',
		position: 'fixed',
		zIndex: 100,
		...(open && {
			...openedMixin(theme),
			'& .MuiDrawer-paper': openedMixin(theme),
		}),
		...(!open && {
			...closedMixin(theme),
			'& .MuiDrawer-paper': closedMixin(theme),
		}),
	}),
)

export default function FmtSideBar() {
	const initialSubCat = {
		isProcurementOpen: false,
		isSales: false,
		isPaymentOpen: false,
	}

	const [open, setOpen] = React.useState(false)
	const [subCatOpen, setSubCatOpen] = React.useState<any>(initialSubCat)
	const [isSubCatSelected, setSubSelected] = React.useState<any>(false)
	const actionDispatch = useAppDispatch()
	const navigate = useNavigate()
	const {pathname} = useLocation()
	const _popup = createRef<any>()

	function handleSubategory(feildName: String) {
		return function () {
			// @ts-ignore
			setSubCatOpen({...subCatOpen, [feildName]: !subCatOpen[feildName]})
		}
	}
	useEffect(() => {
		if (open)
			setSubCatOpen({
				...subCatOpen,
				[isSubCatSelected]: !subCatOpen[isSubCatSelected],
			})
	}, [open])

	function handleMouseOver() {
		setOpen(true)
	}
	function handleMouseLeave() {
		setOpen(false)
		setSubCatOpen(initialSubCat)
	}
	const {profile} = useAppSelector(state => {
		return {
			isLoggedIn: state.auth.authenticated,
			profile: state.auth.userProfile,
		}
	})
	let NavItems: any = []
	if(
		profile.user_role_id === designations['ACCOUNT_PAYABLES'] ||
		profile.user_role_id === designations['FINANCE_CONTROLLER'] ||
		profile.user_role_id === designations['VP_PROCUREMENT'] ||
		profile.user_role_id === designations['KYC_ANALYST']||
		profile.user_role_id === designations['ACCOUNT_RECEIVABLES']||
		profile.user_role_id === designations['COMPLIANCE_EXECUTIVE']||
		profile.user_role_id === designations['SALES_VP']
	){
		NavItems = forFinanceRoleNavbar
	}
	else if (
		profile.user_role_id === designations['SALES_VP'] ||
		profile.user_role_id === designations['SUPPLY_CHAIN_ADMIN']
	) {
		NavItems = forManagers
	} 
	else if (profile.user_role_id === designations['HR_ONBOARDING']) {
		NavItems = forHr
	} else if (
		profile.user_role_id === designations['LOGISTICS_MANAGER'] ||
		profile.user_role_id === designations['LOGISTICS_ASSOCIATE']
	) {
		NavItems = forLogistic
	} else if (
		profile.user_role_id === designations['VAS_ASSOCIATES'] ||
		profile.user_role_id === designations['VAS_KEY_ACCOUNT_MANAGER']
	) {
		NavItems = forVas
	} else {
		NavItems = forEmployees
	}
	const version = metaData.version
	return (
		<Box sx={{display: 'flex'}}>
			<Drawer
				variant='permanent'
				open={open}
				onMouseOver={handleMouseOver}
				onMouseLeave={handleMouseLeave}
				PaperProps={{
					style: {
						backgroundColor: '#005A54',
					},
				}}
			>
				<DrawerHeader>
					<div className={styles.drawer_header}>
						{open ? (
							<>
								<span>
									<img src={Icon} />
								</span>
								<span>
									<img src={FarmartText} style={{width: '110%', marginLeft: '15px'}} />
								</span>
							</>
						) : (
							<img src={Icon} />
						)}
					</div>
				</DrawerHeader>
				<List sx={{height: '100%'}}>
					<div className={styles.nav_list}>
						<div>
							{NavItems.map((navItem: any, index: any) => (
								<ListItem
									key={index}
									sx={{
										display: 'block',
										padding: '3px 0px', // selected and (selected + hover) states
										'&& .Mui-selected, && .Mui-selected:hover': {
											bgcolor: '#EFDA86',
											'&, & .MuiListItemIcon-root': {
												color: '#005A54',
											},
											'&, & .MuiListItemText-root': {
												color: '#005A54',
											},
										},
										// hover states
										'& .MuiListItemButton-root:hover': {
											bgcolor: '#AED2C0',
											'&, & .MuiListItemIcon-root': {
												color: '#005A54',
											},
											'&, & .MuiListItemText-root': {
												color: '#005A54',
											},
										},
									}}
									disablePadding
								>
									{/* need to improve */}
									{navItem?.subcat ? (
										<ListItemButton
											selected={navItem.to === pathname}
											sx={{
												height: 45,
												justifyContent: open ? 'initial' : 'center',
												px: 2.5,
											}}
											onClick={handleSubategory(navItem.feild)}
										>
											<ListItemIcon
												sx={{
													minWidth: 0,
													mr: open ? 3 : 'auto',
													justifyContent: 'center',
													color: '#FAF7EF',
												}}
											>
												<navItem.icon />
											</ListItemIcon>
											<ListItemText
												disableTypography
												primary={
													<Typography sx={{fontSize: '14px'}}>{navItem.navlabel}</Typography>
												}
												sx={{
													opacity: open ? 1 : 0,
													color: '#FAF7EF',
												}}
											/>
											{!open ? null : subCatOpen[navItem.feild] ? (
												<ExpandLessIcon sx={{color: '#FAF7EF'}} />
											) : (
												<ExpandMoreIcon sx={{color: '#FAF7EF'}} />
											)}
										</ListItemButton>
									) : (
										<ListItemButton
											selected={navItem.to === pathname}
											sx={{
												height: 45,
												justifyContent: open ? 'initial' : 'center',
												px: 2.5,
											}}
											component={Link}
											to={navItem.to}
											onClick={() => setSubSelected(false)}
										>
											<ListItemIcon
												sx={{
													minWidth: 0,
													mr: open ? 3 : 'auto',
													justifyContent: 'center',
													color: '#FAF7EF',
												}}
											>
												<navItem.icon />
											</ListItemIcon>
											<ListItemText
												disableTypography
												primary={
													<Typography sx={{fontSize: '14px'}}>{navItem.navlabel}</Typography>
												}
												sx={{
													opacity: open ? 1 : 0,
													color: '#FAF7EF',
												}}
											/>
										</ListItemButton>
									)}

									<Collapse in={subCatOpen[navItem.feild]} unmountOnExit>
										<List disablePadding>
											{navItem.subcat?.map((subcatItem: any, index: any) => (
												<ListItem
													key={index}
													sx={{
														display: 'block',
														padding: '3px 20px',
														'&& .Mui-selected, && .Mui-selected:hover': {
															bgcolor: '#EFDA86',
															'&, & .MuiListItemIcon-root': {
																color: '#005A54',
															},
															'&, & .MuiListItemText-root': {
																color: '#005A54',
															},
														},
														// hover states
														'& .MuiListItemButton-root:hover': {
															bgcolor: '#AED2C0',
															'&, & .MuiListItemIcon-root': {
																color: '#005A54',
															},
															'&, & .MuiListItemText-root': {
																color: '#005A54',
															},
														},
													}}
												>
													<ListItemButton
														sx={{
															justifyContent: open ? 'initial' : 'center',
															px: 2.5,

															borderRadius: '10px',
														}}
														component={Link}
														to={subcatItem.to}
														onClick={() => setSubSelected(navItem.feild)}
														selected={subcatItem.to === pathname}
													>
														<ListItemText
															disableTypography
															primary={
																<Typography sx={{fontSize: '14px'}}>
																	{subcatItem.navlabel}
																</Typography>
															}
															sx={{color: '#FAF7EF'}}
														/>
													</ListItemButton>
												</ListItem>
											))}
										</List>
									</Collapse>
								</ListItem>
							))}
						</div>
					</div>
				</List>
				<ListItem>
					<Typography sx={{fontSize: '10px', color: '#EFDA86'}}>
						{open ? `Version ${version}` : version}
					</Typography>
				</ListItem>
			</Drawer>
		</Box>
	)
}
