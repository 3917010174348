import {PayloadAction, createSlice} from '@reduxjs/toolkit'

const uploadFileDrawer = createSlice({
	name: 'uploadFileDrawer',
	initialState: {
		isDrawerOpen: false,
	},
	reducers: {
		setDrawerState: (state, action: PayloadAction<boolean>) => {
			state.isDrawerOpen = action.payload
		},
	},
})

export default uploadFileDrawer.reducer
export const {setDrawerState} = uploadFileDrawer.actions
