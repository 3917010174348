import {GridColDef} from '@mui/x-data-grid/models/colDef'
import {reformatTimestamp} from './../../../../hooks'

export const columns: GridColDef[] = [
	{
		field: 'dispatch_date',
		headerName: 'Dispatch Date',
		flex: 1,
	},
	{
		field: 'po_number',
		headerName: 'PO Number',
		flex: 1,
	},
	{
		field: 'so_number',
		headerName: 'SO Number',
		flex: 1,
	},
	{
		field: 'buyer_name',
		headerName: 'Buyer Name',
		flex: 1,
	},
	{
		field: 'Po_status',
		headerName: 'Status of Inventory',
		flex: 1,
	},
	{
		field: 'source',
		headerName: 'Source',
		flex: 1,
	},
	{
		field: 'destination',
		headerName: 'Destination',
		flex: 1,
	},
	{
		field: 'crop_and_variety',
		headerName: 'Crop & Variety',
		flex: 1,
	},
	{
		field: 'handover_weight',
		headerName: 'Handover Weight',
		flex: 1,
	},
	{
		field: 'accepted_weight',
		headerName: 'Accepted Weight',
		flex: 1,
	},
]

const getTableObject = (RawObject: any) => {
	return {
		...RawObject,
		id: RawObject.po_id,
		dispatch_date: reformatTimestamp(RawObject.dispatch_date),
		po_number: RawObject.po_number ?? 'N/A',
		so_number: RawObject.so_number ?? 'N/A',
		buyer_name: RawObject.buyer_name ?? 'N/A',
		po_status: RawObject.po_status ?? 'N/A',
		source: RawObject.source ?? 'N/A',
		destination: RawObject.destination ?? 'N/A',
		crop_and_variety: RawObject.crop_and_variety ?? 'N/A',
		handover_weight: RawObject.handover_weight ?? 'N/A',
		accepted_weight: RawObject.accepted_weight ?? 'N/A',
		po_id: RawObject.po_id,
		po_pending_inhand_id: RawObject.po_pending_inhand_id,
	}
}

export const generateTableRows = (RawData: any) => {
	const tableRowArr = []
	for (let item in RawData) {
		const obj = getTableObject(RawData[item])
		tableRowArr.push(obj)
	}

	return tableRowArr
}

export const getTotalPages = (pageSize: number, totalRecords: number) =>
	Math.floor(totalRecords / pageSize) === +(totalRecords / pageSize)
		? Math.floor(totalRecords / pageSize)
		: Math.floor(totalRecords / pageSize) + 1


export function uuid() {
	var S4 = function() {
		return (((1+Math.random())*0x10000)|0).toString(16).substring(1);
	};
	return (S4()+S4()+"-"+S4()+"-"+S4()+"-"+S4()+"-"+S4()+S4()+S4());
}