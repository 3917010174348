import {GridColDef} from '@mui/x-data-grid'
//Types==============
import {IRejectedPoInfo, IRejectPoTableObj} from './../types'
//Hooks==============
import {reformatTimestamp} from '../../../../hooks'

const getTableObject = (RawObject: IRejectedPoInfo) => {
	return {
		id: RawObject.po_pending_in_hand_id,
		unloading_date: RawObject.unloading_date 
			? reformatTimestamp(RawObject.unloading_date)
			: 'N/A',
		po_number: RawObject.po_number ?? 'N/A',
		po_pending_in_hand_id: RawObject.po_pending_in_hand_id ?? null,
		so_number: RawObject.so_number ?? 'N/A',
		buyer_name: RawObject.buyer_name ?? 'N/A',
		current_location: RawObject.current_location ?? 'N/A',
		crop_info: RawObject.commodity ?? 'N/A',
		handover_weight: RawObject.handover_weight ?? 'N/A',
		accepted_weight: RawObject.accepted_weight ?? 'N/A',
	}
}

export const generateTableRows = (RawData: IRejectedPoInfo[]) => {
	const tableRowArr: IRejectPoTableObj[] = []
	for (let item in RawData) {
		const obj = getTableObject(RawData[item])
		tableRowArr.push(obj)
	}

	return tableRowArr
}

export const tableColumns: GridColDef[] = [
	{
		field: 'unloading_date',
		headerName: 'Unloading Date',
		flex: 1,
	},
	{
		field: 'po_number',
		headerName: 'Po Number',
		flex: 1,
	},
	{
		field: 'so_number',
		headerName: 'So Number',
		flex: 1,
	},
	{
		field: 'buyer_name',
		headerName: 'Buyer Name',
		flex: 1,
	},
	{
		field: 'current_location',
		headerName: 'Current Location',
		flex: 1,
	},

	{
		field: 'crop_info',
		headerName: 'Crop & Variety',
		flex: 1,
	},
	{
		field: 'handover_weight',
		headerName: 'Handover Weight',
		flex: 1,
	},
	{
		field: 'accepted_weight',
		headerName: 'Accepted Weight',
		flex: 1,
	},
]
