import {createSlice} from '@reduxjs/toolkit'
import {toast} from 'react-toastify'
import {
	fetchQcReport,
	fetchSampleDetails,
	fetchSapleList,
} from '../thunk/thunk.sample'
import {ISampleState} from '../types/types.sample'

const initialState: ISampleState = {
	sampleList: [],
	count: 0,
	fetchSampleList: false,
	fetchingSampleDetails: true,
	selectedSample: null,
}

export const sliceSample = createSlice({
	name: 'sample',
	initialState,
	reducers: {
		setSelectedSample: (state, action) => {
			state.selectedSample = action.payload
			state.fetchingSampleDetails = true
		},
		selectSample: (state, payload: {type: string; payload: ISample | null}) => {
			state.selectedSample = payload.payload
		},
	},
	extraReducers: builder => {
		builder.addCase(fetchSapleList.pending, state => {
			state.sampleList = []
			state.fetchSampleList = true
		})
		builder.addCase(fetchSapleList.fulfilled, (state, action) => {
			state.fetchSampleList = false
			state.sampleList = action.payload?.rows
			state.count = action.payload?.count
		})
		builder.addCase(fetchSapleList.rejected, (state, action) => {
			const message = action.payload as string
			if (message !== 'canceled') {
				state.fetchSampleList = false
				toast(message, {type: 'error'})
			}
		})
		builder.addCase(fetchQcReport.pending, state => {})
		builder.addCase(fetchQcReport.fulfilled, (state, action) => {})
		builder.addCase(fetchQcReport.rejected, (state, action) => {
			const message = action.payload as string
			toast(message, {type: 'error'})
		})
		builder.addCase(fetchSampleDetails.pending, state => {
			state.selectedSample = null
			state.fetchingSampleDetails = true
		})
		builder.addCase(fetchSampleDetails.fulfilled, (state, action) => {
			state.fetchingSampleDetails = false
			state.selectedSample = action.payload
		})
		builder.addCase(fetchSampleDetails.rejected, (state, action) => {
			state.fetchingSampleDetails = false
			const message = action.payload as string
			toast(message, {type: 'error'})
		})
	},
})

export const sampleAction = {
	fetchSapleList,
	fetchQcReport,
	fetchSampleDetails,
	...sliceSample.actions,
}
export default sliceSample.reducer
