import styled from 'styled-components'

const Title = styled.h1`
	color: #0d0d0d;
	font-size: 1rem;
	font-weight: 700;
	font-family: 'Quicksand', sans-serif;
`

export default Title
