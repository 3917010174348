import {ApiCaller} from '../../../component/apiRedux/apiCaller'
import {FarmartResponse} from './states.api'

export interface TerminationTypeList {
	id: number
	name: string
}

export const getUrlForImage = ApiCaller<FarmartResponse<any>>('upload_imgae', {
	type: 'POST',
	url: 'v1/multimedia/image',
})
