import {
	getRetailesBankDetails,
	updateRetailerBankDetails,
} from './retailers/retailers.api'
import {
	getSampleDetails,
	updateSampleDetails,
} from './supplyvisibility/supplyvisibility'

export const procurmentApis = [
	getSampleDetails,
	updateSampleDetails,
	getRetailesBankDetails,
	updateRetailerBankDetails,
]
