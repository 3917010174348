import LocalShippingIcon from '@mui/icons-material/LocalShipping'
import GridViewIcon from '@mui/icons-material/GridView'

export const forVas = [
	{
		navlabel: 'Dashboard',
		to: '/protected',
		icon: GridViewIcon,
		subcat: [
			{
				navlabel: 'Company metrics',
				to: '/protected/dashboard/company_metrics',
			},
			{
				navlabel: 'Buyer Overview',
				to: '/protected/dashboard/buyer_overview',
			},
			{
				navlabel: 'Seller Overview',
				to: '/protected/dashboard/seller_metrics',
			},
			{
				navlabel: 'Operational Funnels',
				to: '/protected/dashboard/operational_funnels',
			},
			{
				navlabel: 'Procurement',
				to: '/protected/dashboard/procurement',
			}
		],
	},
	{
		navlabel: 'VAS',
		to: '/protected',
		icon: LocalShippingIcon,
		feild: 'isVas',
		subcat: [
			{
				navlabel: 'Leads',
				to: '/protected/vas/supplierList',
			},
			{
				navlabel: 'Customers',
				to: '/protected/vas/vasCustomers',
			},
		],
	},
]
