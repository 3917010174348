import axios, {AxiosInstance, AxiosRequestConfig} from 'axios'
import {getUserData} from '../redux/slice/slice.auth'
import ENV from './../config/environment'


export default class ServiceApi {
	public _axios: AxiosInstance
	private static _instanceOS: ServiceApi
	private static _instatceDS: ServiceApi

	/* 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbmNyeXB0ZWRfdG9rZW4iOiIwM2UwZGY2MzlmMzczMjhkMDkzNDFlOWU5YzBjODc1NDc1Y2E2YTczM2Y1MTFmZWRjN2M4OWU5MGRlMzc3YzE2NDhmMDcwMTZkZjczODFjMTcyYmYwNTJlOTE2NDM1OGFhN2RhZDcwN2Q5ZjAyOWY3NTZmMzRhYTA0MzZjOTZmNTU1NTIiLCJpYXQiOjE2NTMzODAyOTl9.vz781MPAvNHrnME_KNteo_JicXX98Mw1Qhur9ADo4fc' */
	public static getInstance = (type?: 'DS' | 'OS') => {
		if (type == 'DS' && ServiceApi._instatceDS) return ServiceApi._instatceDS;
		if ((!type || type === 'OS' ) && ServiceApi._instanceOS) return ServiceApi._instanceOS;

		if (type === 'DS') {
		 	ServiceApi._instatceDS = new ServiceApi()
			ServiceApi._instatceDS._axios = axios.create({
				baseURL: ENV.dsApiUrlBuyerCredit,
				validateStatus: status => true,
				responseType: 'json',
				withCredentials: false,
			})
			ServiceApi._instatceDS._axios.interceptors.request.use(
				config => ({
					...config,
					headers: {
						...config.headers,
						authorization: 'Bearer e0573219-bcbe-4ef4-a8a8-81534b249a16',
					},
				}),
				err => err,
			)
			ServiceApi._instatceDS._axios.interceptors.response.use(
				response => {
					if (response.status === 401) {
						localStorage.clear()
						window.location.reload()
					}
					return response
				},
				error => {
					if (error.status === 401) {
						localStorage.clear()
						window.location.reload()
					}
					return error
				},
			)
			return ServiceApi._instatceDS;
		} else {
			ServiceApi._instanceOS = new ServiceApi()
			ServiceApi._instanceOS._axios = axios.create({
				baseURL: ENV.api_url,
				validateStatus: status => true,
				responseType: 'json',
				withCredentials: false,
			})
			ServiceApi._instanceOS._axios.interceptors.request.use(
				config => ({
					...config,
					headers: {
						...config.headers,
						authorization: `Bearer ${getUserData().token}`,
					},
				}),
				err => err,
			)
			ServiceApi._instanceOS._axios.interceptors.response.use(
				response => {
					if (response.status && response.status === 401) {
						localStorage.clear()
						window.location.reload()
					}
					return response
				},
				error => {
					if (error.status && error.status === 401) {
						localStorage.clear()
						window.location.reload()
					}
					return error
				},
			)
			return ServiceApi._instanceOS;
		}
	}

	public GET = (
		url: string,
		config?: AxiosRequestConfig,
	) => this._axios.get(url, {...config})

	public POST = (
		url: string,
		data: any,
		config?: AxiosRequestConfig,
	) => this._axios.post(url, data, {...config})

	public PUT = (
		url: string,
		data: any,
		config?: AxiosRequestConfig,
	) => this._axios.put(url, data, {...config})

	public PATCH = (
		url: string,
		data: any,
		config?: AxiosRequestConfig,
	) => this._axios.patch(url, data, {...config})

	public DELETE = (
		url: string,
		config?: AxiosRequestConfig,
	) => this._axios.delete(url, {...config})

	public Axios = (url: string, config: AxiosRequestConfig) => {
		return this._axios({
			url,
			...config,
		})
	}
}