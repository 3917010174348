import React, {Suspense, useEffect} from 'react'
import {Route} from 'react-router-dom'
import PrivateRoute from '../../../component/route'
import Loader from '../../../component/loader'

const SampleDetailView = React.lazy(
	() => import('./view/DrawerIndex'),
)

const SupplyVisiblityDetailPage = () => {
	return (
		<Suspense fallback={<Loader />}>
			<SampleDetailView />
		</Suspense>
	)
}


export const RenderSupplyDetailsRoute = () => {
	return (
		<>
			<Route path='supply/supplydetail/:sampleID'>
				<Route
					path='details'
					element={<PrivateRoute component={SupplyVisiblityDetailPage} />}
				></Route>
			</Route>
		</>
	)
}
