import {ApiCaller} from '../../../../component/apiRedux/apiCaller'
import {FarmartResponse} from '../../../../redux/thunk/reduxApis/states.api'

export interface GstPaymentDetails {
	igst_amount: number,
	cgst_amount: number,
	sgst_amount: number,
	igst_percentage: number,
	cgst_percentage: number,
	sgst_percentage: number,
	payable_amount: number,
	paid_amount: number,
	pending_amount: number,
	initiated_amount: number
}

export const gstPaymentDetailsApi = ApiCaller<
	FarmartResponse<GstPaymentDetails>,
	any,
	any,
	{po_id: string}
>('gstPaymentDetailsApi', {
	url: 'v4/payment/gst/details',
	type: 'GET',
})
