import React from 'react'
import styles from './header.module.css'
import {AccountMenu} from './../../molecules/actionMenu'
import { useLocation } from 'react-router-dom'
interface HeaderProps {
	actions: any
}

export function Header(props: HeaderProps) {
	const {actions} = props
	const location=useLocation();
	const{pathname}=location // It will return the path.
	let str=pathname.substring(11) // substring after '/protected'
	let i=0;                       // Loop which will give the next index.
	while(i!=str.length && str[i]!='/')
	{
		i++;
	}
	let title=str.substring(0,i) // Able to fetch titile of page.
    title=title.charAt(0).toUpperCase()+title.substring(1); // First character should be capital.
	return (
		<div className={styles.headerContainer}>
			<div className={styles.headertitle}>{title}</div>
			<div className={styles.parentIconContainer}>
			{actions.map(( value: any,index: any) => {
				return (
					<div key={index+'a'} className={styles.iconContainer}>
						<AccountMenu type={value.iconName} label={value.label} tooltipTitle={value.title} menuData={value.menuData} profile={value.profile}/>
					</div>
				)
			})}
			</div>
		</div>
	)
}
