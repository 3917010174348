import { ApiCaller } from '../../../component/apiRedux/apiCaller'
import { FarmartResponse } from './states.api'

export interface CropsType {
	name: string
	isActive: boolean
	_id: number
}

export interface CropsTypeV2 {
	id: number
	crop_name: string
	no_of_varieties: number
}

export interface CropVarietiesT {
	id: number
	crop_variety_name: string
}

export interface VarietyQcChecklistT {
	id: number
	qc_type_id: number
	qc_name: string
	value: string
	condition: string
	is_image_required: boolean
}

export interface VarietySamplingT {
	id: number
	weight_from: number
	weight_to: number
	minimum_images: number
	sample_count: number
}

export interface VarietyDispatchDocT {
	id: number
	dispatch_doc_id: number
	doc_name: string
	is_mandatory: boolean
	is_image_required: boolean
}

export interface QcChecklistT {
	id: number
	name: string
}

export const fetchCropApiGet = ApiCaller<FarmartResponse<CropsType[]>>(
	'crops_list',
	{
		type: 'GET',
		url: '/v1/crop',
	},
)
export const createCropApi = ApiCaller<FarmartResponse<CropsType[]>>(
	'create_crops_list',
	{
		type: 'POST',
		url: '/v1/crop',
	},
)

export const fetchCropApiV2 = ApiCaller<FarmartResponse<CropsTypeV2[]>>(
	'crops_list_v2',
	{
		type: 'GET',
		url: '/v2/crop',
	},
)

export const fetchCropVarieties = ApiCaller<FarmartResponse<CropVarietiesT[]>>(
	'varieties_list',
	{
		type: 'GET',
		url: '/v1/crop/variety',
	},
)
export const createCropVariety = ApiCaller<FarmartResponse<any>>(
	'create_varieties_list',
	{
		type: 'POST',
		url: '/v1/crop/variety',
	},
)

/* Fetch variety QC checklist */
export const fetchVarietyQcChecklist = ApiCaller<
	FarmartResponse<VarietyQcChecklistT[]>
>('variety_qc_checklist', {
	type: 'GET',
	url: '/v1/crop/variety/qc_checklist',
})
/* Fetch data for dropdown */
export const fetchQcChecklist = ApiCaller<FarmartResponse<QcChecklistT[]>>(
	'qc_check_list',
	{
		type: 'GET',
		url: '/v1/crop/qc_check_list',
	},
)

/* Create variety QC checklist */
export const createVarietyQcChecklist = ApiCaller<FarmartResponse<any>>(
	'create_variety_qc_checklist',
	{
		type: 'POST',
		url: '/v1/crop/variety/qc_checklist',
	},
)

/* Update variety QC checklist */
export const updateVarietyQcChecklist = ApiCaller<FarmartResponse<any>>(
	'update_variety_qc_checklist',
	{
		type: 'PUT',
		url: '/v1/crop/variety/qc_checklist',
	},
)

/* Delete variety QC checklist */
export const deleteVarietyQcChecklist = ApiCaller<FarmartResponse<any>>(
	'delete_variety_qc_checklist',
	{
		type: 'DELETE',
		url: '/v1/crop/variety/qc_checklist',
	},
)

export const fetchVarietySampling = ApiCaller<
	FarmartResponse<VarietySamplingT[]>
>('variety_sampling', {
	type: 'GET',
	url: '/v1/crop/variety/sampling',
})
export const createVarietySampling = ApiCaller<FarmartResponse<any>>(
	'create_variety_sampling',
	{
		type: 'POST',
		url: '/v1/crop/variety/sampling',
	},
)
export const updateVarietySampling = ApiCaller<FarmartResponse<any>>(
	'update_variety_sampling',
	{
		type: 'PUT',
		url: '/v1/crop/variety/sampling',
	},
)
export const deleteVarietySampling = ApiCaller<FarmartResponse<any>>(
	'delete_variety_sampling',
	{
		type: 'DELETE',
		url: '/v1/crop/variety/sampling',
	},
)
/* Fetch variety doc list */
export const fetchVarietyDispatchDoc = ApiCaller<
	FarmartResponse<VarietyDispatchDocT[]>
>('variety_dispatch_doc', {
	type: 'GET',
	url: '/v1/crop/variety/dispatch_doc',
})
/* create variety doc list */
export const createVarietyDispatchDoc = ApiCaller<FarmartResponse<any>>(
	'create_variety_dispatch_doc',
	{
		type: 'POST',
		url: '/v1/crop/variety/dispatch_doc',
	},
)
/* update variety doc list */
export const updateVarietyDispatchDoc = ApiCaller<FarmartResponse<any>>(
	'update_variety_dispatch_doc',
	{
		type: 'PUT',
		url: '/v1/crop/variety/dispatch_doc',
	},
)
/* delete variety doc list */
export const deleteVarietyDispatchDoc = ApiCaller<FarmartResponse<any>>(
	'delete_variety_dispatch_doc',
	{
		type: 'DELETE',
		url: '/v1/crop/variety/dispatch_doc',
	},
)

/* Fetch data for dropdown  list*/
export const fetchDispatchDocList = ApiCaller<FarmartResponse<QcChecklistT[]>>(
	'doc_at_dispatch',
	{
		type: 'GET',
		url: '/v1/crop/doc_at_dispatch',
	},
)
/* fetch crom gst  */
export const fetchCropGst = ApiCaller<FarmartResponse<any>>('gst_details', {
	type: 'GET',
	url: '/v1/crop/variety/gst_details',
})
/* add crom gst  */
export const createCropGst = ApiCaller<FarmartResponse<any>>(
	'create_gst_details',
	{
		type: 'POST',
		url: '/v1/crop/variety/gst_details',
	},
)
