import { ApiCaller } from "../../../../../component/apiRedux/apiCaller";
import { FarmartResponse } from "../../../../../redux/thunk/reduxApis/states.api";


export interface RejectionReasonResponse {
    payment_rejection_reasons: {id: number, name: string}[]
};

// 1 for retailer 2 for transport
export const rejectionReasonApi = ApiCaller<
	FarmartResponse<RejectionReasonResponse>,
	any,
	any,
	{rejection_for: 1 | 2}
>('rejectionReasonsPayment', {
	type: 'GET',
	url: '/v4/payment/rejection_reasons',
})