import React from 'react'
import {Tooltip, TooltipProps} from '@mui/material'

export type TooltipComponentProps = Pick<TooltipProps, 'title'>

export interface TooltipComponentAllProps extends TooltipComponentProps {
	children: any
}

export function TooltipComponent(props: TooltipComponentAllProps) {
	const {title, children} = props
	return <Tooltip arrow title={title}>{children}</Tooltip>
}
TooltipComponent.defaultProps = {
	title: '',
}