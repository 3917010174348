import {ApiCaller} from '../../../../component/apiRedux/apiCaller'
import {FarmartResponse} from '../../../../redux/thunk/reduxApis/states.api'

export interface UnloadingDetaisInterface {
	id: number
	po_pending_inhand_id?: any
	po_number?: string
	status?: number
	po_pending_inhand_status?: any
	crop_name?: string
	variety_name?: string
	quantity_kg?: number
	no_of_bags?: number
	arrival_info?: ArrivalInfoInterface
	transport_info?: TransportInfoInteface
	pa_info?: PaInfoInterface
	buyer_info?: BuyerInfoInterface
	sales_order_info?: SalesOrderInfoInteface
	unloading_docs?: UnloadingDocInterace[]
	deduction_report?: DeductionReportInterface
	unloading_details?: UnloadingDetailInterface[]
	po_pending_inhand_details?: PoPendingInhandDetailsInterface
	qc_checks?: QcCheckInterface[]
}

export interface QcCheckInterface {
	name: string
	target_value: string
	actual_value: string
}

export interface ArrivalInfoInterface {
	arrived_at: string
	images: string[]
}

export interface TransportInfoInteface {
	transport_name: string
	driver_name: string
	driver_mobile_number: number
	vehicle_number: string
}

export interface PaInfoInterface {}

export interface BuyerInfoInterface {
	name: string
	state: string
	district: string
	address: string
}

export interface SalesOrderInfoInteface {
	id: number
	so_number: string
	qc_list: QcList[]
}

export interface QcList {
	buyer_order_qc_id: number
	type: number
	name: string
	target_value: string
	actual_value: string
}

export interface UnloadingDocInterace {
	type: number
	name: string
	url: string
}

export interface DeductionReportInterface {
	deduction_amount: number
	deduction_remarks: string
	deduction_report_url: string
	unloading_comment: string
}

export interface UnloadingDetailInterface {
	type: number
	name: string
	value: number
}

export interface PoPendingInhandDetailsInterface {
	rejected_quantity: number
	rejected_bags: number
	warehouse_id: number
	send_to: number
}

export const unloadingDetailsApi = ApiCaller<
	FarmartResponse<UnloadingDetaisInterface>,
	any,
	any,
	{po_id: number; so_id: number}
>('unloadingDetailsApiPayment', {
	type: 'GET',
	url: 'v2/po_unloading',
})
