import {createAsyncThunk} from '@reduxjs/toolkit'
import download from 'downloadjs'
import ServiceApi from '../../service/service.api'
import {PaymentRequestFormat} from '../types/type.payment'

type UtrData = {
	id: string
	paymentFor: 'retailer' | 'transport'
	paymentType: 'part_one' | 'part_two'
	utr: string
	index: any
}

type BankingData = {
	id: string
	detailsOf: 'retailer' | 'transport'
	accountNumber: string
	ifscCode: string
	accountHolderName: string
	bankName: string
	index: number
}

export const fetchPaymentList = createAsyncThunk(
	'/payment/list',
	async (
		paginationData: {
			limit: number
			page: number
			search_param: string | null
			with_gst: string
		},
		thunkAPI,
	) => {
		try {
			const {status, data} = await ServiceApi.getInstance().GET('/v3/payment/', {
				params: {
					...paginationData,
				},
			})
			if (status !== 200)
				throw new Error(
					data?.message ?? 'something went wrong while fetching payment list.',
				)

			return data.data
		} catch (err: any) {
			return thunkAPI.rejectWithValue(err.message)
		}
	},
)

export const fetchPaymentDetail = createAsyncThunk(
	'v2/payment/retailer',
	async (PaymentId: any, thunkAPI) => {
		try {
			const {status, data} = await ServiceApi.getInstance().GET(
				`/v2/payment/retailer`,
				{
					params: {
						payment_id: PaymentId,
					},
				},
			)
			if (status !== 200)
				throw new Error(
					data?.message ?? 'something went wrong while fetching payment details.',
				)
			return data.data
		} catch (err: any) {
			return thunkAPI.rejectWithValue(err.message)
		}
	},
)
export const fetchGstPaymentDetail = createAsyncThunk(
	'v2/payment/retailer/gst',
	async (PaymentId: any, thunkAPI) => {
		try {
			const {status, data} = await ServiceApi.getInstance().GET(
				`/v1/payment/retailer/gst_details/${PaymentId}`,
			)
			if (status !== 200)
				throw new Error(
					data?.message ?? 'something went wrong while fetching payment details.',
				)
			return data
		} catch (err: any) {
			return thunkAPI.rejectWithValue(err.message)
		}
	},
)
export const fetchTransportPaymentDetail = createAsyncThunk(
	'/payment/transport',
	async (PaymentId: any, thunkAPI) => {
		try {
			const {status, data} = await ServiceApi.getInstance().GET(
				`/v1/payment/transporter/${PaymentId}`,
			)
			if (status !== 200)
				throw new Error(
					data?.message ?? 'something went wrong while fetching payment details.',
				)
			return data.data
		} catch (err: any) {
			return thunkAPI.rejectWithValue(err.message)
		}
	},
)

export const submitUTR = createAsyncThunk(
	'/payment/submit-utr',
	async (utrData: UtrData, thunkAPI) => {
		try {
			const {status, data} = await ServiceApi.getInstance().POST(
				`/v1/payment/utr_number`,
				{
					id: utrData.id,
					utrNumber: utrData.utr,
					status: '1',
				},
			)
			if (status !== 200) throw new Error(data.message ?? 'unable to submit utr.')
			return {utr: utrData?.utr, index: utrData?.index}
		} catch (err: any) {
			return thunkAPI.rejectWithValue(err.message)
		}
	},
)

export const submitBankDetails = createAsyncThunk(
	'/payment/banking',
	async (bankingData: any, thunkAPI) => {
		try {
			const index = bankingData?.index
			const finalPart1Payment = bankingData?.finalPart1Payment
			delete bankingData.index
			delete bankingData?.finalPart1Payment
			const {status, data} = await ServiceApi.getInstance().POST(
				'/v2/payment/bank_and_tax_details',
				bankingData,
			)

			if (status !== 200)
				throw new Error(data.message ?? 'unable to submit payment details.')
			return {
				...data,
				index,
				is_bank_information_available: true,
				finalPart1Payment: finalPart1Payment,
			}
		} catch (err: any) {
			return thunkAPI.rejectWithValue(err.message)
		}
	},
)
export const submitTranspoterBankDetails = createAsyncThunk(
	'/payment/transporter/banking',
	async (bankingData: BankingData, thunkAPI) => {
		try {
			const {status, data} = await ServiceApi.getInstance().POST(
				'/v1/payment/banking_details',
				{
					id: bankingData?.id,
					accountNumber: bankingData?.accountNumber,
					ifscCode: bankingData?.ifscCode,
					accountHolderName: bankingData?.accountHolderName,
					bankName: bankingData?.bankName,
				},
			)

			if (status !== 200)
				throw new Error(data.message ?? 'unable to submit payment details.')
			return {
				index: bankingData?.index,
				isBankInformationAvailable: true,
			}
		} catch (err: any) {
			return thunkAPI.rejectWithValue(err.message)
		}
	},
)

export const getPaymentBankFormat = createAsyncThunk(
	'/payment/format',
	async (bankData: any, thunkAPI) => {
		try {
			const {status, data} = await ServiceApi.getInstance().POST(
				'/v1/payment/generate_bank_payment_format',
				bankData,
			)
			if (status !== 200 || !data?.status)
				throw new Error(
					data.message ?? 'something went wrong while submitting payment format.',
				)
			return data.data
		} catch (err: any) {
			return thunkAPI.rejectWithValue(err.message)
		}
	},
)

export const getAllMandiTaxResults = createAsyncThunk(
	'/v2/mandi_taxation',
	async (
		queryData: {limit: number; page: number; search_param: string | null},
		thunkAPI,
	) => {
		try {
			const {status, data} = await ServiceApi.getInstance().GET(
				`/v2/mandi_taxation/`,
				{
					params: {
						...queryData,
					},
				},
			)
			if (status !== 200)
				throw new Error(data.message ?? 'unable to get mandi tax results.')
			return data
		} catch (err: any) {
			// @ts-ignore
			return thunkAPI.rejectWithValue(
				'Api error: unable to get mandi tax results.',
			)
		}
	},
)

export const updateMandiTaxRecord = createAsyncThunk(
	'/v1/updateMandiTaxRecord',
	async (body, thunkAPI) => {
		try {
			const {status, data} = await ServiceApi.getInstance().POST(
				`/v1/mandi_taxation/update`,
				body,
			)
			if (status !== 200)
				throw new Error(data.message ?? 'unable to get mandi tax results.')
			return data
		} catch (err: any) {
			// @ts-ignore
			return thunkAPI.rejectWithValue(
				'Api error: unable to get mandi tax results.',
			)
		}
	},
)

export const fetchAllInvoicingRecords = createAsyncThunk(
	'/v2/po_so_invoicing',
	async (queryData: any, thunkAPI) => {
		try {
			const {status, data} = await ServiceApi.getInstance().GET(
				`/v3/payment/invoices`,
				{
					params: queryData,
				},
			)
			if (status !== 200)
				throw new Error(data.message ?? 'unable to get invoicing results.')
			return data
		} catch (err: any) {
			// @ts-ignore
			return thunkAPI.rejectWithValue(
				'Api error: unable to get invoicing results.',
			)
		}
	},
)
export const getCurrencyList = createAsyncThunk(
	'invoicing/currency-list',
	async (body, thunkAPI) => {
		try {
			const {status, data} = await ServiceApi.getInstance().GET(
				`/v1/po_so_invoicing/currency_enum`,
			)
			if (status !== 200)
				throw new Error(data.message ?? 'unable to upload document details.')

			return data
		} catch (err: any) {
			// @ts-ignore
			return thunkAPI.rejectWithValue(
				'Api error: unable to upload document details.',
			)
		}
	},
)
export const uploadDocument = createAsyncThunk(
	'/v1/po_so_invoicing/upload_doc',
	async (body, thunkAPI) => {
		try {
			const {status, data} = await ServiceApi.getInstance().POST(
				`/v1/po_so_invoicing/upload_doc`,
				body,
			)
			if (status !== 200)
				throw new Error(data.message ?? 'unable to upload document details.')
			return data
		} catch (err: any) {
			// @ts-ignore
			return thunkAPI.rejectWithValue(
				'Api error: unable to upload document details.',
			)
		}
	},
)

export const markAsComplete = createAsyncThunk(
	'/v1/po_so_invoicing/update_status',
	async (body, thunkAPI) => {
		try {
			const {status, data} = await ServiceApi.getInstance().POST(
				`/v1/po_so_invoicing/update_status`,
				body,
			)
			if (status !== 200) throw new Error(data.message ?? 'something went wrong.')
			return data
		} catch (err: any) {
			// @ts-ignore
			return thunkAPI.rejectWithValue('Api error: something went wrong.')
		}
	},
)

export const downloadAdditionalPaymentsFormat = createAsyncThunk(
	'payment/download-additional-payment-format',
	async (_, thunkAPI) => {
		try {
			const {status, data} = await ServiceApi.getInstance().GET(
				'/v2/payment/additional_payment/format',
			)
			if (status !== 200) throw new Error(data.message ?? 'Something went wrong')

			return data.data.additional_payment_format
		} catch (err: any) {
			return thunkAPI.rejectWithValue(err.message)
		}
	},
)

export const uploadAdditionalPayment = createAsyncThunk(
	'payment/upload-additional-payment',
	async (body: any, thunkAPI) => {
		try {
			const {status, data} = await ServiceApi.getInstance().POST(
				'v2/payment/additional_payment',
				body,
			)
			if (status !== 200) throw new Error(data.message ?? 'Something went wrong')

			return data.data.additional_payment_format
		} catch (err: any) {
			return thunkAPI.rejectWithValue(err.message)
		}
	},
)

export const getAdditionalPaymentDetails = createAsyncThunk(
	'payment/getAdditionalPaymentDetails',
	async (payment_id: any, thunkAPI) => {
		try {
			const {status, data} = await ServiceApi.getInstance().GET(
				'v2/payment/additional_payment/history',
				{
					params: {
						payment_id: payment_id,
					},
				},
			)
			if (status !== 200) throw new Error(data.message ?? 'Something went wrong')

			return data.data.payment_detail
		} catch (err: any) {
			return thunkAPI.rejectWithValue(err.message)
		}
	},
)

export const denyAdditionalPayment = createAsyncThunk(
	'/payment/denyAdditionalPayment',
	async (payment_id: any, thunkAPI) => {
		try {
			const {status, data} = await ServiceApi.getInstance().GET(
				`/v2/payment/decline`,
				{
					params: {
						payment_id: payment_id,
					},
				},
			)
			if (status !== 200) throw new Error(data.message ?? 'unable to decline.')

			return data
		} catch (err: any) {
			return thunkAPI.rejectWithValue(err.message)
		}
	},
)
