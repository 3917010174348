import { ApiCaller } from "../../../../../component/apiRedux/apiCaller"
import { FarmartResponse } from "../../../../../redux/thunk/reduxApis/states.api"

export interface EditRetailerPaymentDetailsPayload {
	po_id: number
	gross_weight: number
	net_weight: number
	bag_deduction: number
	number_of_bags: number
	mandi_tax: number
	weight_slip_url: string
	primary_9r_url: string
	first_9r_gate_pass_url: string
}

export const editRetailerChargesApi = ApiCaller<
	FarmartResponse<any>,
	any,
	EditRetailerPaymentDetailsPayload,
	any
>('editTransportCharges', {
	url: 'v4/payment/weight_details',
	type: 'POST',
})
