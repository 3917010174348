import {createSlice} from '@reduxjs/toolkit'
import {
	getAllQcCheckList,
	getAllDocAtDispatch,
} from '../../thunk/procurementthunk/thunk.dropdown'

const initialState: any = {
	allQcChecks: null,
	isQcfetching: false,
	allDocumentAtDispatch: null,
	isDocumentFetching: false,
}

export const sliceProcurementDropdown = createSlice({
	name: 'dropdown',
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder.addCase(getAllQcCheckList.rejected, state => {
			state.allQcChecks = null
			state.isQcfetching = false
		})
		builder.addCase(getAllQcCheckList.pending, state => {
			state.isQcfetching = true
		})
		builder.addCase(getAllQcCheckList.fulfilled, (state, action) => {
			state.allQcChecks = action.payload
			state.isQcfetching = false
		})
		builder.addCase(getAllDocAtDispatch.rejected, state => {
			state.allDocumentAtDispatch = null
			state.isDocumentFetching = false
		})
		builder.addCase(getAllDocAtDispatch.pending, state => {
			state.isDocumentFetching = true
		})
		builder.addCase(getAllDocAtDispatch.fulfilled, (state, action) => {
			const payload = action.payload.dispatchDocData ?? []
			const newDocuments = payload.filter(
				(item: any) => item.id !== 1 && item.id !== 2,
			)
			state.allDocumentAtDispatch = newDocuments
			state.isDocumentFetching = false
		})
	},
})

export const dropdownAction = {
	getAllQcCheckList,
	getAllDocAtDispatch,
	...sliceProcurementDropdown.actions,
}

export default sliceProcurementDropdown.reducer
