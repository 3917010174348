import {addDeductionApi} from './addDeduction.api'
import {deletedDeductionApi} from './deleteDeduction.api'
import { editRetailerChargesApi } from './editRetailerDetails.api'
import {editTdsApi} from './editTds.api'
import {editTransportChargesApi} from './editTransportCharges.api'
import {initPaymentDetailsApi} from './initPayment.api'
import {rejectionReasonApi} from './rejectionReason.api'

export const paymentTrenchesApi = [
	rejectionReasonApi,
	addDeductionApi,
	initPaymentDetailsApi,
	deletedDeductionApi,
	editTdsApi,
	editTransportChargesApi,
	editRetailerChargesApi
]

export * from './rejectionReason.api'
export * from './addDeduction.api'
export * from './initPayment.api'
export * from './deleteDeduction.api'
export * from './editTds.api'
export * from './editTransportCharges.api'
export * from './editRetailerDetails.api'