import Drawer from '@mui/material/Drawer';
import { styled } from '@mui/material/styles';
import React, { useEffect, useState } from "react";
import { useAppDispatch } from '../../redux';
import ImageGallery, { ImageGalleryProps } from './imageGallery';

const drawerWidth = 380;
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }: any) => ({
  marginRight: 0,
  width: `calc(100vw - ${drawerWidth}px)`,
  height: '100vh',
  overflow: 'hidden',
  zIndex: 1000,
  top: 0,
  left: 0,
  position: 'fixed'
}));

export interface ImagePreviewProps<T> extends Omit<ImageGalleryProps<T>, 'onClose'> {
    children?: JSX.Element[] | JSX.Element;
    hideDrawer: boolean;
    show: boolean;
	onHide: () => void;
}

function ImagePreview<T>(props: ImagePreviewProps<T>) {
  const { children, hideDrawer, onHide }  = props; 
  const [show, setShow] = useState<boolean>(false);


  useEffect(() => {
    setShow(props.show);
  }, [props.show]);

  const onClose = () => {
    setShow(false);
	onHide();
  }

  return (
			<>
				{show &&
					(hideDrawer ? (
						<div
							style={{
								width: '100vw',
								height: '100vh',
								overflow: 'hidden',
								/*to fix mui drawer whose zIndex is 1200 
								zIndex: 1300,` is indicating that the `zIndex` property is being set to `1300` in
								order to fix an issue of Overlapping. */
								zIndex: 1300,
								top: 0,
								left: 0,
								position: 'fixed',
							}}
						>
							<ImageGallery<T> {...props} onClose={onClose}/>
						</div>
					) : (
						<>
							<Drawer
								sx={{
									width: drawerWidth,
									flexShrink: 0,
									'& .MuiDrawer-paper': {
										width: drawerWidth,
										boxSizing: 'border-box',
									},
								}}
								variant='persistent'
								anchor='right'
								open={true}
							>
								{children}
							</Drawer>
							<Main>
								<ImageGallery<T> {...props} onClose={onClose} />
							</Main>
						</>
					))}
			</>
		)
}

export default ImagePreview;
