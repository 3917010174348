import { createSlice } from '@reduxjs/toolkit';
const screenRecordSlice = createSlice({
  name: 'screenRecord',
  initialState: {
    screenId: null,
    recordId: null,
    platformId: 'a5854444-6a52-4ce8-b232-9fb5bc750735'
  },
  reducers: {
    setScreenAndRecord: (state, action) => {
      const { screenId, recordId } = action.payload;
      state.screenId = screenId;
      state.recordId = recordId;

      console.log("i am called with val", screenId, recordId)
    },
  },
});

export const { setScreenAndRecord } = screenRecordSlice.actions;
export default screenRecordSlice.reducer;
