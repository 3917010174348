import {ApiCaller} from '../../../../component/apiRedux/apiCaller'
import { FarmartResponse } from '../../../../redux/thunk/reduxApis/states.api'
import {BuyerStatusRowResponse, BuyerTypeListResponse, StateDistrictFromPinCode} from '../types/editBuyer.types'

export const fetchBuyerTypeListApi = ApiCaller<
	FarmartResponse<BuyerTypeListResponse>
>('fetchBuyerTypeListApi', {
	type: 'GET',
	url: '/v4/buyer/types',
})

export const fetchBuyerStatus  = ApiCaller<FarmartResponse<BuyerStatusRowResponse>>('FetchBuyerStatus', {
	type: 'GET',
	url: '/v4/buyer/status_list'
})

export const updateBuyerApi = ApiCaller<FarmartResponse<any>>("updateBuyerApi", {
	type: 'PATCH',
	url: '/v4/buyer'
});

export const pushtToZohoApi = ApiCaller<FarmartResponse<any>>("pushtToZohoApi", {
	type: 'POST',
	url: '/v1/zoho/post_buyer'
})


export const getStateDistrictFromPinCode = ApiCaller<FarmartResponse<StateDistrictFromPinCode>>(
	'get_states_districts_from_pincode',
	{
		type: 'GET',
		url: 'v1/location/detail',
	},
)