import { createSlice, current } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'

import {
	createCampaign,
	createCampaignBanner,
	fetchAllLeadsStatusEnums,
	fetchCsvFormatForCampaign,
	getAllPa,
	getCampaign,
	getCampaignDistrict,
	getCampaignStates,
	getCropList,
	getCustomRetailerList,
	getDistricts,
	getPendingLeads,
	getStates,
	getUserLeads,
	stopCampaign,
	submitAction,
	uploadRetailerList,
} from '../thunk/thunk.campaign'

const initialState: any = {
	campaignData: [],
	totalRecords: 0,
	fetchCampaign: false,
	fetchingLeadsEnums: false,
	hasError: {},
	errorMessage: '',
	crops: [],
	cropsObject: {},
	states: [],
	stateObject: {},
	districts: [],
	districtObject: {},
	bannerLoading: false,
	uploading: false,
	bannerUrl: '',
	currentCampaignSelected: '',
	leadsStatusEnums: [],
	pendingLeads: [],
	paData: [],
	stats: {},
	total_pending_leads_count: '',
	actions: [],
	usersRequest: [],
	reset: 0,
}

export const sliceCampaign = createSlice({
	name: 'campaign',
	initialState,
	reducers: {
		setCampaign: (state, payload: {type: string; payload: any}) => {
			state.campaignData = payload.payload
		},
		selectedCampaign: (state, payload: {type: string; payload: any}) => {
			state.currentCampaignSelected = payload.payload
		},
		showError: (state, payload: {type: string; payload: any}) => {
			state.hasError = payload.payload
		},
		emptyLeads: (state, payload: {type: string; payload: any}) => {
			state.pendingLeads = []
		},
		emptyUserRequest: state => {
			state.usersRequest = []
		},
		emptyDistrict: state => {
			state.districts = []
		},
	},
	extraReducers: builder => {
		builder.addCase(getCampaign.pending, state => {
			state.fetchCampaign = true
		})
		builder.addCase(getCampaign.fulfilled, (state, action) => {
			state.fetchCampaign = false
			state.campaignData = action.payload.data.campaigns
			state.totalRecords = action.payload.data.total_records
		})
		builder.addCase(getCampaign.rejected, (state, action) => {
			;(state.fetchCampaign = false),
				(state.hasError = {
					type: 'error',
					message: action.payload as string,
					open: true,
				})
		})
		builder.addCase(getCustomRetailerList.rejected, () => {})
		builder.addCase(getCustomRetailerList.pending, () => {})
		builder.addCase(getCustomRetailerList.fulfilled, () => {})
		builder.addCase(uploadRetailerList.rejected, state => {
			state.uploading = false
			toast('Something went wrong', {type: 'error'})
		})
		builder.addCase(uploadRetailerList.pending, state => {
			state.uploading = true
		})
		builder.addCase(uploadRetailerList.fulfilled, state => {
			state.uploading = false
			toast('List Uploaded Successfully', {type: 'success'})
		})
		builder.addCase(getCropList.fulfilled, (state, action) => {
			const data = convertObjectLabelValue(action.payload.data.crops, {
				name: 'name',
				id: 'crop_id',
			})
			state.crops = data
			state.cropsObject = convertArrayIntoObject(data, 'id')
		})
		builder.addCase(getCropList.rejected, (state, action) => {
			state.hasError = {
				type: 'error',
				message: action.payload as string,
				open: true,
			}
		})
		builder.addCase(getCampaignStates.fulfilled, (state, action) => {
			const data = convertObjectLabelValue(action.payload.data.state, {
				name: 'name',
				id: 'id',
			})
			state.states = data
			state.stateObject = convertArrayIntoObject(data, 'id')
		})
		builder.addCase(getCampaignStates.rejected, (state, action) => {
			state.hasError = {
				type: 'error',
				message: action.payload as string,
				open: true,
			}
		})
		builder.addCase(getStates.fulfilled, (state, action) => {
			const data = convertObjectLabelValue(action.payload.data.state, {
				name: 'name',
				id: 'id',
			})
			state.states = data
			state.stateObject = convertArrayIntoObject(data, 'id')
		})
		builder.addCase(getStates.rejected, (state, action) => {
			state.hasError = {
				type: 'error',
				message: action.payload as string,
				open: true,
			}
		})
		builder.addCase(getDistricts.fulfilled, (state, action) => {
			const data = convertObjectLabelValue(action.payload.data.district, {
				name: 'name',
				id: 'id',
			})
			state.districts = data
			state.districtObject = convertArrayIntoObject(data, 'name')
		})
		builder.addCase(getDistricts.rejected, (state, action) => {
			state.hasError = {
				type: 'error',
				message: action.payload as string,
				open: true,
			}
		})
		builder.addCase(getCampaignDistrict.fulfilled, (state, action) => {
			const data = convertObjectLabelValue(action.payload.data.district, {
				name: 'name',
				id: 'id',
			})
			state.districts = data
			state.districtObject = convertArrayIntoObject(data, 'name')
		})

		builder.addCase(getCampaignDistrict.rejected, (state, action) => {
			state.hasError = {
				type: 'error',
				message: action.payload as string,
				open: true,
			}
		})

		builder.addCase(createCampaignBanner.pending, state => {
			state.bannerLoading = true
		})
		builder.addCase(createCampaignBanner.fulfilled, (state, action) => {
			state.bannerLoading = false
			state.bannerUrl = action.payload.data.bannerImageUrl
		})
		builder.addCase(createCampaignBanner.rejected, (state, action) => {
			;(state.bannerLoading = false),
				(state.hasError = {
					type: 'error',
					message: action.payload as string,
					open: true,
				})
		})
		builder.addCase(createCampaign.pending, state => {
			state.bannerLoading = true
		})
		builder.addCase(createCampaign.fulfilled, (state, action) => {
			state.bannerLoading = false
			state.hasError = {
				type: 'success',
				message: 'Campaign created successfully!',
				open: true,
			}
		})
		builder.addCase(createCampaign.rejected, (state, action) => {
			;(state.bannerLoading = false),
				(state.hasError = {
					type: 'error',
					message: action.payload as string,
					open: true,
				})
		})
		builder.addCase(stopCampaign.pending, state => {
			state.bannerLoading = true
		})
		builder.addCase(stopCampaign.fulfilled, (state, action) => {
			state.bannerLoading = false
		})
		builder.addCase(stopCampaign.rejected, (state, action) => {
			state.bannerLoading = false
			state.hasError = {
				type: 'error',
				message: action.payload as string,
				open: true,
			}
		})
		builder.addCase(fetchAllLeadsStatusEnums.pending, state => {
			state.fetchingLeadsEnums = true
		})
		builder.addCase(fetchAllLeadsStatusEnums.rejected, (state, action) => {
			state.fetchingLeadsEnums = false
			state.hasError = {
				type: 'error',
				message: action.payload as string,
				open: true,
			}
		})
		builder.addCase(fetchAllLeadsStatusEnums.fulfilled, (state, action) => {
			state.fetchingLeadsEnums = false
			state.leadsStatusEnums = action.payload
		})
		builder.addCase(getPendingLeads.pending, state => {
			state.fetchCampaign = true
		})
		builder.addCase(getPendingLeads.fulfilled, (state, action) => {
			state.fetchCampaign = false
			const {
				number_of_assigned_leads,
				number_of_pending_leads,
				number_of_rejected_leads,
				campaign_leads,
			} = action.payload.data.campaign_lead_data
			state.stats = {
				number_of_assigned_leads,
				number_of_pending_leads,
				number_of_rejected_leads,
			}
			state.total_pending_leads_count = campaign_leads.count

			state.pendingLeads = campaign_leads.rows
		})
		builder.addCase(getPendingLeads.rejected, (state, action) => {
			state.fetchCampaign = false
			state.hasError = {
				type: 'error',
				message: action.payload as string,
				open: true,
			}
		})
		builder.addCase(getAllPa.pending, state => {
			state.fetchCampaign = true
		})
		builder.addCase(getAllPa.fulfilled, (state, action) => {
			state.fetchCampaign = false

			const data = convertObjectLabelPaData(action.payload.data.pa_data, {
				name: 'name',
				id: '_id',
				mobile_number:'mobile_number'
			})
			state.paData = data
		})
		builder.addCase(getAllPa.rejected, (state, action) => {
			state.fetchCampaign = false
			state.hasError = {
				type: 'error',
				message: action.payload as string,
				open: true,
			}
		})
		builder.addCase(getUserLeads.pending, state => {
			state.fetchCampaign = true
		})
		builder.addCase(getUserLeads.fulfilled, (state, action) => {
			state.fetchCampaign = false

			const {campaign_leads} = action.payload.data.campaign_lead_data

			state.usersRequest = campaign_leads.rows.map(
				(record: any, index: number) => ({...record, index}),
			)
		})
		builder.addCase(getUserLeads.rejected, (state, action) => {
			state.fetchCampaign = false
			state.hasError = {
				type: 'error',
				message: action.payload as string,
				open: true,
			}
		})

		builder.addCase(submitAction.pending, state => {
			state.fetchCampaign = true
		})
		builder.addCase(submitAction.fulfilled, (state, action) => {
			state.fetchCampaign = false
			state.pendingLeads = []
			state.reset = current(state).reset + 1
			 state.hasError = {
				type: "success",
				message: action.payload.message as string,
				open: true,
			} 
		})
		builder.addCase(submitAction.rejected, (state, action) => {
			state.fetchCampaign = false
			state.hasError = {
				type: 'error',
				message: action.payload as string,
				open: true,
			}
		})
	},
})

export const sliceCampaignAction = {
	getCampaign,
	getCropList,
	getStates,
	getCampaignStates,
	getDistricts,
	getCampaignDistrict,
	fetchAllLeadsStatusEnums,
	createCampaignBanner,
	getCustomRetailerList,
	uploadRetailerList,
	createCampaign,
	stopCampaign,
	getPendingLeads,
	getAllPa,
	getUserLeads,
	fetchCsvFormatForCampaign,
	submitAction,
	...sliceCampaign.actions,
}

export default sliceCampaign.reducer

function convertObjectLabelValue(data: any, sampleObject: any) {
	return data.map((value: any) => {
		return {
			name: value[sampleObject.name],
			id: value[sampleObject.id],
		}
	})
}
function convertObjectLabelPaData(data: any, sampleObject: any) {
	return data.map((value: any) => {
		return {
			name: value[sampleObject.name],
			id: value[sampleObject.id],
			mobile_number:value[sampleObject.mobile_number]
		}
	})
}

function convertArrayIntoObject(data: any, keyType: string) {
	const newData: any = {}
	data.map((value: any) => {
		newData[value[keyType]] = value
	})
	return newData
}
