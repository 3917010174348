import {createAsyncThunk} from '@reduxjs/toolkit'
import ServiceApi from '../../service/service.api'
import {SapleListBody} from '../types/types.sample'

export const fetchSapleList = createAsyncThunk(
	'sample/data',
	async (body: any, thunkAPI) => {
		try {
			const {status, data} = await ServiceApi.getInstance().GET(
				'/v4/master_sample/list',
				{
					params: {
						...body.bodyObject,
					},
				},
			)
			if (status && status !== 200)
				throw new Error(data?.message ?? 'something went wrong : 503')

			return data?.data
		} catch (err: any) {
			return thunkAPI.rejectWithValue(err.message)
		}
	},
)
interface IQcReportBody {
	master_sample_id: number
	purchase_order_id?: number
	crop_variety_id: number
}
export const fetchQcReport = createAsyncThunk(
	'sample/qc_detail',
	async (body: IQcReportBody, thunkAPI) => {
		try {
			const {status, data} = await ServiceApi.getInstance().GET(
				'v3/master_sample/qc_detail',
				{
					params: {
						...body,
					},
				},
			)
			if (status !== 200)
				throw new Error(data?.message ?? 'something went wrong : 504')

			return data?.data
		} catch (err: any) {
			return thunkAPI.rejectWithValue(err.message)
		}
	},
)
interface ISampleDetailBody {
	lot_id: number
}
export const fetchSampleDetails = createAsyncThunk(
	'sample/details',
	async (body: ISampleDetailBody, thunkAPI) => {
		try {
			const {status, data} = await ServiceApi.getInstance().GET(
				'v3/master_sample/detail',
				{
					params: {
						...body,
					},
				},
			)
			if (status !== 200)
				throw new Error(data?.message ?? 'something went wrong : 505')

			return data?.data
		} catch (err: any) {
			return thunkAPI.rejectWithValue(err.message)
		}
	},
)
