import {createAsyncThunk} from '@reduxjs/toolkit'
import ServiceApi from '../../service/service.api'
import download from 'downloadjs'

export const getCampaign = createAsyncThunk(
	'campaign/reports',
	async (bodyRequest: any, thunkAPI) => {
		try {
			const {status, data} = await ServiceApi.getInstance().GET('/v2/campaign', {
				params: {
					...bodyRequest,
				},
			})
			if (status !== 200) throw new Error(data.message ?? 'Something went wrong')
			return data
		} catch (err: any) {
			return thunkAPI.rejectWithValue(err.message)
		}
	},
)

export const getStates = createAsyncThunk(
	'leads/state',
	async (bodyRequest: any, thunkAPI) => {
		try {
			const {status, data} = await ServiceApi.getInstance().GET(
				'/v1/location/states',
				{
					params: {
						...bodyRequest,
					},
				},
			)
			if (status !== 200) throw new Error(data.message ?? 'Something went wrong')
			return data
		} catch (err: any) {
			return thunkAPI.rejectWithValue(err.message)
		}
	},
)
export const getCampaignStates = createAsyncThunk(
	'campaign/state',
	async (bodyRequest: any, thunkAPI) => {
		try {
			const {status, data} = await ServiceApi.getInstance().GET(
				'/v1/location/states',
			)
			if (status !== 200) throw new Error(data.message ?? 'Something went wrong')
			return data
		} catch (err: any) {
			return thunkAPI.rejectWithValue(err.message)
		}
	},
)

export const getPendingLeads = createAsyncThunk(
	'campaign/pending_leads',
	async (bodyRequest: any, thunkAPI) => {
		try {
			const {status, data} = await ServiceApi.getInstance().POST(
				'/v2/campaign/lead/list',
				bodyRequest,
			)

			if (status !== 200) throw new Error(data.message ?? 'Something went wrong')
			return data
		} catch (err: any) {
			return thunkAPI.rejectWithValue(err.message)
		}
	},
)

export const fetchAllLeadsStatusEnums = createAsyncThunk(
	'campaign/leads_enums_status',
	async (_, thunkAPI) => {
		try {
			const {status, data} = await ServiceApi.getInstance().GET(
				'v2/master_sample/get_lead_status',
			)

			if (status !== 200) throw new Error(data.message ?? 'Something went wrong')
			return data.data?.actions
		} catch (err: any) {
			return thunkAPI.rejectWithValue(err.message)
		}
	},
)

export const getUserLeads = createAsyncThunk(
	'campaign/user_leads',
	async (bodyRequest: any, thunkAPI) => {
		try {
			const {status, data} = await ServiceApi.getInstance().POST(
				'/v2/campaign/lead/list',
				bodyRequest,
			)

			if (status !== 200) throw new Error(data.message ?? 'Something went wrong')
			return data
		} catch (err: any) {
			return thunkAPI.rejectWithValue(err.message)
		}
	},
)

export const submitAction = createAsyncThunk(
	'campaign/submit_actions',
	async (bodyRequest: any, thunkAPI) => {
		try {
			const {status, data} = await ServiceApi.getInstance().POST(
				'/v2/campaign/lead/action_on_lead',
				bodyRequest,
			)
			if (status !== 200) throw new Error(data.message ?? 'Something went wrong')
			return data
		} catch (err: any) {
			return thunkAPI.rejectWithValue(err.message)
		}
	},
)

export const getAllPa = createAsyncThunk(
	'campaign/pa_list',
	async (bodyRequest: any, thunkAPI) => {
		try {
			const {status, data} = await ServiceApi.getInstance().GET(
				'v1/campaign/fetch_all_pa',
				{
					params: {
						...bodyRequest,
					},
				},
			)
			if (status !== 200) throw new Error(data.message ?? 'Something went wrong')
			return data
		} catch (err: any) {
			return thunkAPI.rejectWithValue(err.message)
		}
	},
)
/* need to remove */
export const getDistricts = createAsyncThunk(
	'leads/districts',
	async (bodyRequest: any, thunkAPI) => {
		try {
			const {status, data} = await ServiceApi.getInstance().GET(
				'/v1/location/districts',
				{
					params: {
						...bodyRequest,
					},
				},
			)
			if (status !== 200) throw new Error(data.message ?? 'Something went wrong')
			return data
		} catch (err: any) {
			return thunkAPI.rejectWithValue(err.message)
		}
	},
)
export const getCampaignDistrict = createAsyncThunk(
	'campaign/districts',
	async (bodyRequest: any, thunkAPI) => {
		try {
			const {status, data} = await ServiceApi.getInstance().GET(
				'/v1/location/districts',
				{
					params: {
						...bodyRequest,
					},
				},
			)
			if (status !== 200) throw new Error(data.message ?? 'Something went wrong')
			return data
		} catch (err: any) {
			return thunkAPI.rejectWithValue(err.message)
		}
	},
)
export const getCropList = createAsyncThunk(
	'campaign/crops',
	async (_, thunkAPI) => {
		try {
			const {status, data} = await ServiceApi.getInstance().GET(
				'/v1/campaign/crops',
			)
			if (status !== 200) throw new Error(data.message ?? 'Something went wrong')
			return data
		} catch (err: any) {
			return thunkAPI.rejectWithValue(err.message)
		}
	},
)

export const createCampaign = createAsyncThunk(
	'campaign/record',
	async (bodyRequest: any, thunkAPI) => {
		try {
			const {status, data} = await ServiceApi.getInstance().POST(
				'/v2/campaign/',
				bodyRequest,
			)
			if (status !== 200) throw new Error(data.message ?? 'Something went wrong')
			return data
		} catch (err: any) {
			return thunkAPI.rejectWithValue(err.message)
		}
	},
)
export const createCampaignBanner = createAsyncThunk(
	'campaign/banner',
	async (bodyRequest: any, thunkAPI) => {
		try {
			const {status, data} = await ServiceApi.getInstance().POST(
				'/v1/campaign/banner',
				bodyRequest,
			)
			if (status !== 200) throw new Error(data.message ?? 'Something went wrong')
			return data
		} catch (err: any) {
			return thunkAPI.rejectWithValue(err.message)
		}
	},
)
export const getCustomRetailerList = createAsyncThunk(
	'campaign/download/retailer',
	async (_, thunkAPI) => {
		try {
			const {status, data} = await ServiceApi.getInstance().GET(
				'/v1/procurement_sources/procurement/download',
			)
			if (status !== 200) throw new Error(data.message ?? 'Something went wrong')
			download(data, 'Custom-Retailer-List.csv')
			return data
		} catch (err: any) {
			return thunkAPI.rejectWithValue(err.message)
		}
	},
)

export const uploadRetailerList = createAsyncThunk(
	'campaign/upload/retailer',
	async (retailerData: any, thunkAPI) => {
		try {
			const {status, data} = await ServiceApi.getInstance().POST(
				'asdjkh',
				retailerData,
			)
			if (status !== 200) throw new Error(data.message ?? 'Something went wrong')
			return data
		} catch (error: any) {
			return thunkAPI.rejectWithValue(error.message)
		}
	},
)
export const stopCampaign = createAsyncThunk(
	'campaign/stop',
	async (bodyRequest: any, thunkAPI) => {
		try {
			const {status, data} = await ServiceApi.getInstance().POST(
				'/v2/campaign/force_shut',
				bodyRequest,
			)
			if (status !== 200) throw new Error(data.message ?? 'Something went wrong')
			return data
		} catch (err: any) {
			return thunkAPI.rejectWithValue(err.message)
		}
	},
)
export const fetchCsvFormatForCampaign = createAsyncThunk(
	'campaign/csv/format',
	async (_, thunkAPI) => {
		try {
			const {status, data} = await ServiceApi.getInstance().GET(
				'/v2/campaign/custom_mobile_number_format',
			)
			if (status !== 200) throw new Error(data.message ?? 'Something went wrong')
			return data
		} catch (err: any) {
			return thunkAPI.rejectWithValue(err.message)
		}
	},
)
