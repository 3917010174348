import {createAsyncThunk} from '@reduxjs/toolkit'
import ServiceApi from '../../service/service.api'

interface INewUser {
	name: string
	mobile_number: string
	employee_id: string
	designation: string
	reports_to: string
}

export const getDesignations = createAsyncThunk(
	'users/designations',
	async (_, thunkAPI) => {
		try {
			const {status, data} = await ServiceApi.getInstance().GET(
				'/v2/user/designations',
			)
			if (status !== 200) throw new Error(data.message ?? 'Something went wrong.')
			return data
		} catch (err: any) {
			return thunkAPI.rejectWithValue(err.message)
		}
	},
)

export const getUsers = createAsyncThunk(
	'users/get',
	async (
		pageData: {page_no: number; limit: number; searchParams: string | null},
		thunkAPI,
	) => {
		try {
			const {status, data} = await ServiceApi.getInstance().GET('/v2/user', {
				params: {
					...(pageData.searchParams && {searchParam: pageData.searchParams}),
					page_no: pageData.page_no,
					limit: pageData.limit,
				},
			})
			if (status !== 200) throw new Error(data.message ?? 'Something went wrong.')
			return data
		} catch (err: any) {
			return thunkAPI.rejectWithValue(err.message)
		}
	},
)

export const getReportingManagers = createAsyncThunk(
	'users/reporting-manager',
	async (searchParams: any, thunkAPI) => {
		try {
			const {status, data} = await ServiceApi.getInstance().GET('/v2/user/rm', {
				params: {
					...searchParams,
				},
			})
			if (status !== 200) throw new Error(data.message ?? 'Something went wrong.')
			return data
		} catch (err: any) {
			return thunkAPI.rejectWithValue(err.message)
		}
	},
)
export const onUserDetailsSubmit = createAsyncThunk(
	'users/details-update-add',
	async (payload: any, thunkAPI) => {
		try {
			const {status, data} = await ServiceApi.getInstance().POST(
				'/v2/user/panel',
				payload,
			)
			if (status !== 200) throw new Error(data.message ?? 'Something went wrong.')
			return data
		} catch (err: any) {
			return thunkAPI.rejectWithValue(err.message)
		}
	},
)
export const checkMobileNumber = createAsyncThunk(
	'users/check-mobile-number',
	async (mobileNumber: string, thunkAPI) => {
		try {
			const {status, data} = await ServiceApi.getInstance().GET(
				'/v2/user/mobile_number_check',
				{
					params: {
						mobile_number: mobileNumber,
					},
				},
			)
			if (status !== 200) throw new Error(data.message ?? 'Something went wrong.')
			return data
		} catch (err: any) {
			return thunkAPI.rejectWithValue(err.message)
		}
	},
)
export const checkEmployeeId = createAsyncThunk(
	'users/check-employee-id',
	async (employeeId: string, thunkAPI) => {
		try {
			const {status, data} = await ServiceApi.getInstance().GET(
				`/v2/user/employee_id_check/${employeeId}`,///v2/user/employee_id_check/{employee_id}
			)
			if (status !== 200) throw new Error(data.message ?? 'Something went wrong.')
			return data
		} catch (err: any) {
			return thunkAPI.rejectWithValue(err.message)
		}
	},
)
export const getUserTicketToken = createAsyncThunk(
	'users/getUserTicketToken',
	async (_, thunkAPI) => {
		try {
			const {status, data} = await ServiceApi.getInstance().GET(
				`/v1/ticketing/encrypt`
			)
			if (status !== 200) throw new Error(data.message ?? 'Something went wrong.')
			return data
		} catch (err: any) {
			return thunkAPI.rejectWithValue(err.message)
		}
	},
)

