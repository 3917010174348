import {createSlice} from '@reduxjs/toolkit'
import {sendOtp, verifyOtp} from '../thunk/thunk.auth'
import {IAuthState} from '../types/type.auth'



export const getUserData = (): {token?: string; profile?: {}} => {
	const userDataStr = localStorage.getItem('user-data')
	try {
		if (userDataStr)
			return JSON.parse(userDataStr)
		else 
			return {};
	} catch(err) {
		return {};
	}
}

const initialState: IAuthState = {
	authenticated: !!getUserData().token,
	userProfile: getUserData()?.profile,
	otpSent: false,
	isSendingOtp: false,
	isVerifyingOtp: false,
	hasError: false,
	errorMessage: '',
}

export const sliceAuth = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		logout: state => {
			// do all cleanup here before logout
			getUserData().token = undefined
			getUserData().profile = undefined
			state.authenticated = false
		},
		resetErrorMessage : state => {
			state.hasError = false
		}
	},
	extraReducers: builder => {
		// send otp cases
		builder.addCase(sendOtp.pending, state => {
			state.isSendingOtp = true
		})
		builder.addCase(sendOtp.fulfilled, (state, action) => {
			state.isSendingOtp = false
			state.hasError = false
			state.otpSent = true
			state.errorMessage = ''
		})
		builder.addCase(sendOtp.rejected, (state, action) => {
			state.hasError = true
			state.errorMessage = action.payload as string
			state.isSendingOtp = false
		})
		// verify otp cases
		builder.addCase(verifyOtp.pending, state => {
			state.isVerifyingOtp = true
		})
		builder.addCase(verifyOtp.fulfilled, (state, action) => {
			const payload_data = action.payload as any
			if (payload_data.status) {
				const user_data = {
					token: payload_data.data.token.token,
					profile: {
						...payload_data.data.token.user_profile,
						id: payload_data.data.token.ml_user._id,
					},
				}
				// save token & profile data to the local-storage
				// data format: {token: String, profile: Object}
				localStorage.setItem('user-data', JSON.stringify(user_data))
				getUserData().token = payload_data.data.token.token
				getUserData().profile = {
					...payload_data.data.token.user_profile,
					id: payload_data.data.token.ml_user._id,
				}
				state.hasError = false
				state.errorMessage = ''
				state.isVerifyingOtp = false
				state.authenticated = true
				state.userProfile = {
					...payload_data.data.token.user_profile,
					id: payload_data.data.token.ml_user._id,
				}
			} else {
				state.hasError = true
				state.errorMessage = payload_data.message as string
				state.isSendingOtp = false
				state.isVerifyingOtp = false
			}
		})
		builder.addCase(verifyOtp.rejected, (state, action) => {
			state.hasError = true
			state.errorMessage = action.payload as string
			state.isVerifyingOtp = false
		})
	},
})

export const authActions = {
	sendOtp,
	verifyOtp,
	...sliceAuth.actions,
}
export default sliceAuth.reducer
