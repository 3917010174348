import {
	Close,
	Add,
	QuestionMark as DefaultIcon,
	Person,
	ChatBubbleOutline,
	NotificationsNone,
	Help,
	AccessTime,
	SupportAgent,
	Login,
	FlagOutlined,
	RemoveRedEyeRounded
} from '@mui/icons-material'
export const iconData: any = {
	close: Close,
	add: Add,
	user: Person,
	chat: ChatBubbleOutline,
	bell:NotificationsNone,
	help:Help,
	clock:AccessTime,
	support:SupportAgent,
	logout:Login,
	FlagOutlinedIcon:FlagOutlined,
	RemoveRedEyeRounded:RemoveRedEyeRounded
}

export {DefaultIcon}
