import {ApiCaller} from '../../../component/apiRedux/apiCaller'
import {FarmartResponse} from './states.api'

export interface IUserActivityResponse {
	_id: number
	user_activity_id: number
	lat: string
	long: string
	accuracy: number
	log_time: Date
	image: string
	distance_from_last_point: number
	total_distance_travelled: number
}

export const fetchUserActivityApiGet = ApiCaller<
	FarmartResponse<IUserActivityResponse[]>
>('user_activity', {
	type: 'GET',
	url: '/v1/user_tracking/activity_list',
})
export const fetchUserActivityEnumsApiGet = ApiCaller<
	FarmartResponse<{user_activity_list: {id: number; name: string}[]}>
>('user_activity_enums', {
	type: 'GET',
	url: 'v1/user_tracking/activity_types',
})
