import React from 'react'
import {iconData, DefaultIcon} from './iconData'
import {SvgIconProps} from '@mui/material'
import {type} from './types'
type IconProps = Pick<SvgIconProps, 'fontSize' | 'color'>
export interface IconAllProps extends IconProps {
	type: type
	iconStyle?: object
}
export function Icons(props: IconAllProps) {
	const {type, fontSize, color, iconStyle} = props
	const MyIcon: any = iconData[type] ?? DefaultIcon
	return <MyIcon type={type} fontSize={fontSize} color={color} sx={iconStyle} />
}

Icons.defaultProps = {
	fontSize: 'medium',
	iconStyle: {},
	color: 'black',
}
