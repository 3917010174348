import React from 'react'
import {tableColumns} from './../utils'
//Mui Imports=====================
import {DataGrid} from '@mui/x-data-grid'
import Paper from '@mui/material/Paper'
import LinearProgress from '@mui/material/LinearProgress'
import Row from '../../../../component/row'
import Tabs from '@mui/material/Tabs/Tabs'
import Tab from '@mui/material/Tab/Tab'
import {Container} from '@mui/system'
import { useAppDispatch } from '../../../../redux'
import { setScreenAndRecord } from '../../../../redux/slice/slice.ticketing'
import { ScreenEnums } from '../../../../utils/ticketing/enums/ticketingEnums'

const RejectPoTable: React.FC<any> = ({
	getAllRejectPoRec,
	rejectPoRows,
	isDataLoading,
	onPageChange,
	tablePageData,
	totalRows,
	onTableRowSelection,
}) => {
	React.useEffect(() => {
		getAllRejectPoRec()
	}, [tablePageData])
	const dispatch = useAppDispatch()
	dispatch(setScreenAndRecord({screenId:ScreenEnums.INCOMPLETE_DELIVERY_LIST_SCREEN,recordId:undefined}))
	return (
		<Paper sx={{flex: 1}}>
			<DataGrid
				pagination
				paginationMode='server'
				rows={rejectPoRows}
				rowCount={totalRows}
				pageSize={tablePageData.rowLimit}
				rowsPerPageOptions={[50]}
				columns={tableColumns}
				loading={isDataLoading}
				components={{
					LoadingOverlay: LinearProgress,
				}}
				onPageChange={onPageChange}
				onRowClick={onTableRowSelection}
			/>
		</Paper>
	)
}

export default RejectPoTable
