import { ApiCaller } from "../../../../component/apiRedux/apiCaller";
import { FarmartResponse } from "../states.api";


export interface Taluk {
	id: number;
	name: string;
}

export const talukRequestGetApi = ApiCaller<FarmartResponse<{
	taluk: Taluk[]
}>>('taluk', {
	type: 'GET',
	url: '/v1/location/taluk'
})


export const addUpdatePincodeApi = ApiCaller<FarmartResponse<any>>('add_edit_pincode', {
	url: '/v2/location/pincode',
	type: 'POST'
});

export const downloadPincodeApi = ApiCaller<FarmartResponse<{
	url: string
}>>('download_pincode', {
	url: '/v2/location/pincode/download',
	type: 'GET'
})