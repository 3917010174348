export enum ScreenEnums {
	Retailer_List_Screen = 53,
	Retailer_Detail_Screen = 54,
	ACCESS_CREATION_LIST_SCREEN = 29,
	VISIT_LIST_SCREEN = 30,
	VISIT_DETAILS_SCREEN = 31,
	SUPPLY_VISIBILITY_LIST_SCREEN = 32,
	CREATE_ORDER_LIST_SCREEN = 33,
	SALES_ORDER_TARGET_LIST_SCREEN = 35,
	CAMPAIGN_LIST_SCREEN = 38,
	LEADS_LIST_SCREEN = 39,
	BUYER_LIST_SCREEN = 40,
	SO_APPROVAL_LIST_SCREEN = 42,
	REPAYMENT_LIST_SCREEN = 44,
	DELIVERY_LIST_SCREEN = 45,
	PAYMENTS_LIST_SCREEN = 46,
	MANDI_TAX_LIST_SCREEN = 48,
	INVOICING_DOCUMENT_LIST_SCREEN = 49,
	WAREHOUSE_LIST_SCREEN = 50,
	INCOMPLETE_DELIVERY_LIST_SCREEN = 51,
	SO_APPROVAL_DETAILS_SCREEN = 43,
	SALES_ORDER_TARGET_DETAILS_SCREEN = 36,
	BUYER_DETAILS_SCREEN = 41,
	//dependencies
	CREATE_ORDER_DETAILS_SCREEN = 34,
	PAYMENTS_DETAILS_SCREEN = 47,
	// Exxcluded for now
	DROP_DOWN_LIST_SCREEN = 37,
	CONTROLLING_CONFIG_LIST_SCREEN = 52,
	VAS_LEAD_LIST_SCREEN_ENUMS = 65,
	VAS_CUSTOMER_LIST_SCREEN_ENUMS = 66,
	VAS_CUSTOMER_DETAIL_SCREEN_ENUMS = 67,
	// added all the nessacary screens as well
	INCOMPLETE_DELIVERY_DETAIL_SCREEN = 69,
	INCOMPLETE_PENDING_FOR_SECONDARY_UNLOADING_LIST_SCREEN = 68,
	INCOMPLETE_PENDING_FOR_SECONDARY_UNLOADING_DETAIL_SCREEN = 70,
	ADD_NEW_RETAILER_SCREEN=72,
	ONBOARD_WAREHOUSE_SCREEN=73,

}
