import {columns} from './utils'

import {LinearProgress} from '@mui/material'
import {DataGrid} from '@mui/x-data-grid'
import {useSecondarySalesOrder} from './hooks'
import React, {useEffect} from 'react'
import {useAppDispatch} from '../../../redux'
import {
	setSecondarySalesOrderCurrentPage,
	setSecondarySalesOrderIds,
} from '../../../redux/slice/slice.secondaryOrderUnloading'
import {useTableContext} from '../../../component/context/useTableContext'
import {withTableContext} from '../../../component/context/table.context'
import {setScreenAndRecord} from '../../../redux/slice/slice.ticketing'
import {ScreenEnums} from '../../../utils/ticketing/enums/ticketingEnums'

function SecondaryUnloadingMain() {
	const {pageNumber, setPageNumber, pageSize, searchValue} = useTableContext()
	const dispatch = useAppDispatch()

	const {isDataLoading, secondaryOrdersList, onTableRowSelection, totalRecords} =
		useSecondarySalesOrder({
			limit: pageSize,
			page: pageNumber,
			searchBy: searchValue,
			setPageNumber: setPageNumber,
		})

	useEffect(() => {
		dispatch(
			setSecondarySalesOrderIds(
				secondaryOrdersList.map(obj => obj.po_pending_inhand_id),
			),
		)
	}, [secondaryOrdersList, setSecondarySalesOrderIds, dispatch])

	const handlePageChange = React.useCallback(
		(currentPage: number) => {
			setPageNumber(currentPage)
			dispatch(setSecondarySalesOrderCurrentPage(currentPage))
		},
		[setPageNumber, dispatch, setSecondarySalesOrderCurrentPage],
	)

	dispatch(
		setScreenAndRecord({
			screenId: ScreenEnums.INCOMPLETE_PENDING_FOR_SECONDARY_UNLOADING_LIST_SCREEN,
			recordId: undefined,
		}),
	)

	return (
		<div
			style={{
				display: 'flex',
				flex: 1,
			}}
		>
			<DataGrid
				pagination
				paginationMode='server'
				disableSelectionOnClick
				rowsPerPageOptions={[30]}
				rows={secondaryOrdersList}
				columns={columns}
				loading={isDataLoading.isOrdersFechinhg}
				components={{
					LoadingOverlay: LinearProgress,
				}}
				onRowClick={onTableRowSelection}
				pageSize={pageSize}
				page={pageNumber}
				rowCount={totalRecords}
				onPageChange={e => handlePageChange(e)}
			/>
		</div>
	)
}

export default withTableContext(SecondaryUnloadingMain)
