import {createAsyncThunk} from '@reduxjs/toolkit'
import ServiceApi from '../../../../service/service.api'

export const fetchAllRejectedPoList = createAsyncThunk(
	'rejected_po/get-all-reject_po_list',
	async (pageData: {page_no: number; record_limit: number}, thunkAPI) => {
		try {
			const {status, data} = await ServiceApi.getInstance().GET(
				'/v1/po_unloading/po_pending_inhand',
				{
					params: {
						page: pageData.page_no,
						limit: pageData.record_limit,
					},
				},
			)
			if (status !== 200) throw new Error(data.message ?? 'Something went wrong')
			return data
		} catch (error: any) {
			return thunkAPI.rejectWithValue(error.message)
		}
	},
)
