import React, {Suspense, useEffect} from 'react'
import {Navigate, Route} from 'react-router-dom'
import PrivateRoute from '../../../component/route'
import Loader from '../../../component/loader'
import {useUserRolesIn} from '../../../hooks/useUserRole'

const SalesOrderDetailView = React.lazy(
	() => import('./soIndex'),
)

const  SalesOrderEditView = React.lazy(
	() => import('./index'),
)

const SalesOrderDetailPage = () => {
	return (
		<Suspense fallback={<Loader />}>
			<SalesOrderDetailView />
		</Suspense>
	)
}

const SalesOrderEditlPage = () => {
	return (
		<Suspense fallback={<Loader />}>
			<SalesOrderEditView />
		</Suspense>
	)
}

export const RenderSalesOrderDetailsRoute = () => {
	const isActionEnabled = useUserRolesIn([])
	return (
		<>
			<Route path='salesOrder/:orderId'>
				<Route
					path='details'
					element={<PrivateRoute component={SalesOrderDetailPage} />}
				></Route>
				<Route
					path='edit'
					element={
						isActionEnabled ? (
							<PrivateRoute component={SalesOrderEditlPage} />
						) : (
							<>
							<Navigate to={'/protected/sales/salesOrder'}/>
							</>
						)
					}
				></Route>
			</Route>
		</>
	)
}
