import {createSlice, current} from '@reduxjs/toolkit'
import {toast} from 'react-toastify'
import FileSaver from 'file-saver'
import {
	IPayment,
	IPaymentState,
	PaymentList,
	ICurrencyList,
} from '../types/type.payment'
import {
	fetchPaymentList,
	submitBankDetails,
	submitTranspoterBankDetails,
	submitUTR,
	getPaymentBankFormat,
	getAllMandiTaxResults,
	updateMandiTaxRecord,
	fetchAllInvoicingRecords,
	fetchGstPaymentDetail,
	getCurrencyList,
	fetchPaymentDetail,
	fetchTransportPaymentDetail,
	uploadDocument,
	markAsComplete,
	downloadAdditionalPaymentsFormat,
	uploadAdditionalPayment,
	getAdditionalPaymentDetails,
	denyAdditionalPayment,
} from '../thunk/thunk.payment'
import convertJsonToCSV from '../../utils/json_to_excel'

const initialState: IPaymentState = {
	fetchingList: false,
	updatingUTR: false,
	submittingBankFormat: false,
	submitingBankInfo: false,
	payments: [],
	fetchingMandiTaxList: false,
	mandiTaxList: [],
	total_manditax_records: 0,
	fetchingInvoiceList: false,
	total_invoice_records: 0,
	InvoiceList: [],
	currecntList: [],
	isCurrencyListFetching: false,
	isPaymentLoad: false,
	paymentList: [],
	isUploadDocumentsDetails: false,
	isMarkComplete: false,
	isDownloadingAdditionalPaymentFormat: false,
	additionalPaymentformatLink: {data: {additional_payment_format: ''}},
	uploadAdditionalPayment: {
		message: '',
		status: false,
		data: {
			file_url: '',
		},
	},
	totalPaymentRecords: 0,
	additional_payment_details: {
		data: {
			payment_detail: [],
		},
	},
	isPaymentDeclining: false,
	isAdditionalPaymentFetched: false,
	payment_decline_response: {
		status: false,
		message: '',
	},
}

export const slicePayment = createSlice({
	name: 'payment',
	initialState,
	reducers: {
		//
		setUtrField: (state, action) => {
			let copy = [...current(state).paymentList]
			copy[action.payload.index] = {
				...state.paymentList[action.payload.index],
				is_bank_information_available:
					action.payload?.is_bank_information_available,
				...(action.payload.tdsValuePart1 !== null && {
					payable_amount: action.payload.finalPart1Payment.toFixed(2),
				}),
			}
			state.paymentList = copy as PaymentList[]
			toast('Banking details submitted successfully', {type: 'success'})
		},
		setMandiTaxList: (state, payload: any) => {
			state.mandiTaxList = []
		},
	},
	extraReducers: builder => {
		builder.addCase(fetchPaymentList.pending, state => {
			state.isPaymentLoad = true
		})
		builder.addCase(fetchPaymentList.fulfilled, (state, action) => {
			state.isPaymentLoad = false
			state.paymentList = action.payload.payments
			state.totalPaymentRecords = action.payload.total_records
		})
		builder.addCase(fetchPaymentList.rejected, (state, action) => {
			state.isPaymentLoad = false
			state.totalPaymentRecords = 0
			const message = action.payload as string
			toast(message, {type: 'error'})
		})

		builder.addCase(submitUTR.pending, state => {
			state.updatingUTR = true
		})
		builder.addCase(submitUTR.fulfilled, (state, action) => {
			state.updatingUTR = false
			let copy = [...current(state).paymentList]
			copy[action.payload.index] = {
				...state.paymentList[action.payload.index],
				utr_number: action.payload?.utr,
			}
			state.paymentList = copy as PaymentList[]
			toast('UTR submitted successfully', {type: 'success'})
		})
		builder.addCase(submitUTR.rejected, (state, action) => {
			state.updatingUTR = false
			const message = action.payload as string
			toast(message, {type: 'error'})
		})

		builder.addCase(submitTranspoterBankDetails.pending, state => {
			state.submitingBankInfo = true
		})
		builder.addCase(submitTranspoterBankDetails.fulfilled, (state, action) => {
			state.submitingBankInfo = false
			let copy = [...current(state).paymentList]
			copy[action.payload.index] = {
				...state.paymentList[action.payload.index],
				is_bank_information_available: action.payload?.isBankInformationAvailable,
			}
			state.paymentList = copy as PaymentList[]
			toast('Banking details submitted successfully', {type: 'success'})
		})
		builder.addCase(submitTranspoterBankDetails.rejected, (state, action) => {
			state.submitingBankInfo = false
			const message = action.payload as string
			toast(message, {type: 'error'})
		})

		builder.addCase(getPaymentBankFormat.pending, state => {
			state.submittingBankFormat = true
		})
		builder.addCase(getPaymentBankFormat.fulfilled, (state, action) => {
			state.submittingBankFormat = false
			const data = action.payload
			const selectedBank = action.meta.arg.selected_bank ?? ''
			const today = new Date()
			// file name format: bank_ddmmyyyyhhmmss.csv
			const timestamp = `${today.getDate()}${
				today.getMonth() + 1
			}${today.getFullYear()}${today.getHours()}${today.getMinutes()}${today.getSeconds()}`
			const fileName = `${selectedBank}_${timestamp}`
			const result = convertJsonToCSV(data, fileName)
			// download the csv file
			FileSaver.saveAs(result.data, result.fileName)
		})
		builder.addCase(getPaymentBankFormat.rejected, (state, action) => {
			state.submittingBankFormat = false
			const message = action.payload as string
			toast(message, {type: 'error'})
		})
		builder.addCase(getAllMandiTaxResults.pending, state => {
			state.fetchingMandiTaxList = true
			//state.mandiTaxList = []
		})
		builder.addCase(getAllMandiTaxResults.fulfilled, (state, action) => {
			state.fetchingMandiTaxList = false
			state.mandiTaxList = action.payload?.data.mandi_tax_docs ?? []
			state.total_manditax_records = action.payload?.data?.total_records ?? 0
		})
		builder.addCase(getAllMandiTaxResults.rejected, (state, action) => {
			state.fetchingMandiTaxList = false
			const message = action.payload as string
			toast(message, {type: 'error'})
		})
		builder.addCase(updateMandiTaxRecord.fulfilled, (state, action) => {
			const mandiTaxItemIndex = state.mandiTaxList.findIndex(
				// @ts-ignore
				mandiTaxItem => mandiTaxItem.id === action.meta.arg.mandi_tax_id,
			)

			const oldMandiTaxRecord = state.mandiTaxList[mandiTaxItemIndex]

			const updateMandiTaxRecord = {
				...oldMandiTaxRecord,
				//@ts-ignore
				...action.meta.arg,
			}

			if (mandiTaxItemIndex >= 0) {
				state.mandiTaxList[mandiTaxItemIndex] = updateMandiTaxRecord

				if (updateMandiTaxRecord.status) {
					state.mandiTaxList.splice(mandiTaxItemIndex, 1)
					state.mandiTaxList.push(updateMandiTaxRecord)
				}
			}
		})
		builder.addCase(fetchAllInvoicingRecords.pending, state => {
			state.fetchingInvoiceList = true
		})
		builder.addCase(fetchAllInvoicingRecords.fulfilled, (state, action) => {
			state.fetchingInvoiceList = false
			state.InvoiceList = action.payload?.data?.rows ?? []
			state.total_invoice_records = action.payload?.data?.count ?? 0
		})
		builder.addCase(fetchAllInvoicingRecords.rejected, (state, action) => {
			state.fetchingInvoiceList = false
			const message = action.payload as string
			toast(message, {type: 'error'})
		})
		builder.addCase(getCurrencyList.rejected, (state, action) => {
			state.isCurrencyListFetching = false
			state.currecntList = []
			const message = action.payload as string
			toast(message, {type: 'error'})
		})
		builder.addCase(getCurrencyList.pending, (state, action) => {
			state.isCurrencyListFetching = true
		})
		builder.addCase(getCurrencyList.fulfilled, (state, action) => {
			state.isCurrencyListFetching = false
			state.currecntList = (action.payload.data as ICurrencyList[]) ?? []
		})
		builder.addCase(uploadDocument.pending, state => {
			state.isUploadDocumentsDetails = true
		})
		builder.addCase(uploadDocument.fulfilled, (state, action) => {
			state.isUploadDocumentsDetails = false
		})
		builder.addCase(uploadDocument.rejected, (state, action) => {
			state.isUploadDocumentsDetails = false
			const message = action.payload as string
			toast(message, {type: 'error'})
		})
		builder.addCase(markAsComplete.pending, state => {
			state.isMarkComplete = true
		})
		builder.addCase(markAsComplete.fulfilled, (state, action) => {
			state.isMarkComplete = false
			toast('Document Updated', {type: 'success'})
		})
		builder.addCase(markAsComplete.rejected, (state, action) => {
			state.isMarkComplete = false
			const message = action.payload as string
			toast(message, {type: 'error'})
		})
		builder.addCase(
			downloadAdditionalPaymentsFormat.fulfilled,
			(state, action) => {
				// state.isDownloadingAdditionalPaymentFormat = false;
				state.additionalPaymentformatLink.data.additional_payment_format =
					action.payload
			},
		)

		builder.addCase(
			downloadAdditionalPaymentsFormat.rejected,
			(state, action) => {
				state.isDownloadingAdditionalPaymentFormat = false
				const message = action.payload as string
				toast(message, {type: 'error'})
			},
		)
		builder.addCase(downloadAdditionalPaymentsFormat.pending, (state, action) => {
			state.isDownloadingAdditionalPaymentFormat = true
		})
		builder.addCase(getAdditionalPaymentDetails.fulfilled, (state, action) => {
			state.isAdditionalPaymentFetched = false
			state.additional_payment_details.data.payment_detail = action.payload
		})

		builder.addCase(getAdditionalPaymentDetails.rejected, (state, action) => {
			state.isAdditionalPaymentFetched = false
			const message = action.payload as string
			toast(message, {type: 'error'})
		})
		builder.addCase(getAdditionalPaymentDetails.pending, (state, action) => {
			// state.isDownloadingAdditionalPaymentFormat = true
			state.isAdditionalPaymentFetched = true
		})
		builder.addCase(denyAdditionalPayment.fulfilled, (state, action) => {
			state.payment_decline_response.status = action.payload.status
			state.isPaymentDeclining = false
		})
		builder.addCase(denyAdditionalPayment.pending, state => {
			state.isPaymentDeclining = true
		})
		builder.addCase(denyAdditionalPayment.rejected, (state, action) => {
			const message = action.payload as string
			toast(message, {type: 'error'})
		})
	},
})

export const paymentAction = {
	fetchPaymentList,
	submitUTR,
	submitBankDetails,
	submitTranspoterBankDetails,
	getPaymentBankFormat,
	getAllMandiTaxResults,
	updateMandiTaxRecord,
	fetchAllInvoicingRecords,
	fetchGstPaymentDetail,
	getCurrencyList,
	fetchPaymentDetail,
	fetchTransportPaymentDetail,
	uploadDocument,
	markAsComplete,
	downloadAdditionalPaymentsFormat,
	uploadAdditionalPayment,
	getAdditionalPaymentDetails,
	denyAdditionalPayment,
	...slicePayment.actions,
}

export default slicePayment.reducer
