import dayjs from 'dayjs'
import _ from 'lodash'
import moment from 'moment'
import {getUserData} from '../redux/slice/slice.auth'
import {ImageGalleryProps} from '../component/image-preview-v2/imageGallery'
import {ImageDetails} from '../component/image-preview-v2/imagePreview.context'

export const DEFAULT_LIST_FETCH_SIZE = 12

export const retailerStatus = {
	LEAD_ASSIGNED: 1,
	PENDING_FOR_DOCS: 2,
	PENDING_FOR_APPROVAL: 3,
	APPROVED: 4,
	DOC_REJECTED: 5,
}

export const retailerType: any = {
	1: 'Retailer',
	2: 'Trader',
	3: 'Retailer + Trader',
	4: 'Farmer',
	5: 'Farmer Produce Org (FPO)',
}

export const bagType = {
	Jute: 1,
	PP: 2,
	'Crates(20 kgs)': 3,
	'Boxes(10 kgs)': 4,
}

export const buyerType = {
	corporate: 1,
	miller: 2,
	processor: 3,
	FnVprocessor: 4,
	trader: 5,
}

export const buyerOnBoardStatus = {
	Created: 1,
	Pending: 2,
	Hold: 3,
	Reject_Poor_Payment_History: 4,
	Rejected_Insufficient_Doc: 5,
	Approved_By_Vp: 6,
}

export const buyerStatusInfo = {
	CREATED: 1, // default
	PENDING: 2, // after verification by MIS
	HOLD: 3,
	REJECTED_POOR_REPAYMENT_HISTORY: 4,
	REJECTED_INSUFFICIENT_DOC: 5,
	PENDING_FOR_CREDIT_LIMIT: 6,
	APPROVED: 7, // after credit limit submission
}

export const buyerStatusInfoText = {
	[buyerStatusInfo.CREATED]: 'Created',
	[buyerStatusInfo.PENDING]: 'Pending', // after verification by MIS
	[buyerStatusInfo.HOLD]: 'Hold Buyer Onboarding',
	[buyerStatusInfo.REJECTED_POOR_REPAYMENT_HISTORY]:
		'Rejected Poor Payment History',
	[buyerStatusInfo.REJECTED_INSUFFICIENT_DOC]: 'Rejected Insufficient Document',
	[buyerStatusInfo.PENDING_FOR_CREDIT_LIMIT]: 'Pending For Credit Limit',
	[buyerStatusInfo.APPROVED]: 'Approved',
}

export const gstStatus = {
	Active: 1,
	Inactive: 2,
	Cancelled: 3,
	Provision: 4,
	Suspended: 5,
}

export const documentStatus = {
	PENDING: 1,
	APPROVED: 2,
	RE_UPLOAD: 3,
	REJECTED: 4,
}
export const designations = {
	/* Super_Admin: 1,
	Op_Manager: 2,
	Call_Center_Agent: 3,
	Finance_Manager: 4,
	Tiktok_Agent: 5,
	Merchant: 6,
	accounts: 7,
	digital_marketing: 8,
	District_Manager: 9,
	Analytics_user: 10, */
	PROCUREMENT_ASSOCIATE: 11,
	REGIONAL_PROCUREMENT_MANAGER: 12,
	STATE_HEAD_PROCUREMENT: 13,
	VP_PROCUREMENT: 14,
	SALES_VP: 15,
	STATE_HEAD_SALES: 16,
	REGIONAL_MANAGER_SALES: 17,
	SALES_ASSOCIATE: 18,
	FINANCE_CONTROLLER: 19,
	ACCOUNT_PAYABLES: 20,
	MIS_Executive_Procurement: 21,
	CPO: 22,
	CFO: 23,
	MIS_Executive_Sales: 24,
	EXECUTIVE_SALES: 25,
	ASSISTANT_MANAGER_SALES: 26,
	ACCOUNT_RECEIVABLES: 27,
	HR_ONBOARDING: 37,
	// QUALITY_AUDIT: 28,
	// AUDITOR_BEAT_OPS: 29,
	CEO: 30,
	LOGISTIC_MANGER: 12,
	LOGISTIC_ASSOCIATE: 12,
	LOGISTICS_MANAGER: 35,
	COMPLIANCE_EXECUTIVE: 28,
	UNLOADING_ASSOCIATE: 32,
	AUDIT_MANAGER: 33,
	LOGISTICS_ASSOCIATE: 36,
	KYC_ANALYST: 38,
	VAS_ASSOCIATES: 68,
	VAS_RM_SALES: 69,
	VAS_KEY_ACCOUNT_MANAGER: 70,
	// VP_PRODUCT:31
	SUPPLY_CHAIN_ADMIN: 39,
}

export type Roles = typeof designations
export type RolesKeys = keyof Roles

export const sampleStatus = {
	HOLD_PRICE_CENTRAL: 9,

	REJECTED_QUALITY_CENTRAL: 11,
	REJECTED_PRICE_CENTRAL: 12,
	PURCHASE_ORDER_CREATED: 13,
	HOLD_QUALITY_CENTRAL: 10,
}

export const poCreateStatus = {
	PO_CREATED: 1,
	MAPPED: 2,
	VEHICLE_ARRIVED: 3,
	LOADING_STARTED: 4,
	LOADING_FINISHED: 5,
	READY_TO_DISPATCH: 6,
	DISPATCHED: 7,
	ARRIVED_AT_BUYER: 8,
	UNLOADING: 9,
	DELIVERED: 10,
	CANCELLED: 11,
	PARTIAL_DELIVERED: 12,
	DEDUCTION_SUBMITTED: 13,
	COMPLETED: 14,
	TERMINATED: 15,
}
export const pending_po_status = {
	MAPPING_SAVED: 1,
	SEND_FOR_REMAPPING: 2,
	SO_MAPPING_SAVED: 3,
	SO_MAPPING_SUBMITTED: 4,
	DISPATCHED: 5,
	ARRIVED_AT_BUYER: 6,
	UNLOADING: 7,
	DELIVERED: 8,
	CANCELLED: 9,
	PARTIAL_DELIVERED: 10,
	DEDUCTION_SUBMITTED: 11,
	COMPLETED: 12,
}
const pendingPoStatus = {
	[pending_po_status.MAPPING_SAVED]: 'Mapping Saved',
	[pending_po_status.SEND_FOR_REMAPPING]: 'Send For Remapping',
	[pending_po_status.SO_MAPPING_SAVED]: 'SO Mapping Saved',
	[pending_po_status.SO_MAPPING_SUBMITTED]: 'SO Mapping Submitted',
	[pending_po_status.DISPATCHED]: 'Dispatched',
	[pending_po_status.ARRIVED_AT_BUYER]: 'Arrived At Buyer',
	[pending_po_status.UNLOADING]: 'Unloading',
	[pending_po_status.DELIVERED]: 'Delivered',
	[pending_po_status.CANCELLED]: 'Cancelled',
	[pending_po_status.PARTIAL_DELIVERED]: 'Partial Delivered',
	[pending_po_status.DEDUCTION_SUBMITTED]: 'Deduction Submitted',
	[pending_po_status.COMPLETED]: 'Completed',
}

const poStatus = {
	[poCreateStatus.PO_CREATED]: 'Po Created',
	[poCreateStatus.MAPPED]: 'Mapped',
	[poCreateStatus.VEHICLE_ARRIVED]: 'Vehicle Arrived',
	[poCreateStatus.LOADING_STARTED]: 'Loading Started',
	[poCreateStatus.LOADING_FINISHED]: 'Loading Finished',
	[poCreateStatus.READY_TO_DISPATCH]: 'Ready To Dispatch',
	[poCreateStatus.DISPATCHED]: 'Dispatched',
	[poCreateStatus.ARRIVED_AT_BUYER]: 'Arrived At Buyer',
	[poCreateStatus.UNLOADING]: 'Unloading',
	[poCreateStatus.DELIVERED]: 'Delivered',
	[poCreateStatus.CANCELLED]: 'Cancelled',
	[poCreateStatus.PARTIAL_DELIVERED]: 'Partially Delivered',
	[poCreateStatus.DEDUCTION_SUBMITTED]: 'Deduction Submitted',
	[poCreateStatus.COMPLETED]: 'Completed',
	[poCreateStatus.TERMINATED]: 'Terminated',
}

export const transportDetails = {
	'Driving License': 1,
	'Number Plate': 2,
	'Loading Floor': 3,
	'Cancelled Cheque': 4,
	'Registration Certificate(RC)': 5,
	'Aadhar Card': 6,
	'TDS Ack': 7,
}

export const sampleAllStatus = {
	CREATED: 1,
	APPROVED_BY_RPM: 2,
	DO_PRICE_NEGOTIATION: 3,
	RETAKE_SAMPLE_IMAGES: 4,
	RETAKE_QC_REPORT: 5,
	REJECTED_BY_RPM: 6,
	APPROVED_BY_SALES: 7,
	REJECTED_BY_SALES: 8,
	HOLD_PRICE_CENTRAL: 9,
	HOLD_QUALITY_CENTRAL: 10,
	REJECTED_QUALITY_CENTRAL: 11,
	REJECTED_PRICE_CENTRAL: 12,
	PURCHASE_ORDER_CREATED: 13,
	RESIZED_LOT: 14,
	APPROVED_BY_SH: 15,
	TERMINATED: 16,
	REJECTED_BY_SH: 17,
	PO_REQUESTED: 18,
	EXPIRED: 19,
}
const newSampleStatus = {
	[sampleAllStatus.CREATED]: 'Sample Created',
	[sampleAllStatus.APPROVED_BY_RPM]: 'Approved By RPM',
	[sampleAllStatus.DO_PRICE_NEGOTIATION]: 'Price Negotiation',
	[sampleAllStatus.RETAKE_SAMPLE_IMAGES]: 'Retake Sample Image',
	[sampleAllStatus.RETAKE_QC_REPORT]: 'Retake Qc',
	[sampleAllStatus.REJECTED_BY_RPM]: 'Rejected By RPM',
	[sampleAllStatus.APPROVED_BY_SALES]: 'Approved By Sales',
	[sampleAllStatus.REJECTED_BY_SALES]: 'Rejected By Sales',
	[sampleAllStatus.HOLD_PRICE_CENTRAL]: 'Hold Price',
	[sampleAllStatus.HOLD_QUALITY_CENTRAL]: 'Hold Quality',
	[sampleAllStatus.REJECTED_QUALITY_CENTRAL]: 'Reject Quality',
	[sampleAllStatus.REJECTED_PRICE_CENTRAL]: 'Rejected Price',
	[sampleAllStatus.PURCHASE_ORDER_CREATED]: 'Po Created',
	[sampleAllStatus.RESIZED_LOT]: 'Resized Lot',
	[sampleAllStatus.APPROVED_BY_SH]: 'Approved By SH',
	[sampleAllStatus.TERMINATED]: 'Terminated',
	[sampleAllStatus.REJECTED_BY_SH]: 'Rejected By SH',
	[sampleAllStatus.PO_REQUESTED]: 'PO Requested',
	[sampleAllStatus.EXPIRED]: 'Expired',
}

export const docs = {
	Pancard: 1,
	Gst: 2,
}

export const saleOrderStatus: any = {
	SO_CREATED: 1,
	MODIFY_PRICE: 2,
	MODIFY_QUANTITY: 3,
	REJECTED_NO_CREDIT_LIMIT: 4,
	REJECTED_CREDIT_OVERDUE: 5,
	REJECTED_BAD_CREDIT: 6,
	REJECTED_PRICE_PARITY: 7,
	APPROVED_BY_VP: 8,
	APPROVED_BY_CREDIT: 9,
	REJECTED_BY_CREDIT: 10,
}

export const vpSaleOrderStatus = {
	[saleOrderStatus.MODIFY_PRICE]: 'Modify Price',
	[saleOrderStatus.MODIFY_QUANTITY]: 'Modify Quantity',
	[saleOrderStatus.REJECTED_NO_CREDIT_LIMIT]: 'Rejected No Credit Limit',
	[saleOrderStatus.REJECTED_CREDIT_OVERDUE]: 'Rejected Credit OverDue',
	[saleOrderStatus.REJECTED_BAD_CREDIT]: 'Rejected Bad Credit',
	[saleOrderStatus.REJECTED_PRICE_PARITY]: 'Rejected Price Parity',
	[saleOrderStatus.APPROVED_BY_VP]: 'Approved By VP',
}

export const financeControlerSaleOrderStatus = {
	[saleOrderStatus.MODIFY_PRICE]: 'Modify Price',
	[saleOrderStatus.MODIFY_QUANTITY]: 'Modify Quantity',
	[saleOrderStatus.REJECTED_NO_CREDIT_LIMIT]: 'Rejected No Credit Limt',
	[saleOrderStatus.REJECTED_CREDIT_OVERDUE]: 'Rejected Credit OverDue',
	[saleOrderStatus.REJECTED_BAD_CREDIT]: 'Rejected Bad Credit',
	[saleOrderStatus.APPROVED_BY_CREDIT]: 'Approved By Credit',
	[saleOrderStatus.REJECTED_BY_CREDIT]: 'Rejected By Credit',
}

export const financeControlerSaleOrderWhenApproved = {
	[saleOrderStatus.MODIFY_PRICE]: 'Modify Price',
	[saleOrderStatus.MODIFY_QUANTITY]: 'Modify Quantity',
	[saleOrderStatus.APPROVED_BY_CREDIT]: 'Approved By Credit',
}

export const assignLeadsStatus: any = {
	Success: 1,
	Fail: 2,
	Partial: 3,
}

export const RetailerDocType = {
	pancard: 1,
	gst: 2,
	aadhar: 3,
	licence: 4,
	bankAccount: 5,
}

export const TransportImageType = {
	DL: 1,
	'NUMBER PLATE': 2,
	'LOADING FLOOR': 3,
	'CANCELLED CHECK': 4,
	RC: 5,
	AADHAR: 6,
	TDS: 7,
}

export const getMinTime = (delay: number) => {
	const date = new Date().setSeconds(0)

	return dayjs(date)
}

export const convertArrayToObject = (array: any, key: string) => {
	const initialValue = {}
	return array.reduce((obj: any, item: any) => {
		return {
			...obj,
			[item[key]]: item,
		}
	}, initialValue)
}

export function combineDateAndTime(date: any, time: any) {
	var year = date.getFullYear()
	var month = date.getMonth() + 1 // Jan is 0, dec is 11
	var day = date.getDate()
	let hours = time.getHours()
	let minutes = time.getMinutes()
	let seconds = time.getSeconds()
	var dateString = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`

	return dateString
}

pendingPoStatus
export function getPendingPoStatusString(code: number) {
	return pendingPoStatus[code]
}
export function getPoStatusString(code: number) {
	return poStatus[code]
}
export function getSampleStatusString(code: number) {
	return newSampleStatus[code]
}

export const isUserMis = () => {
	// @ts-ignore
	return (
		getUserData()?.profile?.user_role_id === designations.MIS_Executive_Sales
	)
}
export const isUserVp = () => {
	// @ts-ignore
	return getUserData()?.profile?.user_role_id === designations.SALES_VP
}
export const isUserFC = () => {
	// @ts-ignore
	return getUserData()?.profile?.user_role_id === designations.FINANCE_CONTROLLER
}

export const getbagType = (code: number) => {
	const obj = bagType as any
	return Object.keys(obj).find(key => obj[key] == code) ?? 'N/A'
}
export const getBuyerType = (code: number) => {
	const obj = buyerType as any
	return Object.keys(obj).find(key => obj[key] == code) ?? 'N/A'
}

export const getDocDetail = (code: number | string) => {
	const obj = docs as any
	return Object.keys(obj).find(key => obj[key] == code) ?? 'N/A'
}
/* eslint eqeqeq: 0 */
export const getDocStatus = (code: number | string) => {
	const obj = documentStatus as any
	return Object.keys(obj).find(key => obj[key] == code) ?? 'N/A'
}
/* eslint eqeqeq: 0 */
export const getRetailerStatus = (code: number | string) => {
	const obj = retailerStatus as any
	return Object.keys(obj).find(key => obj[key] == code) ?? 'N/A'
}
/* eslint eqeqeq: 0 */
export const getDesignationStatus = (code: number | string) => {
	if (!code) return 'N/a'
	const obj = designations as any
	return Object.keys(obj).find(key => obj[key] == code) ?? 'N/A'
}
/* eslint eqeqeq: 0 */
export const getSampleStatus = (code: number | string) => {
	const obj = sampleAllStatus as any
	return Object.keys(obj).find(key => obj[key] == code) ?? 'N/A'
}

export const getTransportDocument = (code: any) => {
	const obj = transportDetails as any
	return Object.keys(obj).find(key => obj[key] == code) ?? 'N/A'
}
export const getPoStatus = (code: any) => {
	const obj = poCreateStatus as any
	return Object.keys(obj).find(key => obj[key] == code) ?? 'N/A'
}

export const getGstStatus = (code: number | string | undefined) => {
	const obj = gstStatus as any
	return Object.keys(obj).find(key => obj[key] == code) ?? 'N/A'
}

export const getBuyerOnBoardStatus = (code: number | string) => {
	const obj = buyerOnBoardStatus as any
	return Object.keys(obj).find(item => obj[item] == code)
}
export const getBuyerStatusInfo = (code: any) => {
	return buyerStatusInfoText[code] || 'N/A'
}

export const getSoDetail = (code: number | string) => {
	const obj = saleOrderStatus as any
	return Object.keys(obj).find(item => obj[item] == code) ?? 'N/A'
}

export const isValidURL = (url: string) => {
	try {
		const u = new URL(url)
		return true
	} catch (err) {
		return false
	}
}

export const getFormattedDateTime = (date: Date | number | string) =>
	new Date(date).toLocaleString('en-IN', {
		year: '2-digit',
		month: 'short',
		day: '2-digit',
		//hour: "2-digit",
		//minute: "2-digit",
		//hour12: true
	})

export const getDDMMYYYY = (date: Date | number | string) =>
	new Date(date).toLocaleDateString('IN')
export const getTime = (date: Date | number | string) => {
	const time = new Date(date)
	return time.toLocaleString('en-US', {
		hour: 'numeric',
		minute: 'numeric',
		hour12: true,
	})
}

export const getAmountInRupee = (
	value: number | null | undefined,
	emptyString = 'N/a',
) => {
	if (!_.isNil(value)) {
		const inr = new Intl.NumberFormat('en-IN', {
			style: 'currency',
			currency: 'INR',
		})
		// const formattedNumber = number.toLocaleString('en-IN', {
		// 	style: 'currency',
		// 	currency: 'INR',
		// })
		return inr.format(value)
	}

	return emptyString
}

export const formatInRuppes = (value?: string | number, defaultValue = 'N/A') =>
	isNillGetDefaultWithFormating(value, defaultValue, par =>
		getAmountInRupee(par, defaultValue),
	)

export const isNumber = (value: any) => !isNaN(value)

export const getMapUrl = (lat?: number | string, lng?: number | string) =>
	`https://www.google.com/maps?q=${lat},${lng}`

export function getDecimalValue(number: number) {
	var rounded = Math.round((number + Number.EPSILON) * 100) / 100
	return rounded
}

/**
 * 
 * @param dateString 
 * @returns 
 * moment.locale();         // en
	moment().format('LT');   // 2:53 PM
	moment().format('LTS');  // 2:53:17 PM
	moment().format('L');    // 04/21/2023
	moment().format('l');    // 4/21/2023
	moment().format('LL');   // April 21, 2023
	moment().format('ll');   // Apr 21, 2023
	moment().format('LLL');  // April 21, 2023 2:53 PM
	moment().format('lll');  // Apr 21, 2023 2:53 PM
	moment().format('LLLL'); // Friday, April 21, 2023 2:53 PM
	moment().format('llll');

 */

type DateFormates =
	| 'LT'
	| 'LTS'
	| 'L'
	| 'l'
	| 'LL'
	| 'll'
	| 'LLL'
	| 'lll'
	| 'LLLL'
	| 'llll'
export const getDateString = (
	dateString: string,
	type: DateFormates,
	defaultValue: string,
): string => {
	if (!dateString) return defaultValue
	if (!moment(dateString).isValid()) return defaultValue
	return moment(dateString).format(type)
}

export const isNillGetDefault = (value: any, defaultValue: any) =>
	_.isNil(value) ? defaultValue : value

export const isNillGetDefaultWithFormating = (
	value: any,
	defaultValue: any,
	format: (value: any) => any,
) => (_.isNil(value) ? defaultValue : format(value))

export const removeAllAlphabetsFromString = (str: any) => {
	return str.replace(/\D/g, '')
}

export const verifyIfImageExist = (data: ImageDetails[]) => {
	for (const image of data) {
		const url = image.url
		if (url && isValidURL(url)) {
			return true
		}
	}
	return false
}

export const checkValidUrl = (url?: string) =>
	!isValidURL(url || '') ? '' : url || ''
