// Import the functions you need from the SDKs you need
import {initializeApp} from 'firebase/app'
import {getFirestore} from 'firebase/firestore'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
	apiKey: 'AIzaSyBDBOK4y0utpswUPxYXLebTPGbooCLbu4Q',
	authDomain: 'market-linkage.firebaseapp.com',
	databaseURL:
		'https://market-linkage-default-rtdb.asia-southeast1.firebasedatabase.app',
	projectId: 'market-linkage',
	storageBucket: 'market-linkage.appspot.com',
	messagingSenderId: '1042010603795',
	appId: '1:1042010603795:web:9bc4e6e251a3363acedae6',
	measurementId: 'G-XMPLM01GCT',
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
const db = getFirestore(app)

export {db}
