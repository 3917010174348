import {ApiCaller} from '../../../../component/apiRedux/apiCaller'
import {FarmartResponse} from '../../../../redux/thunk/reduxApis/states.api'

export interface UpdateTransportChargesRequestBody {
	po_id: number,
    total_transport_cost: number,
    halting_charges: number,
    unloading_charges: number,
    route_change_charges: number,
    revised_transport_cost: number,
    primary_bill_t_url: string,
    revised_bill_t_url: string
}

export const updateTransportChargesApi = ApiCaller<
	FarmartResponse<any>,
	any,
	UpdateTransportChargesRequestBody
>('updateTransportChargesApi', {
	type: 'POST',
	url: 'v4/payment/transport_charges',
})
