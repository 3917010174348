import {setDocData} from './../service/firebaseServices'

interface SetDocDataReturnType {
	status: boolean
	message: string
}

interface SupplierData {
	businessName: string
	address: string
	licenseNumber: string
	licenseCategory: string
	stateName: string
	districtName: string
	talukName: string
	pincode: string
	id: string
	rmId: number
	[key: string]: any
}

export default async function assignLeadToRM(
	data: SupplierData,
): Promise<SetDocDataReturnType> {
	try {
		validateSupplierData(data)
		const updatedData = addTimestampAndActiveStatus(changeStatus(data))

		await setDocData({
			collectionType: 'supplier',
			key: updatedData.id,
			data: updatedData,
		})
		return {status: true, message: 'Successfully UA assigned to Lead'}
	} catch (error: any) {
		return {status: false, message: error.message}
	}
}

function addTimestampAndActiveStatus(data: SupplierData): SupplierData {
	const d = new Date()
	let time = d.getTime()
	return {
		...data,
		createdAt: time,
		isActive: true,
	}
}

function changeStatus(data: SupplierData): SupplierData {
	return {
		...data,
		status: 2,
	}
}

function validateSupplierData(data: SupplierData): void {
	const requiredFields = [
		'businessName',
		'address',
		'licenseNumber',
		'licenseCategory',
		'stateName',
		'districtName',
		'pincode',
		'id',
		'rmId',
		'uaId',
	]

	const optionalFields = ['rmId', 'uaId']

	for (const field of requiredFields) {
		if (!data[field] && !optionalFields.includes(field)) {
			throw new Error(`Missing or empty field: ${field}`)
		}
	}
}
