import {createSlice} from '@reduxjs/toolkit'
import {
	getUsers,
	getDesignations,
	onUserDetailsSubmit,
	getReportingManagers,
	checkMobileNumber,
	getUserTicketToken,
	checkEmployeeId
} from '../thunk/thunk.user'
import {IUserState} from '../types/type.user'

const initialState: IUserState = {
	users: [],
	roles: [],
	isCreating: false,
	isFetchingRoles: false,
	isFetchingUsers: false,
	hasError: false,
	errorMessage: '',
}

export const sliceUser = createSlice({
	name: 'user',
	initialState,
	reducers: {},
	extraReducers: builder => {},
})

export const userActions = {
	getDesignations,
	getUsers,
	onUserDetailsSubmit,
	getReportingManagers,
	checkMobileNumber,
	checkEmployeeId,
	getUserTicketToken
}

export default sliceUser.reducer
