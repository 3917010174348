import {addEditTdsApi} from './addEditTds.api'
import {approveRejectPaymentApi} from './approveRejectPayment.api'
import {addDeductionApi, deleteDeductionApi} from './deduction.api'
import { getSupplierAndDeliveryDeailsApi } from './supplierAndDeliveryDetails.api'
import {gstPaymentDetailsApi} from './gstPayment.api'
import {initPaymentApi} from './initPayment.api'
import {retailerPaymentDetailsApi} from './retailerPayment.api'
import {updateTransportChargesApi} from './transportCharges.api'
import {transportPaymentDetailsApi} from './transportPayment.api'
import {updateWeightDetailsApi} from './weightDetails.api'
import { fetchDocumentsApi } from './fetchDocumentDetails.api'
import { unloadingDetailsApi } from './unloadingDetails.api'
import { addEditUtrApi } from './addEditUtr.api'
import { fetchRejectionReasonApi } from './fetchRejectionReason.api'

export const paymentApis = [
	retailerPaymentDetailsApi,
	addEditTdsApi,
	approveRejectPaymentApi,
	addDeductionApi,
	deleteDeductionApi,
	gstPaymentDetailsApi,
	initPaymentApi,
	updateTransportChargesApi,
	transportPaymentDetailsApi,
	updateWeightDetailsApi,
	getSupplierAndDeliveryDeailsApi,
	fetchDocumentsApi,
	unloadingDetailsApi,
	addEditUtrApi,
	fetchRejectionReasonApi
]

export * from './retailerPayment.api'
export * from './addEditTds.api'
export * from './approveRejectPayment.api'
export * from './deduction.api'
export * from './gstPayment.api'
export * from './initPayment.api'
export * from './retailerPayment.api'
export * from './transportCharges.api'
export * from './transportPayment.api'
export * from './weightDetails.api'
export * from './supplierAndDeliveryDetails.api'
export * from './fetchDocumentDetails.api'
export * from './unloadingDetails.api'
export * from './addEditUtr.api'
export * from './fetchRejectionReason.api'
