import {ApiCaller} from '../../../../component/apiRedux/apiCaller'
import useApi from '../../../../component/apiRedux/useApi'
import {FarmartResponse} from '../../../../redux/thunk/reduxApis/states.api'

export interface PurchaseOrderDeduction {
	po_id: number
	deduction_for: number // TODO: Define an enum for valid deduction types
	deduction_reason_id: number // TODO: Define an enum for valid deduction reasons
	deduction_amount: number
}

export interface DeleteDeductin {
	po_id: number
	deduction_for: number
	deduction_id: number
}

export const addDeductionApi = ApiCaller<
	FarmartResponse<any>,
	any,
	PurchaseOrderDeduction
>('addDeductionApi', {
	type: 'POST',
	url: 'v4/payment/deduction',
})

export const deleteDeductionApi = ApiCaller<
	FarmartResponse<any>,
	any,
	DeleteDeductin,
	any
>('v4/payment/delete_deduction', {
	type: 'POST',
	url: 'v4/payment/delete_deduction',
})
