import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IInventoryState } from "../types/type.inventory";
import { fetchWarehouseList, onboardWarehouse, fetchAllInventory, remapWithNewWarehouse } from "../thunk/thunk.inventory";
import { toast } from "react-toastify";

const initialState: IInventoryState = {
    fetching: false,
    onboarding: false,
    fetchingAllInventory: false,
    remapping: false,
    totalWarehouse: 0,
    warehouseList: [],
    allInventory: []
};

export const sliceInventory = createSlice({
    name: "inventory",
    initialState,
    reducers: {
        filterByWarehouse: (state, action: PayloadAction<number>) => {
            //
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchWarehouseList.pending, (state) => {
            state.fetching = true;
        });
        builder.addCase(fetchWarehouseList.fulfilled, (state, action) => {
            state.fetching = false;
            state.totalWarehouse = action.payload.totalWarehouse;
            state.warehouseList = action.payload.warehouseList;
        });
        builder.addCase(fetchWarehouseList.rejected, (state, action) => {
            state.fetching = false;
            const message = action.payload as string;
            toast(message, { type: 'error' });
        });

        builder.addCase(onboardWarehouse.pending, (state) => {
            state.onboarding = true;
        });
        builder.addCase(onboardWarehouse.fulfilled, (state, action) => {
            state.onboarding = false;
            toast(`Warehouse- ${action.payload} added successfully.`, { type: 'success' });
        });
        builder.addCase(onboardWarehouse.rejected, (state, action) => {
            state.onboarding = false;
            const message = action.payload as string;
            toast(message, { type: 'error' });
        });

        builder.addCase(fetchAllInventory.pending, (state) => {
            state.fetchingAllInventory = true;
        });
        builder.addCase(fetchAllInventory.fulfilled, (state, action) => {
            state.fetchingAllInventory = false;
            state.allInventory = action.payload;
        });
        builder.addCase(fetchAllInventory.rejected, (state, action) => {
            state.fetchingAllInventory = false;
            const message = action.payload as string;
            toast(message, { type: 'error' });
        });

        builder.addCase(remapWithNewWarehouse.pending, (state) => {
            state.remapping = true;
        });
        builder.addCase(remapWithNewWarehouse.fulfilled, (state, action) => {
            state.remapping = false;
            const { pending_in_hand_id, warehouse_id } = action.payload;
            // update the table info as well.
            const warehouse = state.allInventory.find(w => w.id === warehouse_id);
            if (warehouse) {
                const pendingInhandIndex = state.warehouseList.findIndex(pi => pi.pending_inhand_id === pending_in_hand_id);
                if (pendingInhandIndex > -1) {
                    state.warehouseList[pendingInhandIndex].district_name = warehouse.district_name;
                    state.warehouseList[pendingInhandIndex].state_name = warehouse.state_name;
                    state.warehouseList[pendingInhandIndex].warehouse_name = warehouse.name;
                    state.warehouseList[pendingInhandIndex].warehouse_id = warehouse.id;
                    //FIXME: (Backend) needs warehouse address.
                    //state.warehouseList[pendingInhandIndex].location;
                    toast(`Mapped with new warehouse ${warehouse.name}.`);
                }
            }
        });
        builder.addCase(remapWithNewWarehouse.rejected, (state, action) => {
            state.remapping = false;
            const message = action.payload as string
            toast(message, { type: 'error' })
        });
    }
});

export const inventoryActions = {
    ...sliceInventory.actions,
    fetchWarehouseList,
    onboardWarehouse,
    fetchAllInventory,
    remapWithNewWarehouse
};

export default sliceInventory.reducer;