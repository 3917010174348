import {createAsyncThunk} from '@reduxjs/toolkit'
import {dsApi} from '../../service/dsService.api'
import ServiceApi from '../../service/service.api'
import assignLeadToRM from './../../firebaseServices/assignLeadToRM'
import setProductList from './../../firebaseServices/setProductList'
import fetchSupplierList from './../../firebaseServices/fetchSupplierList'
import serachSupplier from './../../firebaseServices/serachSupplier'
import updateSupplierStatusApi from './../../firebaseServices/updateSupplierStatus'
import getSingleSupplier from '../../firebaseServices/getSingleSupplier'
import {throws} from 'assert'
export const fetchSupplier = createAsyncThunk(
	'/vas/supplierList',
	async (body: any, thunkAPI) => {
		try {
			const {data, status} = await dsApi({
				endpoint: '/fbo_search/get_fbos_for_the_given_params',
				payload: body,
			})
			if (status !== 200) throw new Error(data.message ?? 'Something went wrong')
			return data
		} catch (err: any) {
			return thunkAPI.rejectWithValue(err.message)
		}
	},
)
export const fetchSupplierProductList = createAsyncThunk(
	'/vas/productList',
	async (body: any, thunkAPI) => {
		try {
			const {data, status} = await dsApi({
				endpoint: '/fbo_search/get_fbo_products_for_the_given_refid',
				payload: body,
			})
			if (status !== 200) throw new Error(data.message ?? 'Something went wrong')
			return data
		} catch (err: any) {
			return thunkAPI.rejectWithValue(err.message)
		}
	},
)
export const fetchStateDistrict = createAsyncThunk(
	'/vas/stateDistrict',
	async (_, thunkAPI) => {
		try {
			const {data, status} = await dsApi({
				endpoint: '/fbo_search/get_state_district_filter',
				payload: {states_name_list: null, get_all: true},
			})
			if (status !== 200) throw new Error(data.message ?? 'Something went wrong')
			return data
		} catch (err: any) {
			return thunkAPI.rejectWithValue(err.message)
		}
	},
)
export const fetchAllKobNames = createAsyncThunk(
	'/vas/kobNames',
	async (body: any, thunkAPI) => {
		try {
			const {data, status} = await dsApi({
				endpoint: '/fbo_search/get_kob_names',
				payload: body,
			})
			if (status !== 200) throw new Error(data.message ?? 'Something went wrong')
			return data
		} catch (err: any) {
			return thunkAPI.rejectWithValue(err.message)
		}
	},
)
export const fetchAllProductGivenKobNames = createAsyncThunk(
	'/vas/productGivenKobNames',
	async (body: any, thunkAPI) => {
		try {
			const {data, status} = await dsApi({
				endpoint: '/fbo_search/get_food_products_names',
				payload: body,
			})
			if (status !== 200) throw new Error(data.message ?? 'Something went wrong')
			return data
		} catch (err: any) {
			return thunkAPI.rejectWithValue(err.message)
		}
	},
)

export const fetchRmList = createAsyncThunk(
	'/fetchRmList',
	async (_, thunkAPI) => {
		try {
			const {status, data} = await ServiceApi.getInstance().POST('v2/user', {
				role_ids: [68],
			})
			if (status !== 200) {
				throw new Error(data.message ?? 'Something went wrong')
			}
			return data.data
		} catch (e: any) {
			return thunkAPI.rejectWithValue(e.message)
		}
	},
)

export const fetchUAList = createAsyncThunk(
	'/fetchUAList',
	async (_, thunkAPI) => {
		try {
			const {status, data} = await ServiceApi.getInstance().POST('v2/user', {
				role_ids: [32],
			})
			if (status !== 200) {
				throw new Error(data.message ?? 'Something went wrong')
			}
			return data.data
		} catch (e: any) {
			return thunkAPI.rejectWithValue(e.message)
		}
	},
)
interface SupplierData {
	businessName: string
	address: string
	licenseNumber: string
	licenseCategory: string
	stateName: string
	districtName: string
	talukName: string
	pincode: string
	id: string
	rmId?: number
	uaId?: number
	[key: string]: any
}
export const assignLeadToVasRM = createAsyncThunk(
	'./assignLeadToVasRM',
	async (body: SupplierData, thunkAPI) => {
		const state: any = thunkAPI.getState()
		const userData = state?.auth?.userProfile
		try {
			const {message, status} = await assignLeadToRM({
				...body,
				actionTakenBy: {
					name: userData?.name,
					id: userData?.id,
					mobileNumber: userData?.mobile_number,
				},
			})
			if (!status) throw new Error(message ?? 'Something went wrong')
			return message
		} catch (e: any) {
			return thunkAPI.rejectWithValue(e.message)
		}
	},
)

export const assignLeadToVasUA = createAsyncThunk(
	'./assignLeadToVasUA',
	async (body: SupplierData, thunkAPI) => {
		const state: any = thunkAPI.getState()
		const userData = state?.auth?.userProfile
		try {
			// using same api as assignLeadToVasRM because
			// we are passing the entire payload form the controller
			// so it doesn't matter if we are using RM or UA

			// check if already action is taken or not if taken then throw error
			const data = await getSingleSupplier({id: body.id})

			if (data === null) {
				const {message, status} = await assignLeadToRM({
					...body,
					actionTakenBy: {
						name: userData?.name,
						id: userData?.id,
						mobileNumber: userData?.mobile_number,
					},
				})
				if (!status) throw new Error(message ?? 'Something went wrong')
				return message
			}

			throw new Error('UA already assigned')
		} catch (e: any) {
			return thunkAPI.rejectWithValue(e.message)
		}
	},
)

export const getRecordById = createAsyncThunk(
	'./getRecordById',
	async (id: string, thunkAPI) => {
		try {
			const {message, status, data} = await getSingleSupplier({id})
			if (!status) throw new Error(message ?? 'Something went wrong')
			return data
		} catch (e: any) {
			return thunkAPI.rejectWithValue(e.message)
		}
	},
)

export const updateSupplierStatus = createAsyncThunk(
	'./updateSupplierStatus',
	async (body: any, thunkAPI) => {
		try {
			const {message, status} = await updateSupplierStatusApi(body)
			if (!status) throw new Error(message ?? 'Something went wrong')
			return message
		} catch (e: any) {
			return thunkAPI.rejectWithValue(e.message)
		}
	},
)

interface ProductListData {
	productList: any
	id: number
}

export const addProductListWithSupplier = createAsyncThunk(
	'./productList',
	async (body: ProductListData, thunkAPI) => {
		try {
			const {message, status} = await setProductList(body)
			if (!status) throw new Error(message ?? 'Something went wrong')
			return message
		} catch (e: any) {
			return thunkAPI.rejectWithValue(e.message)
		}
	},
)
interface filtersT {
	status: number
	state?: string
	district?: string
}
interface fetchSupplierListT {
	paginationFor: 'next' | 'previous'
	snapshots?: any
	pageSize: number
	filters: filtersT
	search?: string
}

export const fetchSupplierListFromFirebase = createAsyncThunk(
	'./fetchSupplierListFromFirebase',
	async (body: fetchSupplierListT, thunkAPI) => {
		try {
			const response = await fetchSupplierList(body)

			//if (!status) throw new Error(message ?? 'Something went wrong')
			return response
		} catch (e: any) {
			return thunkAPI.rejectWithValue(e.message)
		}
	},
)
interface serachSupplierT {
	search: string
}
export const serachSupplierListFromFirebase = createAsyncThunk(
	'./serachSupplierListFromFirebase',
	async (body: serachSupplierT, thunkAPI) => {
		try {
			
			const response = await serachSupplier(body);
			console.log(response)
			//if (!status) throw new Error(message ?? 'Something went wrong')
			return response
		} catch (e: any) {
			return thunkAPI.rejectWithValue(e.message)
		}
	},
)
