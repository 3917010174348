import {ApiCaller} from '../../../../component/apiRedux/apiCaller'
import {FarmartResponse} from '../../../../redux/thunk/reduxApis/states.api'

export interface RetailerPaymentDetails {
	weight_details: WeightDetails
	payment_details: PaymentDetails
}

interface WeightDetails {
	gross_weight: number
	net_weight: number
	bag_deduction: number
	number_of_bags: number
	weight_slip_url: string
	primary_9r_url: string
	first_9r_gate_pass_url: string
}

interface PaymentDetails {
	mandi_tax: number
	commodity_cost: number
	retailer_commision: number
	labour_and_bag_cost: number
	f_o_r: number
	transport_cost: number
	tds_amount: number
	deduction_amount: number
	payable_amount: number
	paid_amount: number
	pending_amount: number
	initiated_amount: number
  fulfillment_charge: number
  is_fulfillment_charge_editable: boolean

}

export const retailerPaymentDetailsApi = ApiCaller<
	FarmartResponse<RetailerPaymentDetails>,
	any,
	any,
	{po_id: string}
>('retailerPaymentDetailsApi', {
	url: 'v4/payment/retailer/details',
	type: 'GET',
})
