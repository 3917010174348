import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart'
import GridViewIcon from '@mui/icons-material/GridView'
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined'
import PaymentsIcon from '@mui/icons-material/Payments'
import PersonAddIcon from '@mui/icons-material/PersonAdd'
import TrendingUpIcon from '@mui/icons-material/TrendingUp'
import WarehouseIcon from '@mui/icons-material/Warehouse'
import SettingsIcon from '@mui/icons-material/Settings'
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
export const forFinanceRoleNavbar = [
	{
		navlabel: 'Dashboard',
		to: '/protected',
		icon: GridViewIcon,
		subcat: [
			{
				navlabel: 'Company metrics',
				to: '/protected/dashboard/company_metrics',
			},
			{
				navlabel: 'Buyer Overview',
				to: '/protected/dashboard/buyer_overview',
			},
			{
				navlabel: 'Seller Overview',
				to: '/protected/dashboard/seller_metrics',
			},
			{
				navlabel: 'Operational Funnels',
				to: '/protected/dashboard/operational_funnels',
			},
			{
				navlabel: 'Procurement',
				to: '/protected/dashboard/procurement',
			},
            {
                navlabel: 'Finance',
                to: '/protected/dashboard/finance',
            },
			{
				navlabel: 'Product Farmart App',
				to: '/protected/dashboard/product_farmart',
			},
			{
				navlabel: 'Product SaudaBook App',
				to: '/protected/dashboard/product_saudaBook',
			}
		],
	},
	{
		navlabel: 'Access Creation',
		to: '/protected/users',
		icon: PersonAddIcon,
		feild: 'isUsersOpen',
		subcat: [
			{
				navlabel: 'Access Creation',
				to: '/protected/users/access_creation',
			},
			{
				navlabel: 'Visits',
				to: '/protected/users/visits',
			},
		],
	},
	{
		navlabel: 'Procurement',
		to: '/protected',
		icon: AddShoppingCartIcon,
		feild: 'isProcurementOpen',
		subcat: [
			{
				navlabel: 'Retailer',
				to: '/protected/procurement/retailer',
			},
			{
				navlabel: 'Supply Visibility',
				to: '/protected/procurement/supply',
			},
			{
				navlabel: 'Create Order',
				to: '/protected/procurement/procurement',
			},
			// {
			// 	navlabel: 'Sales Order Target',
			// 	to: '/protected/procurement/salesOrderTarget',
			// },
			{
				navlabel: 'Dropdowns',
				to: '/protected/procurement/dropdown',
			},
			// {
			// 	navlabel: 'Campaign',
			// 	to: '/protected/procurement/campaign',
			// },
			// {
			// 	navlabel: 'Leads',
			// 	to: '/protected/procurement/leads',
			// },
			// {
			// 	navlabel: 'Buyer Prices',
			// 	to: '/protected/procurement/buyerPrices',
			// },
		],
	},
	{
		navlabel: 'Trade',
		to: '/protected',
		icon: BusinessCenterIcon,
		feild: 'isTrade',
		subcat: [
			{
				navlabel: 'Buyer Prices',
				to: '/protected/trade/buyerPrices',
			},
			{
				navlabel:`Supplier Sauda's`,
				to: '/protected/trade/supplierSauda',
			},
		],
	},
	{
		navlabel: 'Sales',
		to: '/protected',
		icon: TrendingUpIcon,
		feild: 'isSales',
		subcat: [
			{
				navlabel: 'Buyer',
				to: '/protected/sales/buyer',
			},
			{
				navlabel: 'So Approval',
				to: '/protected/sales/salesOrder',
			},
			{
				navlabel: 'Repayments',
				to: '/protected/sales/repayments',
			},
		],
	},
	{
		navlabel: 'Logistic',
		to: '/protected',
		icon: LocalShippingOutlinedIcon,
		feild: 'isLogistic',
		subcat: [
			{
				navlabel: 'Delivery',
				to: '/protected/logistic/delivery',
			},
		],
	},
	{
		navlabel: 'Payments',
		to: '/protected',
		icon: PaymentsIcon,
		feild: 'isPaymentOpen',
		subcat: [
			{
				navlabel: 'Payments',
				to: '/protected/payments/payment',
			},
			{
				navlabel: 'Mandi Tax',
				to: '/protected/payments/mandiTax',
			},
			{
				navlabel: 'Invoicing Document',
				to: '/protected/payments/invoicing',
			},
		],
	},
	{
		navlabel: 'Inventory',
		to: '/protected',
		icon: WarehouseIcon,
		feild: 'isInventory',
		subcat: [
			{
				navlabel: 'Warehouse',
				to: '/protected/inventory/warehouse',
			},
			{
				navlabel: 'Incomplete Delivery',
				to: '/protected/inventory/rejectedPo',
			},
		],
	},
	{
		navlabel: 'Controls & Config',
		to: '/protected',
		icon: SettingsIcon,
		feild: 'isConfig',
		subcat: [
			/* {
				navlabel: 'Commodity',
				to: '/protected/control&config/commodity',
			}, */
			{
				navlabel: 'Pincode',
				to: '/protected/control&config/pincode',
			},
		],
	},
]
