import {ApiCaller} from '../../../../../component/apiRedux/apiCaller'
import {FarmartResponse} from '../../../../../redux/thunk/reduxApis/warehouse/warehouse.api'

export const editTdsApi = ApiCaller<
	FarmartResponse<any>,
	any,
	{
		payment_id: number,
		tds_amount: number
	}
>('editTdsApi', {
	url: '/v4/payment/tds',
	type: 'POST',
})
