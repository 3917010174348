import {createAsyncThunk} from '@reduxjs/toolkit'
import ServiceApi from '../../service/service.api'

export const sendOtp = createAsyncThunk(
	'auth/sendOtp',
	async (mobile: string, thunkAPI) => {
		try {
			const {status, data} = await ServiceApi.getInstance().POST('/auth/get_otp', {
				mobile_number: mobile,
			})
			if (status !== 200) throw new Error(data.message ?? 'Something went wrong.')
			return data
		} catch (err: any) {
			return thunkAPI.rejectWithValue(err.message)
		}
	},
)

export const verifyOtp = createAsyncThunk(
	'auth/verifyOtp',
	async (payload: {mobile: string; otp: string}, thunkAPI) => {
		try {
			const {status, data} = await ServiceApi.getInstance().POST(
				'/auth/validate_otp_get_token',
				{
					mobile_number: payload.mobile,
					otp: payload.otp,
				},
			)
			if (status !== 200) throw new Error(data.message ?? 'Something went wrong.')

			return data as {token: string; profile: {}}
		} catch (err: any) {
			return thunkAPI.rejectWithValue(err.message)
		}
	},
)
