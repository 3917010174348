import { ApiCaller } from "../../../../../component/apiRedux/apiCaller";
import { FarmartResponse } from "../../../../../redux/thunk/reduxApis/states.api";

export interface AddDeductionRequestData {
    po_id: string,
    deduction_for: 1 | 2, // 1 for retailer 2 for transport
    deduction_reason_id: number,
    deduction_amount: number
}

export const addDeductionApi = ApiCaller<
	FarmartResponse<any>,
	any,
	AddDeductionRequestData
>('addDeductionApi', {
	url: 'v4/payment/deduction',
	type: 'POST',
})


export const getDeductionReasonRetailer = ApiCaller<FarmartResponse<any>,any>('getDeductionReasonRetailer', {
	url: '/v1/payment/deduction_reasons/retailer',
	type: 'GET',
})

export const getDeductionReasonTransport = ApiCaller<FarmartResponse<any>,any>('getDeductionReasonRetailer', {
	url: '/v1/payment/deduction_reasons/transport',
	type: 'GET',
})


