import React from 'react'
import {Navigate} from 'react-router-dom'
import {useAppSelector} from '../../redux'

interface Props {
	component: React.ComponentType
}

//@ts-ignore
const PrivateRoute: React.FC<Props> = ({component: RouteComponent}) => {
	const authenticated = useAppSelector(state => state.auth.authenticated)
	//@ts-ignore
	if (authenticated) return <RouteComponent />

	return <Navigate to='/login' />
}

export default React.memo(PrivateRoute)
