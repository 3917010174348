import {ApiCaller} from '../../../../component/apiRedux/apiCaller'
import {FarmartResponse} from '../../../../redux/thunk/reduxApis/states.api'

export interface InitPaymetRequestBody {
	po_id: number
	payment_for: number
	is_gst_payment: boolean
	amount: number
}

export const initPaymentApi = ApiCaller<
	FarmartResponse<any>,
	any,
	InitPaymetRequestBody
>('initPaymentApi', {
	type: 'POST',
	url: 'v4/payment',
})
