import {ApiCaller} from '../../../component/apiRedux/apiCaller'
export interface FarmartPaginatedResponse<T> {}

export interface FarmartResponse<T> {
	status: boolean
	message: string
	data: T
}

export interface State {
	id: number
	name: string
	code: string
}

export interface District {
	id: number
	name: string
	code: string
}


export interface StateDistrictFromPinCode {
	location_detail?: {
		pincode?: number
		state?: {
			id: number
			name: string
			state_code: string
		}
		district?: {
			id: number
			name: string
			district_code: string
		}
		taluk?: {
			id: 3857
			name: string
		}
		lat?: 22.7794565
		long?: 86.28695069999999
	}
}


export const statesRequestGet = ApiCaller<FarmartResponse<{state: State[]}>>(
	'states',
	{
		type: 'GET',
		url: 'v1/location/states',
	},
)

  
export const districtRequestGet = ApiCaller<
	FarmartResponse<{district: District[]}>
>('districts', {
	type: 'GET',
	url: 'v1/location/districts',
})

export const getStateDistrictFromPinCode = ApiCaller<FarmartResponse<StateDistrictFromPinCode>>(
	'get_states_districts_from_pincode',
	{
		type: 'GET',
		url: 'v1/location/detail',
	},
)