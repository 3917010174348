import React from 'react'
import RejectedPoMappingDialog from './../../rejectedPoMapping'
import './rejectedPoTable.style.css'
import RejectedPoTabel from './RejectPoTable'
import style from './style.module.css'
//Types============
import {ViewIndexProps} from './../types'

const RejectedPoIndex: React.FC<ViewIndexProps> = ({
	getAllRejectPoRec,
	rejectPoRows,
	isDataLoading,
	onPageChange,
	tablePageData,
	totalRows,
	onTableRowSelection,
}) => {
	return (
		<div className={style.container}>
			<RejectedPoTabel
				getAllRejectPoRec={getAllRejectPoRec}
				rejectPoRows={rejectPoRows}
				isDataLoading={isDataLoading}
				onPageChange={onPageChange}
				tablePageData={tablePageData}
				totalRows={totalRows}
				onTableRowSelection={onTableRowSelection}
			/>
		</div>
	)
}

export default RejectedPoIndex
