import {
	fetchBuyerDetails,
	fetchBuyerStatusList,
	updateBuyerStatus,
} from './buyersummary.api'
import {
	fetchBuyerStatus,
	fetchBuyerTypeListApi,
	getStateDistrictFromPinCode,
	pushtToZohoApi,
	updateBuyerApi,
} from './editBuyer.api'
import {fetchBuyerLinksCriteria, linkBuyerApi} from './mapBuyer.api'

export const buyerApis = [
	fetchBuyerDetails,
	fetchBuyerStatusList,
	fetchBuyerLinksCriteria,
	linkBuyerApi,
	fetchBuyerTypeListApi,
	fetchBuyerStatus,
	updateBuyerApi,
	updateBuyerStatus,
	pushtToZohoApi,
	getStateDistrictFromPinCode,
]
