import {ApiCaller} from '../../../component/apiRedux/apiCaller'
import {FarmartResponse} from './states.api'

export interface RMListTypeInterface {
	rm_sales_name: string
	rm_sales_id: number
}

export const regionalManagerRequestGet = ApiCaller<
	FarmartResponse<{rm_sales_list: RMListTypeInterface[]}>
>('regional_manager', {
	type: 'GET',
	url: '/v3/buyer/rm_sales_list',
})
