import GridViewIcon from '@mui/icons-material/GridView'
import PersonAddIcon from '@mui/icons-material/PersonAdd'

export const forHr = [
	{
		navlabel: 'Dashboard',
		to: '/protected',
		icon: GridViewIcon,
		subcat: [
			{
				navlabel: 'Company metrics',
				to: '/protected/dashboard/company_metrics',
			},
			{
				navlabel: 'Buyer Overview',
				to: '/protected/dashboard/buyer_overview',
			},
			{
				navlabel: 'Seller Overview',
				to: '/protected/dashboard/seller_metrics',
			},
			{
				navlabel: 'Operational Funnels',
				to: '/protected/dashboard/operational_funnels',
			},
			{
				navlabel: 'Procurement',
				to: '/protected/dashboard/procurement',
			}
		],
	},
	{
		navlabel: 'Access Creation',
		to: '/protected/users',
		icon: PersonAddIcon,
		feild: 'isUsersOpen',
		subcat: [
			{
				navlabel: 'Access Creation',
				to: '/protected/users/access_creation',
			},
			{
				navlabel: 'Visits',
				to: '/protected/users/visits',
			},
		],
	},
]
