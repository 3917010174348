import { type } from "os";
import { ApiCaller } from "../../../../component/apiRedux/apiCaller";
import { FarmartResponse } from "../states.api";
export enum DeliveryStatusEnums  {
	vehicle_lost = 'vehicle_lost',
	vehicle_breakdown = 'vehicle_breakdown',
	legal_issues = 'legal_issues',
	delivered = 'delivered'
}

export type DeliveryStatus = {
	[K in DeliveryStatusEnums]: number
}

export type RMDetails ={
	rm_sales_name: string;
	rm_sales_id: number;
}

export type PAListInterface = {
	mobile_number: number,
	name: string;
	_id: number;
}

export type PAResponse = {
	pa_data: PAListInterface[],
}

export type BusinessTypeResponse = {
	business_types: {
		id: number;
		name: string;
	}[]
}

export type TransporterDetailsInterface = {
	vehicle_no: string,
    number_plate_img: string,
    rc: string,
    loading_floor: string,
    driver_name: string,
    contact_no: number,
    dl: string,
    transporter_name: string,
    tds: string,
    cancelled_cheque: string,
    account_no: string,
    ifsc: string,
    bank_name: string,
    account_holder_name: string,
    trip_id: string,
    trip_url: string
};

export const getDeliveriesStatus = ApiCaller<FarmartResponse<DeliveryStatus>> ('getDeliveryStatus',  {
    type: 'GET',
    url: '/v2/purchase_order/delivery_status'
})

export const getRmList = ApiCaller<
	FarmartResponse<{rm_sales_list: RMDetails[]}>
>('getRmDetails', {
	type: 'GET',
	url: 'v3/buyer/rm_sales_list',
})

export const updateDeliveryStatus = ApiCaller<FarmartResponse<any>>('updateDeliveryStatus', {
	type: 'POST',
	url: 'v2/purchase_order/delivery_status'
})

export const fetchPAList = ApiCaller<FarmartResponse<PAResponse>>('fetchPAList', {
	type: 'GET',
	url: 'v1/campaign/fetch_all_pa',
})

export const fetchBusinessType = ApiCaller<FarmartResponse<BusinessTypeResponse>>('fetchBusinessType', {
	type: 'GET',
	url: '/v3/purchase_order/deliveries/business_types'
});

export const createUpdateTrip = ApiCaller<FarmartResponse<any>>('create_update_trip', {
	type: 'PATCH',
	url: '/v3/purchase_order/deliveries'
});

export const fetchDeliveryDetails = ApiCaller<
	FarmartResponse<TransporterDetailsInterface>
>('fetch_transpoter_details', {
	type: 'GET',
	url: '/v3/purchase_order/deliveries',
})