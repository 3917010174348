import {createAsyncThunk} from '@reduxjs/toolkit'
import ServiceApi from '../../service/service.api'
import download from 'downloadjs'
import {
	GstDetailsCropVarityPayloadInterface,
	ICropSamplingCondition,
	ICropVariety,
	IDocDispatch,
	IQCChecklist,
	IRetailer,
} from '../types/type.procurement'

export interface ICreateCrop2 {
	name: string
	varietyData: {
		name: string
		qualityCheckGuideURL: string
	}
	qcData: {
		name: string
		value: string
		condition: string
		imageRequired: boolean
	}
	samplingData: {
		weightFrom: string
		weightTo: string
		minimumImages: string
		sampleCount: string
	}
	docData: {
		name: string
		mendatory: boolean
		imageRequired: boolean
	}
}

export interface IVarietyData {
	name: string
	crop: string
}

export interface IDocDispatchCreate {
	cropId: string
	docData: {
		name: string
		mendatory: boolean
		imageRequired: boolean
	}
}

export interface ICreateQcCheck {
	qcData: {
		name: string
		value: string
		condition: string
		imageRequired: boolean
	}
	varietyId: string
}

export interface ICreateQcSample {
	samplingData: {
		weightFrom: number
		weightTo: number
		minimumImages: number
		sampleCount: number
	}
	varietyId: string
}

export interface ICreateCrop {
	name: string
	varieties: Omit<ICropVariety, '_id'>[]
	samplingCondition: ICropSamplingCondition
	qcChecklist: Omit<IQCChecklist, '_id'>[]
	documentAtDispatch: Omit<IDocDispatch, '_id'>[]
}
export interface IUpdateSample {
	sampleId: string
	lotId: number
	data: {
		status: number
		procurableTon: number
		retailerLockedPrice: number
	}
}

export const getRetailers = createAsyncThunk(
	'procurement/load-retailers',
	async (paginationData: any, thunkAPI) => {
		try {
			const {status, data} = await ServiceApi.getInstance().POST('v3/retailer', {
				...paginationData,
			})
			if (status !== 200) throw new Error(data.message ?? 'Something went wrong')
			return data.data
		} catch (err: any) {
			return thunkAPI.rejectWithValue(err.message)
		}
	},
)

export const downloadRetailers = createAsyncThunk(
	'procurement/download-retailers',
	async (_, thunkAPI) => {
		try {
			const {status, data} = await ServiceApi.getInstance().GET(
				'/v1/procurement_sources/procurement/download',
			)
			if (status !== 200) throw new Error(data.message ?? 'Something went wrong')
			download(data, 'Procurement-Source-Records.csv')

			return data
		} catch (err: any) {
			return thunkAPI.rejectWithValue(err.message)
		}
	},
)

export const uploadRetailers = createAsyncThunk(
	'procurement/upload-retailers',
	async (uploadData: any, thunkAPI) => {
		try {
			const {status, data} = await ServiceApi.getInstance().POST(
				'/v1/procurement_sources/procurement/bulk',
				{users: uploadData},
			)
			if (status !== 200) throw new Error(data.message ?? 'Something went wrong')
			return data
		} catch (err: any) {
			return thunkAPI.rejectWithValue(err.message)
		}
	},
)

export const updateDocStatus = createAsyncThunk(
	'procurement/update-status',
	async (newData: IRetailer, thunkAPI) => {
		try {
			const {status, data} = await ServiceApi.getInstance().PUT(
				'/v1/procurement_sources/procurement/update',
				newData,
			)
			if (status !== 200) throw new Error(data.message ?? 'Something went wrong')
			return data
		} catch (err: any) {
			return thunkAPI.rejectWithValue(err.message)
		}
	},
)

export const loadAllCrops = createAsyncThunk(
	'procurement/load-allcrops',
	async (_, thunkAPI) => {
		try {
			const {status, data} = await ServiceApi.getInstance().GET('/v1/crop')
			if (status !== 200) throw new Error(data.message ?? 'Something went wrong')
			return data
		} catch (err: any) {
			return thunkAPI.rejectWithValue(err.message)
		}
	},
)

export const loadCrops = createAsyncThunk(
	'procurement/load-crops',
	async (_, thunkAPI) => {
		try {
			const {status, data} = await ServiceApi.getInstance().GET('/crops/crop')
			if (status !== 200) throw new Error(data.message ?? 'Something went wrong')
			return data
		} catch (err: any) {
			return thunkAPI.rejectWithValue(err.message)
		}
	},
)

export const createCrop2 = createAsyncThunk(
	'procurement/create-crop2',
	async (cropData: ICreateCrop2, thunkAPI) => {
		try {
			const {status, data} = await ServiceApi.getInstance().POST(
				'/v1/crop',
				cropData,
			)
			if (status !== 200) throw new Error(data.message ?? 'Something went wrong')
			return data
		} catch (err: any) {
			return thunkAPI.rejectWithValue(err.message)
		}
	},
)

export const createVariety = createAsyncThunk(
	'procurement/create-variety',
	async (varietyData: IVarietyData, thunkAPI) => {
		try {
			const {status, data} = await ServiceApi.getInstance().POST(
				'/v1/crop/variety',
				varietyData,
			)
			if (status !== 200) throw new Error(data.message ?? 'Something went wrong')
			return data
		} catch (err: any) {
			return thunkAPI.rejectWithValue(err.message)
		}
	},
)

export const addGstDetailsCropCreation = createAsyncThunk(
	'v1/crop/variety/gst_details',
	async (gstData: GstDetailsCropVarityPayloadInterface, thunkAPI) => {
		try {
			const {status, data} = await ServiceApi.getInstance().POST(
				'/v1/crop/variety/gst_details',
				gstData,
			)
			if (status !== 200) throw new Error(data.message ?? 'Something went wrong')
			return data
		} catch (err: any) {
			return thunkAPI.rejectWithValue(err.message)
		}
	},
)

export const fecthGstDetailsCropCreation = createAsyncThunk(
	'/v1/crop/variety/gst_details',
	async (params: {crop_variety_id: number}, thunkAPI) => {
		try {
			const {status, data} = await ServiceApi.getInstance().GET(
				'/v1/crop/variety/gst_details',
				{
					params: {
						...params,
					},
				},
			)
			if (status !== 200) throw new Error(data.message ?? 'Something went wrong')
			return data
		} catch (err: any) {
			return thunkAPI.rejectWithValue(err.message)
		}
	},
)

export const editVariety = createAsyncThunk(
	'procurement/edit-variety',
	async (editVarietyData: any, thunkAPI) => {
		const varietyId = editVarietyData.varietyId
		const newEditVarietyData = delete editVarietyData.varietyId
		try {
			const {status, data} = await ServiceApi.getInstance().PUT(
				`/v1/crop/variety/${varietyId}`,
				editVarietyData,
			)
			if (status !== 200) throw new Error(data.message ?? 'Something went wrong')
			return data
		} catch (err: any) {
			return thunkAPI.rejectWithValue(err.message)
		}
	},
)

export const deleteVariety = createAsyncThunk(
	'procurement/delete-variety',
	async (varietyId: any, thunkAPI) => {
		try {
			const {status, data} = await ServiceApi.getInstance().DELETE(
				`/v1/crop/variety/${varietyId}`,
			)
			if (status !== 200) throw new Error(data.message ?? 'Something went wrong')
			return data
		} catch (err: any) {
			return thunkAPI.rejectWithValue(err.message)
		}
	},
)

export const addQcCheck = createAsyncThunk(
	'procurement/add-qc',
	async (qcCheckData: ICreateQcCheck, thunkAPI) => {
		try {
			const {status, data} = await ServiceApi.getInstance().POST(
				'/v1/crop/variety/qc_checklist',
				qcCheckData,
			)
			if (status !== 200) throw new Error(data.message ?? 'Something went wrong')
			return data
		} catch (err: any) {
			return thunkAPI.rejectWithValue(err.message)
		}
	},
)

export const editQcCheck = createAsyncThunk(
	'procurement/edit-qc',
	async (editQcData: any, thunkAPI) => {
		const qcCheckId = editQcData.qcCheckId
		const newQcCheckData = delete editQcData.qcCheckId
		try {
			const {status, data} = await ServiceApi.getInstance().PUT(
				`/v1/crop/variety/qc_checklist/${qcCheckId}`,
				editQcData,
			)
			if (status !== 200) throw new Error(data.message ?? 'Something went wrong')
			return data
		} catch (err: any) {
			return thunkAPI.rejectWithValue(err.message)
		}
	},
)

export const deleteQcCheck = createAsyncThunk(
	'procurement/delete-qc',
	async (qcCheckDeleteId: any, thunkAPI) => {
		const qcCheckId = qcCheckDeleteId.qcCheckId
		const varietyId = qcCheckDeleteId.varietyId
		try {
			const {status, data} = await ServiceApi.getInstance().DELETE(
				`/v1/crop/variety/qc_checklist/${qcCheckId}/${varietyId}`,
			)
			if (status !== 200) throw new Error(data.message ?? 'Something went wrong')
		} catch (err: any) {
			return thunkAPI.rejectWithValue(err.message)
		}
	},
)

export const addQcSample = createAsyncThunk(
	'procurement/add-qcSample',
	async (qcSampleData: ICreateQcSample, thunkAPI) => {
		try {
			const {status, data} = await ServiceApi.getInstance().POST(
				'/v1/crop/variety/sampling',
				qcSampleData,
			)
			if (status !== 200) throw new Error(data.message ?? 'Something went wrong')
			return data
		} catch (err: any) {
			return thunkAPI.rejectWithValue(err.message)
		}
	},
)

export const editQcSample = createAsyncThunk(
	'procurement/edit-qcsample',
	async (editQcSampleData: any, thunkAPI) => {
		const qcSampleId = editQcSampleData.qcSampleId
		const newQcsampleData = delete editQcSampleData.qcSampleId

		try {
			const {status, data} = await ServiceApi.getInstance().PUT(
				`/v1/crop/variety/sampling/${qcSampleId}`,
				editQcSampleData,
			)
			if (status !== 200) throw new Error(data.message ?? 'Something went wrong')
			return data
		} catch (err: any) {
			return thunkAPI.rejectWithValue(err.message)
		}
	},
)

export const deleteQcSample = createAsyncThunk(
	'procurement/delete-qcSample',
	async (qcSampleDeleteId: any, thunkAPI) => {
		const qcSampleId = qcSampleDeleteId.qcSampleId
		const varietyId = qcSampleDeleteId.varietyId
		try {
			const {status, data} = await ServiceApi.getInstance().DELETE(
				`/v1/crop/variety/sampling/${qcSampleId}/${varietyId}`,
			)
			if (status !== 200) throw new Error(data.message ?? 'Something went wrong')
		} catch (err: any) {
			return thunkAPI.rejectWithValue(err.message)
		}
	},
)

export const createDocAtDispatch = createAsyncThunk(
	'procurement/create-doc',
	async (docData: IDocDispatchCreate, thunkAPI) => {
		try {
			const {status, data} = await ServiceApi.getInstance().POST(
				'/v1/crop/variety/dispatch_doc',
				docData,
			)
			if (status !== 200) throw new Error(data.message ?? 'Something went wrong')
			return data
		} catch (error: any) {
			return thunkAPI.rejectWithValue(error.message)
		}
	},
)

export const editDocumentAtDis = createAsyncThunk(
	'procurement/edit-doc',
	async (editDocData: any, thunkAPI) => {
		const documentId = editDocData.documentAtDispatchId
		const newEditDocData = delete editDocData.documentAtDispatchId
		try {
			const {status, data} = await ServiceApi.getInstance().PUT(
				`/v1/crop/variety/dispatch_doc/${documentId}`,
				editDocData,
			)
			if (status !== 200) throw new Error(data.message ?? 'Something went wrong')
			return data
		} catch (err: any) {
			return thunkAPI.rejectWithValue(err.message)
		}
	},
)

export const deleteDocAtDispatch = createAsyncThunk(
	'procurement/delete-doc',
	async (docDetailsId: any, thunkAPI) => {
		const documentId = docDetailsId.documentId
		const varietyId = docDetailsId.varietyId
		try {
			const {status, data} = await ServiceApi.getInstance().DELETE(
				`/v1/crop/variety/dispatch_doc/${documentId}/${varietyId}`,
			)
			if (status !== 200) throw new Error(data.message ?? 'Something went wrong')
			return data
		} catch (error: any) {
			return thunkAPI.rejectWithValue(error.message)
		}
	},
)

export const createCrop = createAsyncThunk(
	'procurement/create-crop',
	async (cropData: ICreateCrop, thunkAPI) => {
		try {
			const {status, data} = await ServiceApi.getInstance().POST(
				'/crops/crop',
				cropData,
			)
			if (status !== 200) throw new Error(data.message ?? 'Something went wrong')
			return data
		} catch (err: any) {
			return thunkAPI.rejectWithValue(err.message)
		}
	},
)

export const getSamples = createAsyncThunk(
	'procurement/load-samples',
	async (paginationData: any, thunkAPI) => {
		try {
			const no_pages = paginationData?.page
			const row_limit = paginationData?.limit
			const search_param = paginationData?.search_param

			const {status, data} = await ServiceApi.getInstance().GET(
				'/v2/master_sample/',
				{
					params: {
						page: no_pages,
						limit: row_limit,
						search_param,
					},
				},
			)
			if (status !== 200) throw new Error(data.message ?? 'Something went wrong')
			const sample_data = {
				data: data.data.samples,
				total_record: data.data.total_records,
			}
			return sample_data
		} catch (err: any) {
			return thunkAPI.rejectWithValue(err.message)
		}
	},
)

export const updateSample = createAsyncThunk(
	'procurement/update-sample',
	async (newData: IUpdateSample, thunkAPI) => {
		try {
			const dataToUpdate = {
				dataToUpdate: Object.keys(newData.data).map(prop => ({
					property: prop,
					data: (newData.data as any)[prop],
				})),
			}
			const {status, data} = await ServiceApi.getInstance().PATCH(
				`/v1/master_sample/${newData.sampleId}/${newData.lotId}`,
				dataToUpdate,
			)
			if (status !== 200) throw new Error(data.message ?? 'Something went wrong')
			const [response_data] = data
			return response_data
		} catch (err: any) {
			return thunkAPI.rejectWithValue(err.message)
		}
	},
)

export const getPoFullDetails = createAsyncThunk(
	'procurement/po-fulldetails',
	async (poNumber: string, thunkAPI) => {
		try {
			const {status, data} = await ServiceApi.getInstance().GET(
				`/v1/purchase_order/${poNumber}`,
			)
			if (status !== 200) throw new Error(data.message ?? 'Something went wrong')
			return data
		} catch (err: any) {
			return thunkAPI.rejectWithValue(err.message)
		}
	},
)

export const getSalesOrderMapping = createAsyncThunk(
	'procurement/so-mapping',
	async (poId: any, thunkAPI) => {
		try {
			const {status, data} = await ServiceApi.getInstance().GET(
				`/v1/buyer/order/${poId}`,
			)
			if (status !== 200) throw new Error(data.message ?? 'Something went wrong')
			return data
		} catch (err: any) {
			return thunkAPI.rejectWithValue(err.message)
		}
	},
)

export const mapSotoPo = createAsyncThunk(
	'procurement/po-so_mapping',
	async (posoData: any, thunkAPI) => {
		try {
			const {status, data} = await ServiceApi.getInstance().POST(
				'/v1/purchase_order/map',
				posoData,
			)
			if (status !== 200) throw new Error(data.message ?? 'Something went wrong')
			return data
		} catch (error: any) {
			return thunkAPI.rejectWithValue(error.message)
		}
	},
)
export const detachSotoPo = createAsyncThunk(
	'procurement/detach',
	async (posoData: {po_id: number; so_id: number}, thunkAPI) => {
		try {
			const {status, data} = await ServiceApi.getInstance().POST(
				'/v1/buyer/detach',
				posoData,
			)
			if (status !== 200) throw new Error(data.message ?? 'Something went wrong')
			return data
		} catch (error: any) {
			return thunkAPI.rejectWithValue(error.message)
		}
	},
)

export const updatePoPaymentInfo = createAsyncThunk(
	'procurement/po-update',
	async (poPaymentData: any, thunkAPI) => {
		try {
			const {status, data} = await ServiceApi.getInstance().POST(
				`/v2/purchase_order/update_payment_info`,
				poPaymentData,
			)
			if (status !== 200) throw new Error(data.error ?? 'Something went wrong')
			return data
		} catch (error: any) {
			return thunkAPI.rejectWithValue(error.message)
		}
	},
)

export const getAllDocumentStatus = createAsyncThunk(
	'procurement/document-status',
	async (po_id: number, thunkAPI) => {
		try {
			const {status, data} = await ServiceApi.getInstance().GET(
				`/v1/purchase_order/doc_approval/${po_id}`,
			)
			if (status !== 200) throw new Error(data.message ?? 'Something ')
			return data
		} catch (error: any) {
			return thunkAPI.rejectWithValue(error.message)
		}
	},
)

export const updateDocumentStatus = createAsyncThunk(
	'procurement/image-status-update',
	async (imageData: any, thunkAPI) => {
		const poId: string = imageData.poId
		delete imageData.poId
		try {
			const {status, data} = await ServiceApi.getInstance().PATCH(
				`/v1/purchase_order/doc_approval/${poId}`,
				imageData,
			)
			if (status !== 200) throw new Error(data.message ?? 'Something went wrong')
			return data
		} catch (error: any) {
			return thunkAPI.rejectWithValue(error.message)
		}
	},
)

export const uploadImage = createAsyncThunk(
	'image/upload',
	async (imageData: any, thunkAPI) => {
		try {
			const {status, data} = await ServiceApi.getInstance().POST(
				`/v1/multimedia/image`,
				imageData,
			)
			if (status !== 200) throw new Error(data.message ?? 'Something went wrong')
			return data
		} catch (error: any) {
			return thunkAPI.rejectWithValue(error.message)
		}
	},
)

export const submitRetailerFinalPayment = createAsyncThunk(
	'handover/submit-retailer-final-payment',
	async function (RetailerPaymentData: any, thunkAPI) {
		try {
			const {status, data} = await ServiceApi.getInstance().POST(
				'/v1/payment/final_payment/retailer',
				RetailerPaymentData,
			)
			if (status !== 200) throw new Error(data.message ?? 'Something went wrong')
			return data
		} catch (error: any) {
			return thunkAPI.rejectWithValue(error.message)
		}
	},
)
export const submitTransportFinalPayment = createAsyncThunk(
	'handover/sumit-transport-final-payment',
	async function (TransportPaymentData: any, thunkAPI) {
		try {
			const {status, data} = await ServiceApi.getInstance().POST(
				'/v1/payment/final_payment/transport',
				TransportPaymentData,
			)
			if (status !== 200) throw new Error(data.message ?? 'Something went wrong')
			return data
		} catch (error: any) {
			return thunkAPI.rejectWithValue(error.message)
		}
	},
)

export const updateRetailerDocument = createAsyncThunk(
	'retailer/update-document-procurement',
	async (retailerDocPayload: any, thunkAPI) => {
		const index = retailerDocPayload.index
		delete retailerDocPayload.index
		try {
			const {status, data} = await ServiceApi.getInstance().POST(
				'/v2/retailer/update_doc/panel',
				retailerDocPayload,
			)
			if (status !== 200) throw new Error(data.message ?? 'Something went wrong')
			return {data, index}
		} catch (err: any) {
			return thunkAPI.rejectWithValue(err.message)
		}
	},
)
export const updateTransporterDetails = createAsyncThunk(
	'rejected-po/update-transporter-details',
	async (Payload: any, thunkAPI) => {
		try {
			const {status, data} = await ServiceApi.getInstance().POST(
				'/v1/po_remapping/transport',
				Payload,
			)
			if (status !== 200) throw new Error(data.message ?? 'Something went wrong')
			return data
		} catch (err: any) {
			return thunkAPI.rejectWithValue(err.message)
		}
	},
)

export const fetchRejectedPoDetails = createAsyncThunk(
	'rejected-po/update-transporter-details',
	async (po_pending_in_hand_id: number, thunkAPI) => {
		try {
			const {status, data} = await ServiceApi.getInstance().GET(
				'/v1/po_remapping/details',
				{
					params: {
						po_pending_in_hand_id,
					},
				},
			)
			if (status !== 200) throw new Error(data.message ?? 'Something went wrong')
			return data
		} catch (err: any) {
			return thunkAPI.rejectWithValue(err.message)
		}
	},
)

export const fetchRejectedPoTransportD = createAsyncThunk(
	'rejected-po/rejected-po-transport',
	async (po_pending_in_hand_id: number, thunkAPI) => {
		try {
			const {status, data} = await ServiceApi.getInstance().GET(
				'v1/po_remapping/transport',
				{
					params: {
						po_pending_in_hand_id,
					},
				},
			)
			if (status !== 200) throw new Error(data.message ?? 'Something went wrong')
			return data
		} catch (err: any) {
			return thunkAPI.rejectWithValue(err.message)
		}
	},
)

export const fetchRejectedPoDispatchD = createAsyncThunk(
	'rejected-po/rejected-po-dispatch',
	async (po_pending_in_hand_id: number, thunkAPI) => {
		try {
			const {status, data} = await ServiceApi.getInstance().GET(
				'/v1/po_remapping/dispatch',
				{
					params: {
						po_pending_in_hand_id,
					},
				},
			)
			if (status !== 200) throw new Error(data.message ?? 'Something went wrong')
			return data
		} catch (err: any) {
			return thunkAPI.rejectWithValue(err.message)
		}
	},
)

export const fetchSalesOrderForRejectedPo = createAsyncThunk(
	'rejected-po/sales-order-details',
	async (po_pending_inhand_id: number, thunkAPI) => {
		try {
			const {status, data} = await ServiceApi.getInstance().GET(
				'/v2/sales_order/mapping',
				{
					params: {
						po_pending_inhand_id,
					},
				},
			)
			if (status !== 200) throw new Error(data.message ?? 'Something went wrong')
			return data
		} catch (err: any) {
			return thunkAPI.rejectWithValue(err.message)
		}
	},
)
export const updateDispatchDetails = createAsyncThunk(
	'rejected-po/dispatch-details',
	async (payload: any, thunkAPI) => {
		try {
			const {status, data} = await ServiceApi.getInstance().POST(
				'/v1/po_remapping/dispatch',
				payload,
			)
			if (status !== 200) throw new Error(data.message ?? 'Something went wrong')
			return data
		} catch (err: any) {
			return thunkAPI.rejectWithValue(err.message)
		}
	},
)
export const mapRejectedSalesOrder = createAsyncThunk(
	'rejected-po/map-so',
	async (payload: any, thunkAPI) => {
		try {
			const {status, data} = await ServiceApi.getInstance().POST(
				'/v1/po_remapping/map',
				payload,
			)
			if (status !== 200) throw new Error(data.message ?? 'Something went wrong')
			return data
		} catch (err: any) {
			return thunkAPI.rejectWithValue(err.message)
		}
	},
)
export const fetchAllSecondaryOrders = createAsyncThunk(
	'rejected-po/get-all-secondary-orders-list',
	async (
		paginationData: {page_number: number; limit: number; searchBy?: string},
		thunkAPI,
	) => {
		try {
			const {status, data} = await ServiceApi.getInstance().GET(
				'/v1/po_remapping/',
				{
					params: {...paginationData},
				},
			)
			if (status !== 200) throw new Error(data.message ?? 'Something went wrong')
			return data
		} catch (err: any) {
			return thunkAPI.rejectWithValue(err.message)
		}
	},
)

export const fetchUnloadingDetails = createAsyncThunk(
	'rejected-po/get-unloading-details',
	async (payload: {po_no: string}, thunkAPI) => {
		try {
			const {status, data} = await ServiceApi.getInstance().GET(
				'/v1/po_unloading/po_so_mapped_unloading_list',
				{
					params: {...payload},
				},
			)
			if (status !== 200) throw new Error(data.message ?? 'Something went wrong')
			return data
		} catch (err: any) {
			return thunkAPI.rejectWithValue(err.message)
		}
	},
)

export const fetchDataScienceQcChecks = createAsyncThunk(
	'data-science/qc-checks',
	async (payload: {sample_id: number}, thunkAPI) => {
		try {
			const {status, data} = await ServiceApi.getInstance().POST(
				'/v1/ds/fetch_sample_qc_report',
				payload,
			)
			if (status !== 200) throw new Error(data.message ?? 'Something went wrong')
			return data
		} catch (err: any) {
			return thunkAPI.rejectWithValue(err.message)
		}
	},
)
