import { ApiCaller } from '../../../../component/apiRedux/apiCaller'
import { FarmartResponse } from '../states.api'

export interface BuyerFacility {
	mill_name: string
	gst: string
	mandi_licence: string
	address: 'strings'
	buyer_facility_id: 4
	buyer_gst_id: 3007
	poc: {
		name: string
		mobile: string
		email: string
	}
	mill_img_urls: string[]
	mill_capacity: 10
	mill_product: string
	state_id: string
	district_id: string
	pin_id: string
	state: string
	district: string
	pin: string
	crops?: string[]
	crop_id_list: []
}


export const getFacilitiesListApi = ApiCaller<
	FarmartResponse<{
		buyer_facility_list: BuyerFacility[]
		total_records: number
	}>
>('get_buyer_facilitiy', {
	type: 'GET',
	url: '/v3/buyer/facilities',
})

export const getFacilitiesDetailApi = ApiCaller<
	FarmartResponse<BuyerFacility>
>('get_buyer_facilitiy_details', {
	type: 'GET',
	url: '/v3/buyer/facility_details',
})

export const editFacilitiesDetailApi = ApiCaller<FarmartResponse<unknown>>(
	'post_buyer_facilitiy_details',
	{
		type: 'POST',
		url: '/v3/buyer/mill_details',
	},
)
