import {setDocData} from './../service/firebaseServices'

interface SetDocDataReturnType {
	status: boolean
	message: string
}

interface ProductListData {
	productList: any
	id: number
}

export default async function addProductList(
	data: ProductListData,
): Promise<SetDocDataReturnType> {
	try {
		await setDocData({
			collectionType: 'products',
			key: data.id,
			data: data,
		})
		return {status: true, message: 'Success'}
	} catch (error: any) {
		return {status: false, message: error.message}
	}
}
