import {ApiCaller} from '../../../../component/apiRedux/apiCaller'
import {FarmartResponse} from '../../../../redux/thunk/reduxApis/warehouse/warehouse.api'

export interface ApproveRejectPaymentApiRequestBody {
	payment_id: number
	status: number
	rejection_reason_id?: number
	comment?: string
}

export const approveRejectPaymentApi = ApiCaller<
	FarmartResponse<any>,
	any,
	ApproveRejectPaymentApiRequestBody
>('approveRejectPaymentApi', {
	type: 'POST',
	url: 'v4/payment/approve_or_reject',
})
