import { ApiCaller } from "../../../component/apiRedux/apiCaller";
import { FamrmartResponse } from "../../../redux/thunk/reduxApis/states.api";
import AddSupplierRequestType from "../supplierlist/types/addSupplierRequetType";
import { SupplierResponse } from "../vasCustomers/types/customerResponseInterface";

export const assignSupplierToUA = ApiCaller<FamrmartResponse<AddSupplierRequestType>>(
   'assignLeadToUa',
   {
    type:'POST',
    url:'/v1/vas/leads'
   } 
)  
export const getAllSupplier = ApiCaller<FamrmartResponse<SupplierResponse>>(
    'getSupplierList',
    {
     type:'GET',
     url:'/v1/vas/leads'   
    }
)
export const assignRmToSupplier = ApiCaller<FamrmartResponse<SupplierResponse>>(
    'assignRm',
    {
     type:'POST',
     url:'/v1/vas/assign_rm'   
    }
)

export const approveOrRejectSupplier = ApiCaller<FamrmartResponse<SupplierResponse>>(
    'approveOrRejectSupplier',
    {
     type:'POST',
     url:'/v1/vas/approve_lead'   
    }
)

export const getAllState = ApiCaller<FamrmartResponse<SupplierResponse>>(
    'allState',
    {
     type:'GET',
     url:'/v1/location/states'   
    }
)
export const getStateDistrict = ApiCaller<FamrmartResponse<SupplierResponse>>(
    'stateDistrict',
    {
     type:'GET',
     url:'/v1/location/districts'   
    }
)


