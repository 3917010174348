import {
	createUpdateTrip,
	fetchBusinessType,
	fetchDeliveryDetails,
	fetchPAList,
	getDeliveriesStatus,
	getRmList,
	updateDeliveryStatus,
} from './deliveries.api'

export const deliveriesApis = [
	getDeliveriesStatus,
	getRmList,
	updateDeliveryStatus,
	fetchPAList,
	fetchBusinessType,
	createUpdateTrip,
	fetchDeliveryDetails
]
