import React from 'react'
import styles from './LoginSplashScreen1.module.scss';

export const LoginSplashScreen = () => {
  const texts=new Map();
  texts.set(1,"World’s first OS powering food value chains");
  texts.set(2,"World’s first OS powering food value chains");
  return (
    <div className={styles.splashScreenContainer}>
       <div className={styles.image}></div>
      <div className={styles.text}>
          {texts.get(1)}
      </div>
    </div>
  )
}
