export const reformatTimestamp = (date: any, lang = 'en-GB') => {
	// Parse timestamp as local
	if (date) {
		let [y, m, d] = date.split(/\D/)

		return new Date(y, m - 1, d).toLocaleString(lang, {
			year: 'numeric',
			month: 'numeric',
			day: 'numeric',
		})
	} else {
		return ''
	}
}

export const getReformattedCurr = (amount: number | null | undefined) => {
	if (amount === null || amount === undefined) {
		return 'N/a'
	} else {
		const indianCurrAmount = new Intl.NumberFormat('en-IN', {
			style: 'currency',
			currency: 'INR',
		}).format(amount)

		return indianCurrAmount
	}
}
