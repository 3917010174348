import axios from 'axios'
import ENV from './../config/environment'

export const headers = {	
	Authorization: 'Bearer e0573219-bcbe-4ef4-a8a8-81534b249a16',
	'Access-Control-Allow-Origin': '*',
	'Content-Type': 'application/json',
}

export async function dsApi(body: any) {
	return await axios.post(ENV.dsApiUrl, body, {headers})
}
