import { PayloadAction } from "@reduxjs/toolkit";
import {createSlice } from '@reduxjs/toolkit'

export interface SecondaryOrderUnloadingState {
	currentIndex: number,
	ids: number[],
	currentPage: number;
	totalPages: number;
}

const initialState: SecondaryOrderUnloadingState = {
	currentIndex: -1,
	ids: [],
	currentPage: 0,
	totalPages: 0,
}

export const secondaryOrderUnloadingSlice = createSlice({
	name: 'secondaryOrderUnloading',
	initialState,
	reducers: {
		setNextSecondarySalesOrderId: (state: SecondaryOrderUnloadingState) => {
			let {currentIndex, ids} = state
			if (currentIndex + 1 < ids.length) {
				state.currentIndex = state.currentIndex + 1;
			}
		},
		setSecondarySalesOrderIds: (state, action: PayloadAction<number[]>) => {
			state.ids = action.payload
		},
		setSecondarySalesOrderCurrentIndex: (state, action: PayloadAction<number>) => {
			state.currentIndex = action.payload
		},
		setSecondarySalesOrderCurrentPage: (state, action: PayloadAction<number>) => {
			state.currentPage = action.payload;
		},
		setSecondarySalesOrderTotalPages: (state, action: PayloadAction<number>) => {
			state.totalPages = action.payload;
		},
		setSecondarySalesOrderNextPage: (state, action: PayloadAction<Omit<SecondaryOrderUnloadingState, 'currentIndex' | 'currentPage'>>) => {
			state.ids = action.payload.ids;
			state.currentIndex = 0;
			state.currentPage = state.currentPage + 1;
			state.totalPages = action.payload.totalPages;
		},
		resetSecondarySalesOrder: (state) => {
			state.currentIndex = -1;
			state.ids = []
			state.currentPage = 0
			state.currentIndex = 0
		}
	},
})

export default secondaryOrderUnloadingSlice.reducer
export const {
	setNextSecondarySalesOrderId,
	setSecondarySalesOrderIds,
	setSecondarySalesOrderCurrentIndex,
	setSecondarySalesOrderCurrentPage,
	setSecondarySalesOrderTotalPages,
	resetSecondarySalesOrder,
	setSecondarySalesOrderNextPage
} = secondaryOrderUnloadingSlice.actions
