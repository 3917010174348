import {ApiCaller} from '../../../../../component/apiRedux/apiCaller'
import {FarmartResponse} from '../../states.api'

export interface ISampleDetailsObject {
	sample_id: number
	sample_farmart_id: string
	sample_status: number
	retailer_name: string
	retailer_business_name: null
	retailer_address: string
	retailer_state: string
	retailer_district: string
	retailer_pincode: string
	retailer_mobile_number: number
	pa_mobile_number: number
	crop_name: string
	variety_name: string
	crop_variety_id: number
	created_at: Date
	sample_quantity: number
	available_quantity: number
	packaging_type: number
	tentative_price: number
	sample_images: string[]
	qc_details: {
		qc_type_id: number
		name: string
		value: string
		image: string
	}[]
}

export const getSampleDetails = ApiCaller<
	FarmartResponse<ISampleDetailsObject>
>('sample_details', {
	type: 'GET',
	url: '/v4/master_sample/detail',
})
export const updateSampleDetails = ApiCaller<FarmartResponse<any>>(
	'sample_action',
	{
		type: 'POST',
		url: '/v4/master_sample/approve_or_reject',
	},
)
