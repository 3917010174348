import {toast} from 'react-toastify'
import {createSlice} from '@reduxjs/toolkit'

import {getAllStates} from './../../thunk/globalthunk/thunk.geolocation'

const initialState: any = {
	all_states: [],
	states_fetching: false,
}

const globalSlice = createSlice({
	name: 'global-data',
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder.addCase(getAllStates.rejected, (state, payload) => {
			state.all_states = []
			state.states_fetching = false
			toast(payload.payload, {type: 'error'})
		})
		builder.addCase(getAllStates.fulfilled, (state, payload) => {
			state.all_states = payload.payload?.data?.state ?? []
			state.states_fetching = false
		})
		builder.addCase(getAllStates.pending, state => {
			state.states_fetching = true
		})
	},
})

export const globalActions = {
	getAllStates,
}

export default globalSlice.reducer
